<script setup>
import { onMounted, ref, reactive, watch } from "vue";
import store from "@/store";
import Cookies from "./svg/cookies.vue";
import { useRoute } from "vue-router";

const cookiesModalShown = ref(false);
const settingsShown = ref(false);
const rodoCard = ref();

const route = useRoute();

const cookiesData = reactive({
     cCNecessary: 1,
     cCFunctional: 0,
     cCMarketing: 0,
});

const setDataLayer = () => {
     window.dataLayer.push({
          cookieConsentNecessary: cookiesData.cCNecessary,
     });
     window.dataLayer.push({
          cookieConsentFunctional: cookiesData.cCFunctional,
     });
     window.dataLayer.push({
          cookieConsentMarketing: cookiesData.cCMarketing,
     });

     window.dataLayer.push({
          event: "cookieConsentNecessaryEnabled",
     });

     if (cookiesData.cCFunctional)
          window.dataLayer.push({
               event: "cookieConsentFunctionalEnabled",
          });
     if (cookiesData.cCMarketing)
          window.dataLayer.push({
               event: "cookieConsentMarketingEnabled",
          });
};

const closeCookiesModal = () => {
     settingsShown.value = false;
     cookiesModalShown.value = false;
};

const setCookie = (name, value, days) => {
     let expires = "";
     if (days) {
          const date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          expires = "; expires=" + date.toUTCString();
     }
     document.cookie = name + "=" + (value || "") + expires + "; SameSite=None; Secure; path=/";
};

const setCookieConsent = (all = false) => {
     if (all) {
          cookiesData.cCFunctional = 1;
          cookiesData.cCMarketing = 1;
     }

     const cookiesObject = {
          Necessary: cookiesData.cCNecessary,
          Functional: cookiesData.cCFunctional,
          Marketing: cookiesData.cCMarketing,
          time: Math.round(+new Date() / 1000),
     };

     setCookie("cookieConsentData", JSON.stringify(cookiesObject), 31);
     store.cookies.setAccepted(true);
     initPlugins();
     setDataLayer();

     if ((cookiesData.cCFunctional == 0)) {
          Object.keys(document.cookie).forEach((cookieName) => {
               if (cookieName != "PHPSESSID" && cookieName != "cookieConsentData") {
                    document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
               }
          });
     }

     closeCookiesModal();
};

watch(route, (newRoute) => {
     if (!store.cookies.accepted) {
          if (newRoute.name === "Impressum") cookiesModalShown.value = false;
          else cookiesModalShown.value = true;
     }
});

onMounted(() => {
     if (!store.cookies.accepted) cookiesModalShown.value = true;
     else {
          const cookies = document.cookie.split("; ").reduce((prev, current) => {
               const [name, ...value] = current.split("=");
               prev[name] = value.join("=");
               return prev;
          }, {});

          const cookieConsentCookies = JSON.parse(cookies.cookieConsentData);

          cookiesData.cCFunctional = cookieConsentCookies.Functional;
          cookiesData.cCMarketing = cookieConsentCookies.Marketing;

          setDataLayer();
     }

     window.addEventListener("DOMContentLoaded", () => {
          const editButton = document.querySelector("#editCookieConsent");
          if (editButton)
               editButton.addEventListener("click", () => {
                    cookiesModalShown.value = true;
                    settingsShown.value = true;
               });
     });
});
</script>

<template>
     <div class="cookies-backdrop" v-if="cookiesModalShown">
          <div class="rodo-card" ref="rodoCard">
               <Cookies class="cookies-svg" />
               <span class="rodo-title"><B>Ihre Zustimmung zu Cookies</B></span>
               <div class="rodo-text">
                    <span>
                         Wir schätzen Ihre Privatsphäre sehr!
                         <br />
                         Bei uns haben Sie die Möglichkeit Ihre Cookies Einstellungen zu verändern. Die
                         Baustoff-Euro-Trade GmbH verwendet Cookies und andere Technologien zur Benutzerführung,
                         Webanalyse und Verbesserung der Performance unserer Website. Dank dieser können wir Ihnen
                         relevante Inhalte und personalisierte Werbung besser und gezielte anzeigen. Wenn Sie uns dabei
                         unterstützen wollen, sagen Sie bitte "ICH STIMME ZU" damit sind Sie einverstanden und erlauben
                         uns, diese Daten an Dritte weiterzugeben, unter anderem an unsere Marketingpartner. Wenn Sie
                         diesem nicht zustimmen wollen, beschränken wir uns nur auf wesentliche Cookies, und durch das
                         nutzen unserer Website akzeptieren Sie dieses. Wenn Sie die Cookies Einstellungen verändern
                         möchten drücken Sie bitte "INDIVIDUELLE EINSTELLUNGEN".
                         <br /><br />
                         Mehr Informationen finden Sie hier:
                         <router-link to="/impressum">Impressum</router-link> |
                         <router-link to="/impressum#datenschutz">Datenschutzerklärung</router-link>
                    </span>
               </div>

               <div class="rodo-buttons">
                    <span class="settings" @click="settingsShown = true"> INDIVIDUELLE EINSTELLUNGEN </span>
                    <button class="btn small accept" @click="setCookieConsent(true)">ICH STIMME ZU</button>
               </div>
               <Transition name="fade-up">
                    <div class="settings-backdrop" v-if="settingsShown">
                         <div class="settings-card">
                              <div class="settings-card-content">
                                   <div class="einstellungen-text">
                                        <h2>Einstellungen</h2>
                                        <p>
                                             Hier können Sie die Einstellungen zum Tracking und Analyse auf unserer
                                             Website verändern und Ihren Präferenzen Anpassen.
                                        </p>
                                        <div class="group-cookies">
                                             <p>
                                                  <B>Technisch notwendige Cookies:</B><br />
                                                  <span class="gray"
                                                       >(SessionID, Google Tag Manager, Cookies freigeben)</span
                                                  >
                                             </p>
                                             <div>
                                                  <p>
                                                       Dieses Cookies sind notwendig und erforderlich, um den
                                                       technischen Betrieb der Website zu gewährleisten, grundlegende
                                                       Einkaufsfunktionen bereitzustellen sowie die Sicherheit zu
                                                       gewährleisten und Betrug vorzubeugen. Immer eingeschaltet.
                                                  </p>
                                                  <input
                                                       class="switch-input"
                                                       type="checkbox"
                                                       :checked="Boolean(cookiesData.cCNecessary)"
                                                       disabled />
                                             </div>
                                        </div>

                                        <div class="group-cookies">
                                             <p class="title">
                                                  <B>Marketing Cookies:</B><br />
                                                  <span class="gray">(Google Ads)</span>
                                             </p>
                                             <div>
                                                  <p>
                                                       Die Baustoff-Euro-Trade GmbH sammelt Informationen zur
                                                       Benutzerhistorie, und Benutzerverhalten um Ihnen ordnungsgemäß
                                                       und gezielt gesponserte Inhalte zu unseren Produkten
                                                       darzustellen. Um Ihnen Inhalte zu präsentieren, die Ihren
                                                       Interessen entsprechen, können wir beispielsweise Informationen
                                                       über Ihre Einkäufe und Website verhalten verwenden. Wir können
                                                       Informationen wie unter anderem Ihre verschlüsselte
                                                       E-Mail-Adresse oder Gerätedaten und Kunden-ID an Dritte wie
                                                       Facebook oder Google weitergeben. Das dient dem Ziel Ihnen,
                                                       relevante Werbung auf Websites und Anwendungen Dritter zu
                                                       präsentieren. Wir können diese Daten auch zwischen den
                                                       verschiedenen von Ihnen verwendeten Geräten kombinieren und
                                                       Anzeigendaten verarbeiten (zum beispiel hat das Klicken auf eine
                                                       Anzeige zu einem Kauf geführt). Dieses dient zur Bewertung der
                                                       Wirksamkeit der Anzeige und ermöglicht deren uns ein besseres
                                                       Werbebudget zu recherchieren.
                                                  </p>
                                                  <input
                                                       class="switch-input"
                                                       type="checkbox"
                                                       :checked="Boolean(cookiesData.cCMarketing)"
                                                       @change="
                                                            (e) => (cookiesData.cCMarketing = Number(e.target.checked))
                                                       " />
                                             </div>
                                        </div>

                                        <div class="group-cookies">
                                             <p>
                                                  <B>Funktionelle Cookies:</B><br />
                                                  <span class="gray">(Google Analitycs, Google Maps)</span>
                                             </p>
                                             <div>
                                                  <p>
                                                       Wir verwenden diese Daten, um Ihr Einkaufserlebnis zu verbessern,
                                                       Fehler zu vermeiden, neue Funktionen zu testen sowie User
                                                       Experience-Analysen und kommerziell relevante Store-Analysen
                                                       durchzuführen.
                                                  </p>
                                                  <input
                                                       class="switch-input"
                                                       type="checkbox"
                                                       :checked="Boolean(cookiesData.cCFunctional)"
                                                       @change="
                                                            (e) => (cookiesData.cCFunctional = Number(e.target.checked))
                                                       " />
                                             </div>
                                        </div>
                                   </div>
                                   <button class="btn" @click="setCookieConsent()">Einstellungen Speichern</button>
                              </div>
                         </div>
                    </div>
               </Transition>
          </div>
     </div>
</template>

<style lang="scss" scoped>
@import "../css/config/config";

.cookies-backdrop {
     display: flex;
     z-index: 99999999999;
     width: 100vw;
     height: 100vh;
     position: fixed;
     left: 0;
     bottom: 0;
     top: 0;
     right: 0;
     background-color: rgba(0, 0, 0, 0.5);
     display: flex;
     align-items: flex-end;
     justify-content: center;
     padding-bottom: 5rem;

     .rodo-card {
          display: flex;
          flex-direction: column;
          row-gap: 2rem;
          justify-content: space-between;
          width: 60%;
          margin: 0 auto;
          background-color: white;
          height: 22rem;
          max-width: 1000px;
          padding-inline: 2rem;
          padding-bottom: 1rem;
          position: relative;

          @include break("lg down") {
               width: 75%;
          }

          @include break("md down") {
               width: 90%;
               height: 24rem;
          }

          @include break("sm down") {
               padding-inline: 1.5rem;
          }

          .cookies-svg {
               width: 5rem;
               height: auto;
               position: absolute;
               left: 0;

               @include break("md down") {
                    width: 4rem;
               }
          }

          .rodo-title {
               text-align: center;
               font-size: 1.5rem;
               margin-top: 2.5rem;
               max-width: 90%;
               margin-inline: auto;
          }

          .rodo-text {
               max-height: 50%;
               overflow-y: scroll;
               overflow-x: hidden;

               &::-webkit-scrollbar {
                    width: 10px;
               }

               &::-webkit-scrollbar-thumb {
                    background-color: $primary;
                    border-radius: 2px;
               }

               &::-webkit-scrollbar-track {
                    background-color: $primary_10;
                    border-radius: 2px;
               }

               span {
                    display: block;
                    font-size: 1rem;
                    line-height: 2rem;
                    padding: 0 2rem;

                    @media screen and (max-width: 360px) {
                         padding: 0 1rem;
                    }

                    a {
                         text-decoration: underline;
                         color: $primary_800;
                         font-weight: 500;
                    }
               }
          }

          .rodo-buttons {
               width: 100%;
               display: flex;
               align-items: center;
               justify-content: space-around;
               column-gap: 0.75rem;
               align-items: center;
               font-weight: 500;

               .settings {
                    display: block;
                    width: fit-content;
                    text-align: center;
                    color: $secondary;
                    text-decoration: underline;
                    cursor: pointer;
               }

               .accept {
                    min-width: 40%;

                    @media screen and (max-width: 375px) {
                         min-width: 45%;
                    }
               }
          }
     }
}

.btn {
     font-size: 0.875rem;
     padding: 0.5rem 1rem;
     line-height: 1.5;
     font-weight: 600;
     border: 2px solid;
     transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out, border-color 0.25s ease-in-out,
          transform 0.25s ease-in-out;
     cursor: pointer;
     display: flex;
     justify-content: center;
     align-items: center;
     column-gap: 0.25rem;
     min-width: unset;
     border-radius: 0.5rem;

     @include break("sm down") {
          width: fit-content;
     }

     background-color: $primary;
     border-color: $primary;
     color: #fff;

     svg path {
          fill: #fff;
     }

     &:not(:disabled):hover {
          background-color: $secondary;
          border-color: $secondary;
     }
}

.settings-backdrop {
     display: flex;
     z-index: 1;
     width: 100vw;
     height: 100vh;
     position: fixed;
     left: 0;
     bottom: 0;
     background-color: rgba(0, 0, 0, 0.25);
     display: flex;
     align-items: flex-end;
     justify-content: center;
     padding-bottom: 10rem;

     .settings-card {
          background-color: white;
          width: 65%;
          max-width: 1150px;
          height: 32rem;
          max-height: calc(90dvh - 10rem);
          padding: 2.5rem 1rem 1rem;
          z-index: 1;

          @include break("lg down") {
               width: 80%;
          }

          @include break("md down") {
               width: 95%;
          }

          .settings-card-content {
               max-height: 100%;
               width: 100%;
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               row-gap: 1.5rem;
          }

          .einstellungen-text {
               overflow-y: scroll;
               padding: 0.5rem 2rem 1.5rem;

               &::-webkit-scrollbar {
                    width: 7px;
               }

               &::-webkit-scrollbar-thumb {
                    background-color: $primary;
                    border-radius: 2px;
               }

               &::-webkit-scrollbar-track {
                    background-color: $primary_10;
                    border-radius: 2px;
               }

               h2 {
                    text-align: center;
                    font-weight: 600;
                    font-size: 2rem;
                    margin-bottom: 2rem;
               }

               .gray {
                    color: $gray_300;
                    font-size: 1rem;
               }
          }

          .group-cookies {
               margin-top: 2rem;

               > div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    border-bottom: 1px solid $gray_50;
                    padding-bottom: 2rem;

                    @include break("small down") {
                         flex-direction: column;
                         align-items: flex-start;
                    }

                    > p {
                         display: block;
                         width: 80%;
                    }
               }

               .switch-input {
                    position: relative;
                    appearance: none;
                    outline: none;
                    width: 3.35rem;
                    height: 2rem;
                    background-color: #ffffff;
                    border: 1px solid #d9dadc;
                    border-radius: 3.35rem;
                    box-shadow: inset -1.25rem 0 0 0 #ffffff;
                    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
                    cursor: pointer;

                    &::after {
                         content: "";
                         position: absolute;
                         top: 1px;
                         left: 1px;
                         width: 1.675rem;
                         height: 1.675rem;
                         background-color: transparent;
                         border-radius: 50%;
                         box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
                    }

                    &:checked {
                         border-color: $primary_800;
                         box-shadow: inset 1.25rem 0 0 0 $primary_800;
                    }

                    &:checked:after {
                         left: 20px;
                         box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
                    }

                    &:disabled {
                         border-color: #d1d1d1;
                         box-shadow: inset 1.25rem 0 0 0 #e0e0e0;
                         cursor: no-drop;
                    }
               }
          }
     }
}
</style>