<script setup>
import ArrowDark from "./ArrowDark.vue"
import { ref, onMounted } from "vue";
import axios from 'axios';

const props = defineProps({
    noFollow: {
        type: Boolean,
        default: false
    }
})

onMounted(() => {
    showProductsPages();
});

const productsPages = ref(null);
const siteName = ref("baudiscount");
const siteUrl = ref("https://fotos.baudiscount.de/products-pages");

const showProductsPages = (() => {
    axios.get(`/api/getProductsPages`)
        .then((res) => {
            productsPages.value = res.data;
        })
        .catch((error) => {
            console.error(error);
        });
})

</script>

<template>
    <div v-if="productsPages && productsPages.length" class="box-gallery-luxurious">
        <template v-for="(card, idx) in productsPages">
            <template v-if="card.onPages.includes(siteName)">
                <a v-if="!card.isFuture" :rel="props.noFollow ? 'nofollow' : ''" data-aos="fade-up" data-aos-delay="0"
                    data-aos-duration="550" target="_blank" :href="card.link" class="img-luxurious conv-tracker">
                    <div class="image-wrapper">
                        <img loading="lazy" :src="siteUrl + card.image.src.replace('[VERSION]', 'desktop')"
                            :alt="card.image.alt">
                    </div>
                    <div class="img-luxurious-small">
                        <img loading="lazy" :src="siteUrl + card.icon.src" :class="{ smHeight: card.icon.class }">
                        <p v-html="card.title"></p>
                        <span class="mehr">Mehr
                            <ArrowDark />
                        </span>
                    </div>
                </a>
                <div v-else data-aos="fade-up" data-aos-delay="0" data-aos-duration="550" class="img-luxurious future">
                    <div class="image-wrapper">
                        <img loading="lazy" :src="siteUrl + card.image.src.replace('[VERSION]', 'desktop')"
                            :alt="card.image.alt">
                    </div>
                    <div class="img-luxurious-small future">
                        <img loading="lazy" :src="siteUrl + card.icon.src">
                        <p>{{ card.title }}</p>
                    </div>
                    <div class="tooltip-wrapper">
                        <p v-html="card.info" class="tooltip-message"></p>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.box-gallery-luxurious {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 1rem auto 3.5rem;
    column-gap: 1.5rem;
    row-gap: 3rem;

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }

    .img-luxurious {
        max-width: 100%;
        padding-top: 1rem;
        padding-bottom: 4rem;
        border-radius: 4px;
        position: relative;
        display: block;

        @media screen and (max-width: 576px) {
            padding-bottom: 2rem;
            margin-bottom: 1.5rem;
        }

        .tooltip-wrapper {
            display: none;
        }

        &.future {
            .tooltip-wrapper {
                display: block;
                position: absolute;
                top: 10%;
                right: 5%;
                padding: 1.25rem;
                border-radius: 8px;
                width: 90%;
                background-color: #FFF;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                opacity: 0;
                transition: opacity 0.2s ease-in-out;

                @media screen and (min-width:426px) and (max-width:576px) {
                    right: 10%;
                    width: 80%;
                }

                p {
                    margin: 0;
                    font-size: 1rem;

                    @media screen and (max-width:1200px) {
                        font-size: 1.25rem;
                    }

                    @media screen and (max-width:768px) {
                        font-size: 1rem;
                    }
                }
            }

            &:hover {
                .tooltip-wrapper {
                    opacity: 1;
                }
            }
        }

        .image-wrapper {
            border-radius: 8px;
            overflow: hidden;
            transition: 0.4s;

            img {
                width: 100%;
                max-width: 100%;
                transition: 0.4s;
                object-fit: cover;
                vertical-align: middle;
            }
        }

        .img-luxurious-small {
            position: absolute;
            bottom: 0;
            left: 50%;
            transition: 0.4s;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            background-color: #fff;
            padding: 0.75rem 1.5rem 0.5rem;
            border-radius: 8px;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 0.625rem;
            width: 94%;

            &.future {
                flex-direction: row;
                justify-content: center;
                padding-block: 2.625rem;
                column-gap: 1.25rem;

                p {
                    font-size: 1.125rem;
                }

                img {
                    max-height: 2.5rem;
                }
            }

            @media screen and (max-width: 768px) {
                max-width: 320px;
                padding: 0.75rem 1rem 0.5rem;
                width: 90%;
            }

            @media screen and (max-width: 576px) {
                width: 75%;
            }

            img {
                object-fit: contain;
                max-width: 100%;

                &.smHeight {
                    max-height: 2rem;

                    @media screen and (max-width: 1200px) {
                        max-height: 3rem;
                    }

                    @media screen and (max-width: 768px) {
                        max-height: 2rem;
                    }
                }

                @media screen and (max-width: 768px) {
                    max-height: 20px;
                }
            }

            p {
                text-align: center;
                font-weight: 300;
                font-size: 0.875rem;
                line-height: 1;
                color: #455a64;
                margin: 0;
            }

            span.mehr {
                color: #263238;
                align-self: center;
                font-weight: 600;
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-left: 0.5rem;
                    transition: transform 0.4s ease-in-out;


                    @media screen and (max-width: 1200px) {
                        max-height: 1rem;
                    }
                }
            }
        }

        &:not(.future):hover {
            .image-wrapper {
                box-shadow: 0 10px 20px -1px #c6c6c6;
                transform: translateY(-3px);

                img {
                    transform: scale(1.01);
                }
            }

            .img-luxurious-small {
                transform: translateX(-50%) scale(1.01);
                box-shadow: 0 5px 20px -1px #c6c6c6;

                span.mehr svg {
                    transform: translateX(5px);
                }
            }
        }
    }
}
</style>