<script setup>

import { nextTick, ref, watch } from 'vue';
import Germany from '@/assets/svg/countries-flags/Germany.vue';
import Austria from '@/assets/svg/countries-flags/Austria.vue';
import Switzerland from '@/assets/svg/countries-flags/Switzerland.vue';
import ArrowDown from '@/assets/svg/countries-flags/ArrowDown.vue';

const countryCode = ref(null)
const countrySelectOpened = ref(false)

nextTick(() => {
    countryCode.value = '+49'
})

const emit = defineEmits(['onCountryChange'])

watch(countryCode, (newVal, oldVal) => {
    emit('onCountryChange', newVal)
})

</script>

<template>
    <div class="tel-select-wrapper">
        <div class="value-wrapper" :class="{ opened: countrySelectOpened }" tabindex="0"
            @click.stop="countrySelectOpened = !countrySelectOpened"
            @keyup.enter="countrySelectOpened = !countrySelectOpened"
            @keydown.up.prevent="countryCode = countryCode == '+41' ? '+43' : '+49'"
            @keydown.down.prevent="countryCode = countryCode == '+49' ? '+43' : '+41'"
            @focusout="countrySelectOpened = false">
            <div class="active-value" v-html="$refs['s' + countryCode]?.innerHTML + $refs['v' + countryCode]?.outerHTML">
            </div>
            <ArrowDown />
        </div>
        <div class="select-dropdown" :class="{ opened: countrySelectOpened }">
            <div class="option-wrapper" :class="{ 'active-option': countryCode == '+49' }"
                @mousedown="countryCode = '+49', countrySelectOpened = false"
                @touch="countryCode = '+49', countrySelectOpened = false">
                <span class="svg-span" ref="s+49">
                    <Germany />
                </span>
                <span>Deutschland</span>
                <span class="option-value" ref="v+49">+49</span>
            </div>
            <div class="option-wrapper" :class="{ 'active-option': countryCode == '+43' }"
                @mousedown="countryCode = '+43', countrySelectOpened = false"
                @touch="countryCode = '+43', countrySelectOpened = false">
                <span class="svg-span" ref="s+43">
                    <Austria />
                </span>
                <span>Österreich</span>
                <span class="option-value" ref="v+43">+43</span>
            </div>
            <div class="option-wrapper" :class="{ 'active-option': countryCode == '+41' }"
                @mousedown="countryCode = '+41', countrySelectOpened = false"
                @touch="countryCode = '+41', countrySelectOpened = false">
                <span class="svg-span" ref="s+41">
                    <Switzerland />
                </span>
                <span>Schweiz</span>
                <span class="option-value" ref="v+41">+41</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/css/config/config.scss";
.tel-select-wrapper {
    position: relative;

    .value-wrapper {
        height: 100%;
        width: fit-content;
        background-color: #fff;
        display: flex;
        align-items: center;
        padding: 0 0.25rem;
        cursor: pointer;
        user-select: none;

        .active-value,
        svg:last-child {
            pointer-events: none;
        }


        &:focus-visible {
            outline-color: $bbBlue;
        }

        .active-value {
            display: flex;
            align-items: center;
            column-gap: 0.25rem;
            margin-right: 0.25rem;
            width: 3.5rem;

            svg {
                height: 24px;
                width: 24px;

                @media screen and (max-width: 1024px) {
                    height: 18px;
                    width: 18px;
                }
            }

            span {
                font-size: 0.875rem;
                color: black;
            }
        }

        svg:last-child {
            transition: transform 0.3s ease-in-out;

            @media screen and (max-width: 1024px) {
                height: 18px;
                width: 18px;
            }
        }

        &.opened {
            svg:last-child {
                transform: rotate(180deg);
            }
        }
    }

    .select-dropdown {
        position: absolute;
        top: 100%;
        left: -2px;
        height: fit-content;
        overflow-y: hidden;
        z-index: 100;
        border-top: none;
        height: 0;
        opacity: 0.2;
        transition: all 0.3s;
        border: 1px solid #B0BEC5;

        &.opened {
            height: 100px;
            opacity: 1;
        }

        .option-wrapper {
            display: flex;
            padding: 0.25rem 0.5rem;
            align-items: center;
            column-gap: 0.5rem;
            background-color: #fff;
            border-bottom: 1px solid #B0BEC5;
            border-top: none;
            cursor: pointer;
            user-select: none;
            font-size: 0.875rem;
            color: black;

            .svg-span {
                display: flex;
                align-items: center;
            }

            svg,
            span {
                pointer-events: none;
            }

            &:hover {
                background-color: #e8f1f8;
            }

            &.active-option {
                background-color: #d2e3f2;
            }

            &:last-child {
                border-bottom: none;
            }

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
</style>