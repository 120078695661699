<script>
export default {
    inheritAttrs: false
}
</script>

<script setup>

const props = defineProps({
    modelValue: {
        required: true
    },
    label: {
        type: String,
        required: true
    },
    id: {
        type: [String, Number],
        required: true
    },
    isError: {
        type: Boolean
    },
    type: {
        type: String,
        default: "text",
        validator: (value) => {
            return ['text', 'email'].includes(value)
        }
    },
    errorMessage: {
        type: [String, null],
        required: false
    },
    helperMessage: {
        type: String,
        required: false
    }
})

const emit = defineEmits(['update:modelValue'])

</script>

<template>
    <div class="container" :class="{ 'error': isError }">
        <div class="label-container">
            <label :for="props.id">{{ props.label }}</label>
            <slot></slot>
        </div>

        <div class="input-wrapper">
            <input :type="props.type === 'email' ? 'email' : 'text'" :id="props.id" :value="modelValue"
                @input="(event) => { emit('update:modelValue', event.target.value) }" v-bind="$attrs">
        </div>

        <p v-if="props.helperMessage && !props.errorMessage" class="message">{{ props.helperMessage }}</p>
        <p class="message error" v-else-if="props.errorMessage !== undefined"
            :class="{ hidden: props.errorMessage === null }">
            {{ props.errorMessage ? props.errorMessage : '-' }}
        </p>
    </div>
</template>

<style lang="scss" scoped>
@import "@/css/config/config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    flex-grow: 1;

    .label-container {
        display: flex;
        align-items: center;
        column-gap: 0.25rem;
    }

    label {
        color: #263238;
        font-size: 0.875rem;
        line-height: 1.5;
        font-weight: 300;
        width: fit-content;

        @include break("xxs down") {
            font-size: 1rem;
        }
    }

    &.error {
        .input-wrapper {
            border-color: red;

            &:focus-within {
                border-color: red;
                outline-color: red;
            }

            animation: shake 0.2s 2;
        }
    }

    .input-wrapper {
        border: 1px solid #B0BEC5;
        outline: 1px solid transparent;
        border-radius: 2px;
        transition: border-color 0.15s ease-in-out, outline-color 0.15s ease-in-out;


        &:focus-within {
            border-color: $bbBlue;
            outline-color: $bbBlue;
        }

        input {
            border: none;
            outline: none;
            width: 100%;
            padding: 0.5rem;
            font-size: 0.875rem;
            line-height: 1.5;

            @include break("xxs down") {
                font-size: 1rem;
            }

            &:disabled {
                color: #e4e9eb;
            }
        }
    }

    .message {
        color: $gray_400;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;

        &.error {
            color: red;

            &.hidden {
                visibility: hidden
            }
        }
    }


}

@keyframes shake {
    25% {
        translate: 6px 0;
    }

    50% {
        translate: -6px 0;
    }

    75% {
        translate: 6px 0;
    }
}</style>