<script setup>
import PaginationArrowLeft from '@/components/svg/PaginationArrowLeft.vue';
import PaginationArrowRight from '@/components/svg/PaginationArrowRight.vue';
import GalleryModal from './GalleryModal.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/scss';
import "swiper/scss/pagination";

import { ref } from 'vue';

const props = defineProps({
    imagesData: {
        type: Array,
        required: true
    }
})

const modalShown = ref(false)
const activeSlideInModal = ref(0)

const openModal = (index) => {
    modalShown.value = true
    activeSlideInModal.value = index
}
</script>

<template>
    <div class="gridGallery">
        <div class="gridPrevIcon">
            <PaginationArrowLeft color="blue" />
        </div>
        <Swiper class="grid-gallery-wrapper" 
            :modules="[Autoplay, Pagination, Navigation]" 
            :grab-cursor="true"
            :space-between="13" 
            :disable-on-interaction="false" 
            :breakpoints="{
                577: {
                    spaceBetween: 16,
                },
                1200: {
                    spaceBetween: 24,
                },
            }" 
            :autoplay="{
                delay: 5000,
            }" 
            :navigation="{
                prevEl: '.gridPrevIcon',
                nextEl: '.gridNextIcon',
                disabledClass: 'arrow-disabled'
            }" 
            :pagination="{
                el: '.swiper-pagination.grid',
                type: 'bullets',
                clickable: true
            }"
            >
            <SwiperSlide v-for="gridImages in imagesData" class="gallery-slide-grid">
                <div v-for="image in gridImages" :key="image.index" class="image-wrapper" :class="image.classes"
                    @click="openModal(image.index)">
                    <img loading="lazy" :src="image.imageSrc" alt="">
                </div>
            </SwiperSlide>
            <div class="swiper-pagination grid"></div>
        </Swiper>
        <div class="gridNextIcon">
            <PaginationArrowRight color="blue" />
        </div>

        <GalleryModal 
            :modalShown="modalShown" 
            :imagesData="props.imagesData" 
            @closeModal="modalShown = false"
            :activeSlide = "activeSlideInModal"
            />

    </div>
</template>

<style lang="scss" scoped>
.gridGallery {
    position: relative;
    width: 75%;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        width: 90%;
    }

    @media screen and (min-width: 2120px) {
      width: 60%;
    }
    .arrow-disabled {
        display: none;
    }

    .gridPrevIcon,
    .gridNextIcon {
        position: absolute;
        top: 50%;
        cursor: pointer;
        z-index: 2;

        svg {
            width: 64px;
            height: 64px;
            opacity: 0.3;
            transition: opacity 0.25s ease-in-out;

            @media screen and (max-width: 1024px) {
                height: 50px;
                width: 50px;
            }

            @media screen and (max-width: 768px) {
                height: 36px;
                width: 36px;
                margin: 1rem 0;
            }

            &:hover {
                opacity: 1;
            }
        }

        @media screen and (max-width: 576px) {
            display: none;
        }
    }

    .gridPrevIcon {
        left: 0;
        transform: translate(-100%, -50%);

        @media screen and (max-width: 768px) {
            transform: translate(-75%, -50%);
        }

    }

    .gridNextIcon {
        right: 0;
        transform: translate(100%, -50%);

        @media screen and (max-width: 768px) {
            transform: translate(75%, -50%);
        }

    }

}

.grid-gallery-wrapper {
    width: 100%;
    overflow: hidden;
    padding: 2rem 1rem;

    @media screen and (max-width: 768px) {
        padding: 0.5rem 1rem;
        margin-top: 4rem;
    }

    .gallery-slide-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 24px;

        @media screen and (max-width: 1200px) {
            gap: 16px;
        }

        @media screen and (max-width: 576px) {
            gap: 12px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-auto-flow: dense;
        }

        .image-wrapper {
            grid-column: span 1;
            overflow: hidden;
            border-radius: 0.5rem;
            transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out;

            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 14px 23px -1px #c6c6c6;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.5rem;
                user-select: none;
                object-fit: cover;
            }

            &.big {
                grid-column: span 2;

                img {
                    aspect-ratio: 16/9;
                }
            }

            &.portrait {
                grid-row: span 2;
            }

            &.img-right img {
                object-position: right;
            }

            &.img-20 img {
                object-position: 20%;
            }

            &.img-80 img {
                object-position: 80%;
            }
        }
    }
}
</style>

<style lang="scss">
@import "@/css/variables.scss";
.swiper-pagination.grid {
    bottom: 0 !important;

    @media screen and (max-width: 1024px) {
        bottom: -0.5rem !important;
    }

    @media screen and (max-width: 768px) {
        bottom: -1.5rem !important;
    }

    .swiper-pagination-bullet {
        width: 0.75rem;
        height: 0.75rem;
        min-width: 8px;
        min-height: 8px;

        &-active {
            background-color: $bbBlue;
        }
    }
}
</style>