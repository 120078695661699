<template>
  <button @click="$emit('sidebarToggle')" class="menu" :class="[sidebarVisibility && 'opened']">
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <path class="line line1" :class="[dark & !sidebarVisibility && 'dark']" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
      <path class="line line2" d="M 20,50 H 80" :class="[dark & !sidebarVisibility && 'dark']" />
      <path class="line line3" :class="[dark & !sidebarVisibility && 'dark']" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
    </svg>
  </button>
</template>

<script>
export default {
  props: ["sidebarVisibility", "dark"],
  emits: ["sidebarToggle"],
};
</script>

<style lang="scss" scoped>
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 201;

  > svg {
    display: inline-block;
    width: 4rem;
    height: 4rem;
  }

  &.opened {
    &:after {
      opacity: 0;
    }
  }
  &:after {
    opacity: 0;
    transition: 0.4s;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: 0;
    content: "MENU";
    font-size: 0.55rem;
    width: 100%;
  }
}

.dark {
  stroke: black !important;
  fill: none !important;
}

.line {
  fill: none;
  stroke: rgb(255, 255, 255);
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
  transition: 0.3s;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
  opacity: 1;
}
</style>
