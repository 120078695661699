<script setup>
import WhatsAppIcon from "./WhatsAppIcon.vue"
import PhoneCallIcon from "./PhoneCallIcon.vue"

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from "swiper"
import 'swiper/scss';

// import advisorsConfig from "./advisorsConfig"
import { ref, onMounted } from "vue";
import axios from 'axios';

import store from "@/store"

onMounted(() => {
    showAdvisorsData();
});

const advisorsData = ref(null)
const showAdvisorsData = (() => {
    axios.get(`/api/getCustomerAdvisor`)
        .then((res) => {
            advisorsData.value = res.data;
        })
        .catch((error) => {
            console.error(error);
        });
})

</script>

<template>
    <section class="customer-advisor" id="customerAdvisor">
        <div class="customer-content">

            <h2>Lassen Sie sich jetzt unverbindlich beraten!</h2>
            <Swiper class="advisors-wrapper" :grab-cursor="true" :loop="true" :looped-slides="4" :space-between="20"
                :modules="[Autoplay]" :autoplay="{
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }" :breakpoints="{
    0: {
        slidesPerView: 1.3,
    },
    500: {
        slidesPerView: 2.3,
    },
    700: {
        slidesPerView: 3.3,
    },
    786: {
        slidesPerView: 4.3,
    },
    1120: {
        slidesPerView: 5.3,
    },
    1800: {
        slidesPerView: 6.3,
    },
    2400: {
        slidesPerView: 7.3,
    },
}">
                <SwiperSlide class="swiper-slide" v-for="advisor in advisorsData">
                    <div class="advisor-card">
                        <img loading="lazy" :src="`https://fotos.baudiscount.de/fachberatung-team${advisor.avatarSrc}`" alt="" class="advisor-avatar">
                        <div class="details-card">
                            <p class="advisor-name">{{ advisor.name }}</p>
                            <p class="advisor-position">{{ advisor.position }}</p>
                            <a class="advisor-whatsapp" target="_blank" :href="'https://wa.me/' + advisor.whatsapp">
                                <WhatsAppIcon />
                                {{ advisor.whatsapp }}
                            </a>
                            <a class="advisor-tel" :href="'tel:' + advisor.tel">
                                <PhoneCallIcon />
                                {{ advisor.telDisplayed }}
                            </a>
                            <a class="advisor-mail" :href="'mailto:' + advisor.email">{{ advisor.email }}</a>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

            <div class="description-customer">
                <p>Nehmen Sie Kontakt mit uns auf - wir informieren Sie jederzeit zuverlässig, unverbindlich und kostenlos
                    über unsere Produkte und Dienstleistungen.</p>
                <button @click="store.callModal.openModal">
                    Kostenloser Rückrufservice
                </button>
            </div>

        </div>
    </section>
</template>

<style lang="scss" scoped>
@import "@/css/variables.scss";

.customer-advisor {
    position: relative;

    .customer-content {
        padding: 3.125rem 1rem 4.25rem;
        background: rgb(252, 235, 255);
        background: linear-gradient(105deg, rgba(252, 235, 255, 1) 7%, rgba(244, 251, 255, 1) 46%, rgba(217, 238, 255, 1) 83%);
        overflow: hidden;

        .swiper {
            overflow: visible;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 3rem;

        h2 {
            font-weight: 700;
            font-size: 2rem;
            line-height: 1;
            margin-bottom: 0.5rem;
            text-align: center;
        }

        @media screen and (max-width:576px) {
            padding-inline: 1rem;
        }
    }

    .advisors-wrapper {
        width: 100%;
        display: block;

        &.onlyFenster {
            max-width: 1180px;
            overflow-y: visible;
            overflow-x: clip;

            @media screen and (max-width:1180px) {
                max-width: unset;
                overflow: visible;
            }
        }

        .advisor-card {
            aspect-ratio: 1.12;
            display: grid;
            grid-template-columns: 18% 34% 48%;
            grid-template-rows: 46% 12% 42%;

            @media screen and (max-width:576px) {
                padding: 0.75rem;
                aspect-ratio: 1.2;
            }

            img {
                border-radius: 50%;
                grid-column: 1/3;
                grid-row: 1/3;
                max-width: 100%;
            }

            .details-card {
                padding: 0.75rem 1rem;
                background-color: #fff;
                border-radius: 4px;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                grid-column: 2/4;
                grid-row: 2/4;
                z-index: 1;
                height: fit-content;

                @media screen and (max-width:576px) {
                    padding: 0.75rem;
                }

                p,
                a {
                    margin: 0;
                    font-weight: 300;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    color: #263238;

                    svg {
                        height: 0.875rem;
                        width: 0.875rem;
                        margin-right: 2px;
                    }
                }

                a {
                    cursor: pointer;
                    display: block;
                }

                .advisor-name {
                    font-weight: 700;
                    font-size: 1rem;
                    text-transform: uppercase;
                    margin-bottom: 0.25rem;
                }

                .advisor-position {
                    font-weight: 400;
                    color: $bbBlueLighter;
                    margin-bottom: 0.5rem;
                }

                .advisor-whatsapp {
                    margin-bottom: 0.25rem;
                }

                .advisor-tel {
                    margin-bottom: 0.5rem;
                }

                .advisor-mail {
                    text-decoration: underline;
                    text-decoration-color: #263238;
                }
            }
        }
    }

    .description-customer {
        max-width: 920px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2.25rem;

        p {
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.75rem;
            color: #263238;
            text-align: center;
            margin: 0;
        }

        a {
            text-decoration: underline;
        }

        button {
            border: 1px solid transparent;
            background-color: $bbBlue;
            color: #fff;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5rem;
            border-radius: 8px;
            padding: 0.625rem 3.875rem;
            transition: background-color 0.4s ease-in-out;
            cursor: pointer;

            &:hover {
                background: $bbBlueLighter;
            }

            @media screen and (max-width:768px) {
                width: 60%;
                padding: 0.875rem 3.875rem;
                font-size: 1.25rem;
            }

            @media screen and (max-width:576px) {
                width: 100%;
            }
        }
    }
}

.customer-advisor-hidden {
    display: none !important;
}
</style>