<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.77734 1.28158C4.36124 1.28158 0.781576 4.86124 0.781576 9.27734C0.781576 13.6934 4.36124 17.2731 8.77734 17.2731C13.1934 17.2731 16.7731 13.6931 16.7731 9.27734C16.7731 4.86124 13.1934 1.28158 8.77734 1.28158ZM0.0546875 9.27734C0.0546875 4.45979 3.95979 0.554688 8.77734 0.554688C13.5949 0.554688 17.5 4.45979 17.5 9.27734C17.5 14.0945 13.5949 18 8.77734 18C3.95979 18 0.0546875 14.0949 0.0546875 9.27734Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.84132 3.54175C5.04388 4.99125 4.53939 7.01842 4.53939 9.27734C4.53939 11.5363 5.04389 13.5634 5.84133 15.0129C6.64596 16.4755 7.69863 17.2731 8.77496 17.2731C8.97569 17.2731 9.13841 17.4358 9.13841 17.6366C9.13841 17.8373 8.97569 18 8.77496 18C7.31115 18 6.06431 16.9262 5.20446 15.3633C4.33743 13.7873 3.8125 11.6349 3.8125 9.27734C3.8125 6.9198 4.33742 4.76736 5.20445 3.19138C6.06429 1.62845 7.31113 0.554688 8.77496 0.554688C8.97569 0.554688 9.13841 0.717407 9.13841 0.918132C9.13841 1.11886 8.97569 1.28158 8.77496 1.28158C7.69861 1.28158 6.64594 2.07919 5.84132 3.54175Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.77751 0.607666C8.97823 0.607666 9.14095 0.770385 9.14095 0.97111V17.5834C9.14095 17.7841 8.97823 17.9469 8.77751 17.9469C8.57678 17.9469 8.41406 17.7841 8.41406 17.5834V0.97111C8.41406 0.770385 8.57678 0.607666 8.77751 0.607666Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.109375 9.27775C0.109375 9.07703 0.272094 8.91431 0.472819 8.91431H8.77897C8.97969 8.91431 9.14241 9.07703 9.14241 9.27775C9.14241 9.47848 8.97969 9.64119 8.77897 9.64119H0.472819C0.272094 9.64119 0.109375 9.47848 0.109375 9.27775Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.30859 4.94181C1.30859 4.74109 1.47131 4.57837 1.67204 4.57837H8.69668C8.89741 4.57837 9.06013 4.74109 9.06013 4.94181C9.06013 5.14254 8.89741 5.30526 8.69668 5.30526H1.67204C1.47131 5.30526 1.30859 5.14254 1.30859 4.94181Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.30859 13.6137C1.30859 13.413 1.47131 13.2502 1.67204 13.2502H8.69668C8.89741 13.2502 9.06013 13.413 9.06013 13.6137C9.06013 13.8144 8.89741 13.9771 8.69668 13.9771H1.67204C1.47131 13.9771 1.30859 13.8144 1.30859 13.6137Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.4805 9.27726C14.4805 9.07654 14.6432 8.91382 14.8439 8.91382H16.0572C16.2579 8.91382 16.4206 9.07654 16.4206 9.27726C16.4206 9.47799 16.2579 9.64071 16.0572 9.64071H14.8439C14.6432 9.64071 14.4805 9.47799 14.4805 9.27726Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.078 4.9574C15.1915 5.123 15.1492 5.3492 14.9836 5.46263L13.9829 6.14809C13.8173 6.26153 13.5911 6.21924 13.4777 6.05365C13.3643 5.88805 13.4065 5.66185 13.5721 5.54841L14.5728 4.86296C14.7384 4.74952 14.9646 4.7918 15.078 4.9574Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.1819 2.42391C12.3614 2.51363 12.4343 2.73192 12.3445 2.91148L11.8019 3.99741C11.7122 4.17697 11.4939 4.24979 11.3144 4.16007C11.1348 4.07035 11.062 3.85206 11.1517 3.6725L11.6943 2.58657C11.784 2.40701 12.0023 2.33419 12.1819 2.42391Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.6485 14.1822C11.8217 14.0808 12.0444 14.1389 12.1458 14.3121L12.7593 15.3591C12.8607 15.5323 12.8026 15.755 12.6294 15.8564C12.4562 15.9579 12.2336 15.8997 12.1321 15.7266L11.5187 14.6796C11.4172 14.5064 11.4753 14.2837 11.6485 14.1822Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.6913 12.1534C13.7934 11.9806 14.0163 11.9233 14.1891 12.0255L15.234 12.6433C15.4068 12.7455 15.4641 12.9684 15.3619 13.1411C15.2598 13.3139 15.0369 13.3712 14.8641 13.269L13.8192 12.6512C13.6464 12.549 13.5891 12.3262 13.6913 12.1534Z"
            fill="#263238" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.2225 3.34568C13.3867 3.46111 13.4263 3.6878 13.3109 3.85202L9.67953 9.01813L13.845 9.74376C14.0427 9.77821 14.1751 9.96644 14.1406 10.1642C14.1062 10.3619 13.9179 10.4943 13.7202 10.4599L8.4259 9.53758L12.7162 3.43401C12.8316 3.2698 13.0583 3.23025 13.2225 3.34568Z"
            fill="#263238" />
    </svg>
</template>