const useValidator = () => {
    const validateInputObjectTypes = (dataObject) => {
        if (
            typeof dataObject !== "object" ||
            dataObject === null ||
            Array.isArray(dataObject)
        )
            throw "Not an object";

        if (!Object.keys(dataObject).includes("validation"))
            throw "Missing 'validation' key";

        if (
            (typeof dataObject.validation !== "object" &&
                dataObject.validation !== false) ||
            dataObject === null ||
            Array.isArray(dataObject)
        )
            throw "'validation' key must be an object or false";
    };

    const validateRequired = (dataObject) => {
        if (
            typeof dataObject.validation.required === "boolean" ||
            typeof dataObject.validation.required.value === "boolean"
        ) {
            if (
                dataObject.validation.required === true ||
                dataObject.validation.required?.value === true
            ) {
                if (dataObject.value?.length < 1 || dataObject.value === false) {
                    dataObject.validation.isValid = false;
                    if (typeof dataObject.validation.required?.message === "string")
                        dataObject.validation.errorMessage =
                            dataObject.validation.required.message;
                    return false;
                } else return true;
            } else return true;
        } else
            throw "'required' key must be and boolean or object with boolean value key";
    };

    const validateRegExp = (dataObject) => {
        // if regex is regexp instance
        if (dataObject.validation.regex instanceof RegExp) {
            if (!dataObject.validation.regex.test(dataObject.value)) {
                dataObject.validation.isValid = false;
                return false;
            } else return true;
        }

        // if regex is object with value and message
        if (dataObject.validation.regex?.value instanceof RegExp) {
            if (!dataObject.validation.regex.value.test(dataObject.value)) {
                dataObject.validation.isValid = false;
                // if message exists
                if (typeof dataObject.validation.regex.message === "string")
                    dataObject.validation.errorMessage =
                        dataObject.validation.regex.message;

                return false;
            } else return true;
        }

        throw "'regex' key must be and RegExp or object with RegExp value key";
    };

    const validateMinLength = (dataObject) => {
        //if minLength is number
        if (typeof dataObject.validation.minLength === "number") {
            if (dataObject.validation.minLength < 1)
                throw "'minLength' must be greater than 0";
            if (dataObject.value?.length < dataObject.validation.minLength) {
                dataObject.validation.isValid = false;
                return false;
            } else return true;
        }

        //if minLength is object with number value
        if (typeof dataObject.validation.minLength?.value === "number") {
            if (dataObject.validation.minLength.value < 1)
                throw "'minLength' must be greater than 0";
            if (dataObject.value?.length < dataObject.validation.minLength.value) {
                dataObject.validation.isValid = false;
                // if message exists
                if (typeof dataObject.validation.minLength.message === "string")
                    dataObject.validation.errorMessage =
                        dataObject.validation.minLength.message;
                return false;
            } else return true;
        }

        throw "'minLength' key must be and number or object with number value key";
    };

    const validateMaxLength = (dataObject) => {
        //if maxLength is number
        if (typeof dataObject.validation.maxLength === "number") {
            if (dataObject.validation.maxLength < 1)
                throw "'maxLength' must be greater than 0";
            if (dataObject.value?.length > dataObject.validation.maxLength) {
                dataObject.validation.isValid = false;
                return false;
            } else return true;
        }

        //if maxLength is object with number value
        if (typeof dataObject.validation.maxLength?.value === "number") {
            if (dataObject.validation.maxLength.value < 1)
                throw "'maxLength' must be greater than 0";
            if (dataObject.value?.length > dataObject.validation.maxLength.value) {
                dataObject.validation.isValid = false;
                // if message exists
                if (typeof dataObject.validation.maxLength.message === "string")
                    dataObject.validation.errorMessage =
                        dataObject.validation.maxLength.message;
                return false;
            } else return true;
        }

        throw "'maxLength' key must be and number or object with number value key";
    };

    const validateInput = (dataObject) => {
        try {
            validateInputObjectTypes(dataObject);
        } catch (e) {
            throw new Error(e);
        }

        if (dataObject.validation === false) return true; //git

        const validationKeys = Object.keys(dataObject.validation);
        dataObject.validation.isValid = true;
        dataObject.validation.errorMessage = null;

        //if input is not required but if has an value which needs to validate
        // input is not validating when has no value

        if (
            (!validationKeys.includes("required") ||
                dataObject.validation.required === false ||
                dataObject.validation.required?.value === false) &&
            dataObject.value?.length === 0
        )
            return true;

        try {
            // required validation
            if (validationKeys.includes("required")) {
                if (!validateRequired(dataObject)) return false;
            }

            // regex validation
            if (validationKeys.includes("regex")) {
                if (!validateRegExp(dataObject)) return false;
            }

            //min length validation
            if (validationKeys.includes("minLength")) {
                if (!validateMinLength(dataObject)) return false;
            }
            //max length validation
            if (validationKeys.includes("maxLength")) {
                if (!validateMaxLength(dataObject)) return false;
            }

            return true
        } catch (e) {
            throw new Error(e);
        }
    };

    const validateAll = (formData) => {
        const keys = Object.keys(formData)

        let isValid = true
        keys.forEach((el) => {
            if (!validateInput(formData[el])) isValid = false
        })

        return isValid
    }

    const resetValues = (formData) => {
        const keys = Object.keys(formData)
        keys.forEach((el) => {
            if (Object.keys(formData[el]).includes('defaultValue')) {
                formData[el].value = formData[el].defaultValue
                if (formData[el].validation !== false) {
                    formData[el].validation.isValid = true
                    formData[el].validation.errorMessage = null
                }
            }
        })
    }

    return {
        validateInput,
        validateAll,
        resetValues
    }
};

export default useValidator