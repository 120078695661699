<script setup>
import ImageHeader from '@/components/ImageHeader.vue';
import Kontaktinformationskarte from "@/components/Kontaktinformationskarte.vue";
import GoogleMaps from "@/components/GoogleMaps.vue";
import GridGallery from "@/components/GridGallery/GridGallery.vue";

import kontaktkarteConf from "@/components/Kontaktkarte/kontaktkarteConf.js";
import aboutGalleryConfig from "@/components/GridGallery/aboutGalleryConfig.js";

import { computed } from "vue";

const city = computed(() => {
    return kontaktkarteConf.contactData[slug];
})

const { slug } = defineProps({
    slug: {
        type: String,
        required: true
    }
})

</script>

<template>
    <section class="kontaktieren_stad">
        <ImageHeader :headerBg="require(`@/assets/kontakt/header-${city.link}.webp`)" :title="`Baudiscount ${city.name}`" />

        <div class="contact_wraper">
            <p class="stad_city_info">{{ city["city-info"] }}</p>
        </div>

        <div class="contact_wraper">
            <Kontaktinformationskarte :config-data="city"></Kontaktinformationskarte>
        </div>

        <GoogleMaps :google-map="city['google-map']" :adress="city.address"></GoogleMaps>

        <section class="uber_uns_customer_slider">
            <div class="contact_wraper">
                <h2 class="customer_slider_h2">
                    Wir beraten Sie gerne ausführlich zu <span>allen Gartenhäusern</span>, die Sie im Baudiscount kaufen
                    können.
                    Sollten Sie Fragen haben, zögern Sie nicht und vereinbaren noch heute
                    einen <span>kostenlosen und unverbindlichen</span> Termin für eine Beratung.
                </h2>
                <GridGallery :imagesData="aboutGalleryConfig"></GridGallery>
            </div>
        </section>
    </section>
</template>

<style lang="scss" scoped>
@import "@/css/config/config.scss";

.kontaktieren_stad {
    .contact_wraper {
        margin-inline: auto;
        width: 75%;
        max-width: 1450px;
        height: fit-content;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            width: 95%;
        }
    }

    .stad_city_info {
        margin: 3rem 0 0;

        font-size: clamp(.75rem, .4651rem + .5935vw, 1rem);
    }

    .uber_uns_customer_slider {
        padding: 3rem 0 0;
        .customer_slider_h2 {
            margin-top: 0;
            font-weight: 700;
            font-size: 2rem;
            line-height: 1.5;

            span {
                color: $bbBlue;
            }
        }

        .gridGallery {
            width: 95%;
        }
    }
}
</style>