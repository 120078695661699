<script>
export default {
    inheritAttrs: false
}
</script>


<script setup>

const props = defineProps({
    checked: {
        type: Boolean
    },
    type: {
        type: String,
        required: false,
        default: 'checkbox',
        validator: (value) => {
            return ['radio', 'checkbox'].includes(value)
        }
    },
    isError: {
        type: Boolean
    },
    label: {
        type: String,
        required: false
    },
    id: {
        type: String,
        required: true
    }
})

const emit = defineEmits(['check'])

</script>

<template>
    <div class="option-wrapper">
        <div class="custom-checbox-wrapper">
            <input :type="props.type" :id="props.id" :checked="props.checked" @change="emit('check', props.label)"
                v-bind="$attrs">
            <div class="custom-checkbox" :class="{ 'error': props.isError }"></div>
        </div>
        <label v-if="props.label" :for="props.id">{{ props.label }}</label>
    </div>
</template>

<style lang="scss" scoped>
@import "@/css/config/config.scss";

.option-wrapper {
    display: flex;
    align-items: center;
    column-gap: 0.375rem;

    label {
        color: #263238;
        font-size: 0.875rem;
        line-height: 1.5;
        font-weight: 300;
    }

    .custom-checbox-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1rem;

        @include break("xs down") {
            height: 1.25rem;
        }

        input {
            opacity: 0;
            margin: 0;
            height: 100%;
            aspect-ratio: 1;
            cursor: pointer;

            &:checked+.custom-checkbox {
                border-color: $bbBlue;
                background-color: $bbBlue;
            }
        }

        .custom-checkbox {
            position: absolute;
            height: 100%;
            aspect-ratio: 1;
            top: 0;
            left: 0;
            background-color: #FFF;
            border: 1px solid rgba(0, 0, 0, 0.08);
            border-radius: 2px;
            pointer-events: none;

            &.error {
                border-color: red;
            }

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                aspect-ratio: 1;
                background-image: url("@/assets/svg/check.svg");
                background-size: contain;
            }
        }
    }
}
</style>