<script setup>
import Logo from "./svg/Logo.vue";
import Number from "./Number.vue";
import Tuv from "./svg/Tuv.vue";
import Hamburger from "./svg/Hamburger.vue";
import Sidebar from "./Sidebar.vue";
import TuvModal from "./TuvModal.vue";

import { ref, watch } from 'vue'
import NavigationLink from "./NavigationLink.vue";
import { RouterLink, useRoute } from "vue-router";

import store from "@/store";

const props = defineProps([ "dark", "sticky"])
const route = useRoute()

const sidebarVisibility = ref(false)
const tuvModalOpened = ref(false)

const isMobile = ref(window.innerWidth <= 800);

window.addEventListener('resize', () => {
     if(window.innerWidth <= 800 && !isMobile.value)
          isMobile.value = true
     if(window.innerWidth > 800 && isMobile.value)
          isMobile.value = false
})

const sidebarToggle = () => {
     sidebarVisibility.value = !sidebarVisibility.value;
}

watch(route, () => {
     if(sidebarVisibility.value === true) sidebarVisibility.value = false
})

</script>

<template>
     <header ref="header" :class="{sticky: props.sticky}">
          <div class="images__wrapper">
               <div class="logo_wrapper">
                    <RouterLink :to="{name: 'Startseite'}"><Logo :dark="dark" :host="store.multisite.host"/></RouterLink>
                    <!-- <Tuv v-if="isMobile" @click="tuvModalOpened = true" class="tuv"/> -->
               </div>
               <div class="links_images_wrapper">
                    <!-- <Tuv v-if="!isMobile" @click="tuvModalOpened = true" class="tuv"/> -->
                    <a :href="`tel:` + store.multisite.numberAnchor"><Number :dark="dark" :number="store.multisite.number"/></a>
               </div>
          </div>
          <nav>
               <NavigationLink v-if="!isMobile" :dark="dark" pageName = "Startseite">Startseite</NavigationLink>
               <NavigationLink v-if="!isMobile" :dark="dark" pageName = "Uber Uns">Über uns</NavigationLink>
               <NavigationLink v-if="!isMobile" :dark="dark" pageName = "Produkte">Produkte</NavigationLink>
               <NavigationLink v-if="!isMobile" :dark="dark" pageName = "Newsletter">Bauratgeber</NavigationLink>
               <NavigationLink v-if="!isMobile" :dark="dark" pageName = "B2B">Geschäftskunden</NavigationLink>
               <NavigationLink v-if="!isMobile" :dark="dark" pageName = "Kontakt">Kontakt</NavigationLink>
               <Hamburger v-if="isMobile" @sidebarToggle="sidebarToggle" :dark="dark" :sidebarVisibility="sidebarVisibility"></Hamburger>
               <Sidebar
                    v-if="isMobile"
                    :sidebarVisibility="sidebarVisibility"
                    :dark="dark"
               >
               </Sidebar>
          </nav>
     </header>
     <TuvModal :tuv-opened="tuvModalOpened" @closeModal = "tuvModalOpened = false"/>
</template>

<style scoped lang="scss">
header {
     width: 100vw;
     position: fixed;
     top: 0;
     left: 0;
     z-index: 100;
     padding: 1rem 5%;
     display: flex;
     align-items: center;
     justify-content: space-between;
     color: white;
     column-gap: 1.375rem;

     &.sticky {
          position: sticky;
          background-color: #FFF;
          border-bottom: 1px solid #B0BEC5;
     }

     @media screen and (max-width: 1630px) {
          padding: 1rem 2%;
     }

     @media screen and (max-width: 1024px) {
          padding: 1rem 3%;
     }

     @media screen and (max-width: 768px) {
          gap: 2rem;
          padding: 1rem;
          align-items: flex-start;
     }

     @media screen and (min-width: 2160px) {
          padding: 1rem 10rem;
     }

     > .images__wrapper {
          align-items: center;
          gap: 1.5rem;
          display: flex;

          @media screen and (min-width: 2160px) {
               margin-left: 0%;
          }

          @media screen and (max-width: 576px) {
               width: 100%;
               flex-direction: column;
               gap: 0;
               align-items: flex-start;
               row-gap: 1rem;
          }

          .logo_wrapper {
               display: flex;
               align-items: center;
               gap: 2em;

               @media screen and (max-width: 350px) {
                    gap: 1rem;
               }

               a {
                    display: inline-block;
               }

          }
          > .links_images_wrapper {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               gap: 1.375rem;

               a {
                    text-decoration: none;
               }

               @media screen and (max-width: 576px) {
                    align-self: center;
               }
          }

          .tuv {
               height: 4rem;
               cursor: pointer;
               @media screen and (max-width: 768px) {
                    width: 7rem;
               }
               @media screen and (max-width: 350px) {
                    width: 5rem;
               }
          }
     }
}

nav {
     gap: 1.25rem;
     display: flex;
     height: 100%;
     align-items: center;
     position: relative;

     @media screen and (max-width: 768px) {
          position: fixed;
          top: 1rem;
          right: 1rem;
          height: fit-content;
     }

     .future__text {
          font-size: 2rem;
          font-weight: 800;
     }

     > span {
          font-size: 1.3rem;
          font-weight: 300;
          transition: 0.3s;
          display: block;
     }
}
</style>