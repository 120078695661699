<script>
export default {
    inheritAttrs: false
}
</script>

<script setup>
import { computed} from 'vue';
import TelefonSelect from '../TelefonSelect.vue';


const props = defineProps({
    modelValue: {
        required: true
    },
    label: {
        type: String,
        required: true
    },
    id: {
        type: String,
        required: true
    },
    isError: {
        type: Boolean
    },
    type: {
        type: String,
        default: "text",
        validator: (value) => {
            return ['text', 'email', 'telefon', 'textarea'].includes(value)
        }
    },
})

const emit = defineEmits(['update:modelValue', 'onCountryCodeChange'])

const setCountryCode = (code) => {
    emit('onCountryCodeChange', code)
}

const inputValue = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

</script>

<template>
    <div class="container" :class="{'error': isError}">
        <label :for="props.id">{{ props.label }}</label>

        <div class="input-wrapper telefon" v-if="props.type === 'telefon'">
            <TelefonSelect @onCountryChange="setCountryCode" color="blue"/>
            <input type="text" :id="props.id" v-model="inputValue" v-bind="$attrs">
        </div>

        <div class="input-wrapper" v-else-if="props.type !== 'textarea'">
            <input :type="props.type === 'email' ? 'email' : 'text'" :id="props.id" @input="qweqwe" v-model="inputValue" v-bind="$attrs">
        </div>

        <textarea v-else-if="props.type === 'textarea'" :id="props.id" rows="3" v-model="inputValue" v-bind="$attrs"></textarea>
    </div>
</template>

<style lang="scss" scoped>
$bbBlue: #2D3790;

.container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    flex-grow: 1;

    label {
        color: #263238;
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 300;
    }

    &.error {
        .input-wrapper {
            border-color: red;

            &:focus-within {
                border-color: red;
                outline-color: red;
            }
        }

        textarea {
            border-color: red;

            &:focus {
                border-color: red;
                outline-color: red;
            }
        }
    }

    .input-wrapper {
        border: 1px solid #B0BEC5;
        outline: 1px solid transparent;
        border-radius: 2px;
        transition: border-color 0.15s ease-in-out, outline-color 0.15s ease-in-out;

        &.telefon {
            display: flex;
        }

        &:focus-within {
            border-color: $bbBlue;
            outline-color: $bbBlue;
        }

        input {
            border: none;
            outline: none;
            width: 100%;
            padding: 0.5rem;
            font-size: 0.75rem;
        }
    }

    textarea {
        border: 1px solid #B0BEC5;
        outline: 1px solid transparent;
        border-radius: 2px;
        width: 100%;
        padding: 0.5rem;
        resize: none;
        transition: border-color 0.15s ease-in-out, outline-color 0.15s ease-in-out;
        font-size: 0.875rem;

        &:focus {
            border-color: $bbBlue;
            outline-color: $bbBlue;
        }
    }
}</style>