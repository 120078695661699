<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_327_29)">
            <path
                d="M11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM15.948 17.652L10.8 12.492V6H13.2V11.508L17.652 15.96L15.948 17.652Z"
                />
        </g>
        <defs>
            <clipPath id="clip0_327_29">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>