<script setup>
import VanillaCalendar from '@uvarov.frontend/vanilla-calendar';

import '@/css/vanilla-calendar.min.css';
import useDateConverters from '@/utils/useDateConverters';
import ClockIcon from '../svg/callModal/ClockIcon.vue';
import TimezoneIcon from '../svg/callModal/TimezoneIcon.vue';

import { ref, computed, reactive, onMounted } from 'vue';
import TelefonischeBeratung from '../svg/callModal/TelefonischeBeratung.vue';

const props = defineProps({
    state: {
        type: Object,
        required: true
    },
    selectedDayText: {
        type: String,
        required: true
    }
})

const config = reactive({
    callOffset: 30,
    hoursRange: {
        saturday: {
            start: "09:30",
            end: "12:30",
            maxHour: "10:00"
        },
        workingDay: {
            start: "07:30",
            end: "17:30",
            maxHour: "12:00"
        },
    }
})

const hoursGrid = ref(null)

const { dateToString, dateToTimeString } = useDateConverters()

const availableHours = computed(() => {
    const hoursArray = []

    if (props.state.selectedDate) {
        const today = new Date()
        const offsettedToday = new Date(today.getTime() + config.callOffset * 60 * 1000)

        const selectedDateObject = new Date(props.state.selectedDate)

        let startHourTime
        let endHourTime

        //setting Date objects with start and end time
        if (selectedDateObject.getDay() === 6) {
            startHourTime = new Date(
                `${props.state.selectedDate}T${config.hoursRange.saturday.start}:00`,
            )
            endHourTime = new Date(
                `${props.state.selectedDate}T${config.hoursRange.saturday.end}:00`,
            )
        } else {
            startHourTime = new Date(
                `${props.state.selectedDate}T${config.hoursRange.workingDay.start}:00`,
            )
            endHourTime = new Date(
                `${props.state.selectedDate}T${config.hoursRange.workingDay.end}:00`,
            )
        }

        //generate array
        while (startHourTime <= endHourTime) {
            if (startHourTime > offsettedToday) {
                hoursArray.push(dateToTimeString(startHourTime))
            }

            startHourTime.setTime(startHourTime.getTime() + config.callOffset * 60 * 1000)
        }
    }

    return hoursArray
})

const initCalendar = () => {
    const today = new Date()
    const year = today.getFullYear()

    const todayStringDate = dateToString(today)

    // date of last day in 2 months
    const endDate = new Date(year, today.getMonth() + 10, 0)
    const endDateString = dateToString(endDate)

    //setting initial date
    let initialSelectedDate
    const saturdayEndDate = new Date(
        `${todayStringDate}T${config.hoursRange.saturday.maxHour}:00`,
    )
    const workingDayEndDate = new Date(
        `${todayStringDate}T${config.hoursRange.workingDay.maxHour}:00`,
    )

    if (
        today.getDay() === 0 ||
        (today.getDay() !== 6 && today >= workingDayEndDate)
    ) {
        initialSelectedDate = new Date()
        initialSelectedDate.setDate(today.getDate() + 1)
    } else if (today.getDay() === 6 && today >= saturdayEndDate) {
        initialSelectedDate = new Date()
        initialSelectedDate.setDate(today.getDate() + 2)
    } else {
        initialSelectedDate = today
    }

    const initialSelectedDateString = dateToString(initialSelectedDate)
    props.state.selectedDate = initialSelectedDateString

    //date of first day of current month
    const startDate = new Date(year, today.getMonth(), 1)

    //finding first sunday
    while (startDate.getDay() != 0) {
        startDate.setDate(startDate.getDate() + 1)
    }

    const sundays = []

    //pushing sunday dates into array
    while (startDate <= endDate) {
        sundays.push(dateToString(startDate))
        startDate.setDate(startDate.getDate() + 7)
    }

    props.state.calendar = new VanillaCalendar("#calendar", {
        DOMTemplates: {
            default: `
                        <div class="vanilla-calendar-header">
                            <div class="vanilla-calendar-header__content">
                                <#Month />
                            </div>
                            <#ArrowPrev />
                            <#ArrowNext />
                        </div>
                        <div class="vanilla-calendar-wrapper">
                            <#WeekNumbers />
                            <div class="vanilla-calendar-content">
                                <#Week />
                                <#Days />
                            </div>
                        </div>
                    `,
            month: `
                    <div class="vanilla-calendar-header">
                        <div class="vanilla-calendar-header__content">
                            <#Month />
                        </div>
                    </div>
                    <div class="vanilla-calendar-wrapper">
                        <div class="vanilla-calendar-content">
                            <#Months />
                        </div>
                    </div>
                `,
        },
        date: {
            today: today,
        },
        actions: {
            clickDay(event, dates) {
                props.state.selectedDate = dates[0]
                props.state.selectedHour = null
                if (window.innerWidth < 426) hoursGrid.value.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            },
        },
        settings: {
            lang: "de",
            range: {
                min: initialSelectedDateString,
                max: endDateString,
                disabled: sundays,
            },
            selection: {
                month: true,
                year: true,
            },
            selected: {
                dates: [initialSelectedDateString],
            },
        },
    })
    props.state.calendar.init()
}

onMounted(() => {
    initCalendar()
})

</script>

<template>
    <div class="card-content">
        <div class="header-section">
            <div class="text-section">
                <p class="section-title">Vereinbaren Sie jetzt einen persönlichen Beratungstermin mit uns!</p>
                <p>
                    Wählen Sie eine Termin aus, an dem <br>Sie unser Fachberater zurückrufen soll.
                </p>
            </div>

            <div class="info-section grid-info-section">
                <div class="info-item">
                    <ClockIcon />
                    <span>30&nbsp;min</span>
                </div>
                <div class="info-item">
                    <TelefonischeBeratung />
                    <span>Wir&nbsp;rufen&nbsp;Sie&nbsp;an</span>
                </div>
            </div>
        </div>

        <div class="main-section">
            <div class="calendar-section">
                <div id="calendar"></div>
            </div>
            <div class="hours-section">
                <div class="section-header">
                    <p class="current-day-info">{{ selectedDayText }}</p>
                    <p>Wählen Sie einen geeigneten Zeitpunkt für das Gespräch.</p>
                </div>
                <div class="hours-grid" ref="hoursGrid">
                    <button v-for="hour in availableHours" class="hour-btn"
                        :class="{ selected: props.state.selectedHour === hour }" @click="props.state.selectedHour = hour">
                        {{ hour }}
                    </button>
                </div>
            </div>

            <div class="time-zone-section">
                <span>
                    <TimezoneIcon />
                    Zeitzone
                </span>
                <p>Mitteleuropaische Zeit (UTC+1:00)</p>
            </div>

            <div class="button-wrapper">
                <button class="form-btn" :disabled="!props.state.selectedHour"
                    @click="props.state.activeStep = 2">Weiter</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/css/variables";
.main-section {
    width: 100%;
    padding: 1.75rem 2rem;
    display: grid;
    grid-template-columns: min(38%, 280px) auto;
    column-gap: 2.625rem;
    row-gap: 0.75rem;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 42% auto;
    }

    @media screen and (max-width: 576px) {
        padding-inline: 1.25rem;
        grid-template-columns: 46% auto;
    }

    @media screen and (max-width: 425px) {
        grid-template-columns: 100%;
        row-gap: 1rem;
        padding-bottom: 0;
        padding-inline: 1rem;
    }
}
</style>

<style lang="scss">
@import "@/css/variables";

.header-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-inline: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray_200;

    @media screen and (max-width: 576px) {
        padding-inline: 1.25rem;
    }

    @media screen and (max-width: 425px) {
        flex-direction: column;
        row-gap: 1rem;
        padding-inline: 1rem;
    }

    p,
    span {
        font-size: 0.875rem;
        line-height: 1.5;
        margin-bottom: 0;
    }

    .text-section {
        p {
            color: $gray_900;
            font-weight: 300;

            &.section-title {
                font-weight: 600;
                font-size: 1.75rem;
                margin-bottom: 0.5rem;
            }
        }
    }
}

.info-section {
    align-self: flex-end;
    max-width: 35%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 576px) {
        max-width: 38%;
    }

    @media screen and (max-width: 425px) {
        align-self: flex-start;
        max-width: 100%;
    }

    .info-item {
        display: flex;
        column-gap: 0.75rem;
        width: min-content;

        @media screen and (max-width: 425px) {
            width: max-content;
            max-width: 100%;
        }

        &:first-child {
            margin-bottom: 0.375rem;
        }

        svg {
            height: 1.25rem;
            width: 1.25rem;

            path {
                fill: $gray_200;
            }
        }

        span {
            color: $gray_200;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5;
            width: inherit;
        }
    }
}

.grid-info-section {
    grid-template-columns: repeat(1, 1fr);
}

.main-section {

    .calendar-section {
        aspect-ratio: 1.00526;

        @media screen and (max-width: 425px) {
            aspect-ratio: 0.99074;
        }

        .vanilla-calendar {
            padding: 0;
            width: auto;
            min-width: unset;

            @media screen and (max-width: 1024px) {
                gap: 4px;
            }

            .vanilla-calendar-header {
                padding: 0 4px 0 0;

                &__content {
                    padding: 0;
                    justify-content: flex-start;
                }

                .vanilla-calendar-month {
                    color: #fff;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    padding: 4px 8px;
                    background-color: $bbGreen;
                    border-radius: 4px;
                    margin-right: 8px;
                }

                .vanilla-calendar-year {
                    font-weight: 400;

                    &_disabled {
                        color: #b3b3b3;
                    }
                }

                .vanilla-calendar-arrow {
                    &_next {
                        margin-left: 18px;

                        @media screen and (max-width: 425px) {
                            margin-left: 28px;
                            margin-right: 1%;
                        }
                    }

                    &::before,
                    &::after {
                        background-color: $bbGreen;
                    }

                    &.arrow_disabled {
                        pointer-events: none;

                        &::before,
                        &::after {
                            background-color: $gray_200;
                        }
                    }
                }
            }

            .vanilla-calendar-wrapper {
                height: fit-content;

                .vanilla-calendar-week__day {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    color: #b3b3b3;
                }

                .vanilla-calendar-week {
                    column-gap: 8px;
                    margin-bottom: 8px;

                    &__day {
                        width: 100%;
                    }

                    &__day_weekend {
                        color: $bbGreen;
                    }
                }

                .vanilla-calendar-days {
                    row-gap: 4px;
                    column-gap: 8px;

                    @media screen and (max-width: 1024px) {
                        column-gap: 6px;
                    }

                    .vanilla-calendar-day {
                        &__btn {
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 13px;
                            padding: 0;
                            color: $gray_900;
                            border: 1px solid $bbGreen;
                            background-color: #FFF;
                            border-radius: 4px;
                            width: 100%;
                            height: auto;
                            aspect-ratio: 1;
                            transition: background-color 0.2s ease-in-out;

                            &:hover {
                                background-color: rgba($color: $bbGreen,
                                        $alpha: 0.15);
                            }
                        }

                        &__btn_today {
                            background-color: rgba($color: $bbGreen, $alpha: 0.15);
                        }

                        &__btn_disabled {
                            border: none;
                            color: #b3b3b3;
                        }

                        &__btn_selected {
                            background-color: $bbGreen;
                            color: #fff;
                            pointer-events: none;

                            &:hover {
                                background-color: $bbGreen;
                            }
                        }

                        &__btn_next,
                        &__btn_prev {
                            visibility: hidden;
                        }
                    }
                }

                .vanilla-calendar-months {
                    @media screen and (max-width: 425px) {
                        grid-template-columns: repeat(2, 1fr);
                        row-gap: 8px;
                    }

                    &__month {
                        color: $bbGreen;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 13px;
                        background-color: #FFF;
                        height: 2.75rem;
                        transition: background-color 0.2s ease-in-out;

                        @media screen and (max-width: 1200px) {
                            height: 2rem;
                        }

                        &:hover {
                            background-color: rgba($color: $bbGreen, $alpha: 0.15);
                        }

                        &_selected {
                            background-color: $bbGreen;
                            color: #fff;

                            &:hover {
                                background-color: $bbGreen;
                            }
                        }

                        &_disabled {
                            color: #b3b3b3;
                        }
                    }
                }
            }
        }
    }

    .hours-section {
        .section-header p {
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 1.5;
            color: $gray_900;
            margin-bottom: 0.25rem;

            &.current-day-info {
                font-size: 0.875rem;
            }
        }

        .hours-grid {
            width: 90%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 0.25rem;
            margin-top: 1rem;

            @media screen and (max-width: 1024px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (max-width: 576px) {
                width: 100%;
            }

            .hour-btn {
                background-color: transparent;
                border: 1px solid $bbGreen;
                color: $bbGreen;
                font-weight: 600;
                font-size: 0.6875rem;
                line-height: 1rem;
                padding: 0.375rem;
                cursor: pointer;

                transition: background-color 0.2s ease-in-out;

                @media screen and (max-width: 1024px) {
                    font-size: 1rem;
                    padding: 0.5rem;
                }

                &:hover {
                    background-color: rgba($color: $bbGreen, $alpha: 0.15);
                }

                &.selected {
                    background-color: $bbGreen;
                    color: #fff;
                }
            }
        }
    }

    .time-zone-section {
        @media screen and (max-width: 425px) {
            grid-row: 2/3;
        }

        span {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-bottom: 0.75rem;
            margin-left: 2px;

            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1.5;
            color: $gray_900;
        }

        p {
            font-size: 0.875rem;
            line-height: 1.5;
            color: $gray_900;
        }
    }

    .button-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
}
</style>