import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ServicesView from "../views/ServicesView.vue";
import Newsletter from "../views/NewsletterView.vue";
import ErrorPage from "../views/ErrorPage.vue";
import Impressum from "@/views/Impressum.vue";
import ContactView from "@/views/ContactView.vue";
import KontaktView from "@/views/KontaktView.vue";
import LayoutView from "@/views/LayoutView.vue";
import KontaktierenStadView from "@/views/KontaktierenStadView.vue";


const routes = [
  {
    path: "/",
    name: "Startseite",
    component: HomeView,
  },
  {
    path: "/uber-uns",
    name: "Uber Uns",
    component: AboutView,
  },
  {
    path: "/preis-produktkonfiguratoren",
    redirect: () => {
      return { path: '/produkte' }
    }
  },
  {
    path: "/produkte",
    name: "Produkte",
    component: ServicesView,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
  },
  {
    path: "/abonnieren-sie-unseren-bauratgeber",
    name: "Newsletter",
    component: Newsletter,
  },
  {
    path: "/geschaeftskunden",
    name: "B2B",
    component: ContactView,
  },
  {
    path: "/kontakt",
    component: LayoutView,
    children: [
      {
        path: "",
        name: "Kontakt",
        component: KontaktView,
      },
      {
        path: "berlin",
        name: "KontaktierenStad.berlin",
        component: <KontaktierenStadView slug="berlin"/>,
      },
      {
        path: "hamburg",
        name: "KontaktierenStad.hamburg",
        component: <KontaktierenStadView slug="hamburg"/>,
      },
      {
        path: "frankfurt",
        name: "KontaktierenStad.frankfurt",
        component: <KontaktierenStadView slug="frankfurt"/>,
      },
      {
        path: "muenchen",
        name: "KontaktierenStad.muenchen",
        component: <KontaktierenStadView slug="muenchen"/>,
      },
      {
        path: "wien",
        name: "KontaktierenStad.wien",
        component: <KontaktierenStadView slug="wien"/>,
      },
    ]
  },
  {
    path: "/b2b",
    redirect: () => {
      return { path: '/geschaeftskunden' }
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404 Error",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash }
    }

    return {top: 0}
  },
});

export default router;
