<template>
  <div class="main-phone-container" :class="{black: dark}">
    <span class="phone-number">{{ number }}</span>
    <div class="open-hours-container">
      <span>Mo.- Fr. 7:00-18:00 Uhr</span>
      <span>Sa. 9:00-13:00 Uhr</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      required: true,
    },
    number: {
      type: String,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
.main-phone-container {
  color: #FFF;
  text-align: center;

  &.black {
    color: #000;
  }

  .phone-number {
    display: inline-block;
    width: fit-content;
    font-size: 1.875rem;
    font-weight: 600;
    line-height: normal;
    word-break: keep-all;
    white-space: nowrap;
    text-decoration: none;
  }

  .open-hours-container {

    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;

    span {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: normal;
      display: inline-block;
      width: fit-content;
      word-break: keep-all;
      white-space: nowrap;
      text-decoration: none;
    }
  }
}
</style>
