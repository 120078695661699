export default {
    'contactData': {
        'berlin': {
            'numberAnchor': 'tel:+49305771342110',
            'numberToDisplay': '030 / 577 134 2110',
            'whatsappAnchor': 'https://wa.me/491604004348',
            'whatsappToDisplay': '160 4004348',
            'email': 'info@baudiscount.de',
            'fax': '030 / 577 134 2111',
            'address': 'Kurfürstendamm 194, 10707 Berlin',
            'name': 'Berlin',
            'link': 'berlin',
            'opening-hours': '07:00-18:00',
            'opening-hours-saturday': '9:00-13:00',
            'city-info': 'Wenn Sie ein Gartenhaus aus Holz, eine Holzgarage oder einen Holzcarport online bei uns bestellen, können Sie auf unseren hervorragenden Service zählen. Zum bestmöglichen Kundendienst gehört auch, dass wir vor Ort vertreten sind und uns in Ihrer Region gut auskennen. Das Team von Baudiscount-Gartenhaus in Berlin ist telefonisch, per E-Mail, per Fax und per WhatsApp für alle Fragen rund ums Gartenhaus für Sie da – von der Modellauswahl über die verfügbare Ausstattung bis hin zu baurechtlichen Fragen und Anliegen rund um die Montage. So wollen wir sicherstellen, dass vor oder nach Ihrer Bestellung keine Fragen offenbleiben und Sie mit Ihrem Holzgartenhaus oder Ihrer Garage komplett zufrieden sind. Unsere Fachberater kennen sich sehr gut mit unserem Sortiment aus und werden regelmäßig zu neuen Produkten geschult. Darüber hinaus sind wir rund um Berlin gut vernetzt und können Ihnen kompetente Montagepartner vermitteln. Überzeugen Sie sich selbst: Rufen Sie während der Sprechzeiten bei unserem freundlichen Serviceteam in Berlin an oder schreiben Sie uns eine Nachricht!',
            'google-map': {
                'lat': '52.50113526436669',
                'lng': '13.31774722464291'
            }
        },
        'hamburg': {
            'numberAnchor': 'tel:+4940573089590',
            'numberToDisplay': '040 / 573 089 590',
            'whatsappAnchor': 'https://wa.me/491604004348',
            'whatsappToDisplay': '160 4004348',
            'email': 'info@baudiscount.de',
            'fax': '040 / 573 089 591',
            'address': 'Ballindamm 27, 20095 Hamburg',
            'name': 'Hamburg',
            'link': 'hamburg',
            'opening-hours': '07:00-18:00',
            'opening-hours-saturday': '9:00-13:00',
            'city-info': 'Mit unserer Kompetenz rund um Holzgartenhäuser, Holzgaragen und Carports aus Holz sind wir auch direkt vor Ort für Sie vertreten. Unser Team von Baudiscount-Gartenhaus in Hamburg steht Ihnen gerne für Ihre Fragen zur Verfügung und kennt sich sowohl mit unserem Sortiment sowie kompetenten Montagepartnern in der Region hervorragend aus. Für jede Frage rund um Ihr neues Gartenhaus erreichen Sie unser Team in Hamburg während der Sprechzeiten telefonisch oder auch per WhatsApp, E-Mail und Fax. Gerne unterstützen wir Sie bereits vor dem Kauf bei der Auswahl eines geeigneten Modells, bei baurechtlichen Fragen sowie bei der Auswahl der passenden Ausstattung für Ihr Gartenhaus. Für alle Produktfragen wird unser Serviceteam regelmäßig geschult. Darüber hinaus können Sie sich auch gerne für alle Fragen rund um Lieferung, Zahlung und Montage an uns wenden. Überzeugen Sie sich am besten selbst: Rufen Sie unser Team am Standort Hamburg gleich an oder schreiben Sie uns eine Nachricht.',
            'google-map': {
                'lat': '53.55273727802509',
                'lng': '9.996549500114378'
            }
        },
        'frankfurt': {
            'numberAnchor': 'tel:+4969348668390',
            'numberToDisplay': '069 / 348 668 390',
            'whatsappAnchor': 'https://wa.me/491604004348',
            'whatsappToDisplay': '160 4004348',
            'email': 'info@baudiscount.de',
            'fax': '069 / 348 668 391',
            'address': 'Opernplatz 14, 60313 Frankfurt am Main',
            'name': 'Frankfurt',
            'link': 'frankfurt',
            'opening-hours': '07:00-18:00',
            'opening-hours-saturday': '9:00-13:00',
            'city-info': 'Wir sind mit unserem Team in Frankfurt auch direkt vor Ort für Sie vertreten. Damit unterscheidet sich unser Angebot deutlich von anonymen Händlern im Internet. Für alle Fragen rund um Ihre Onlinebestellung stehen Ihnen unsere Servicemitarbeiter von Baudiscount-Gartenhaus in Frankfurt zur Verfügung: während der Sprechzeiten telefonisch sowie per WhatsApp, E-Mail oder Fax. Wir kennen uns in der Region hervorragend aus und vermitteln Ihnen auf Anfrage gerne kompetente Montagepartner für Ihr Gartenhaus. Darüber hinaus können Sie sich mit jeder Frage zur Modellauswahl oder zur passenden Ausstattung an uns wenden. Hierfür wird unser Serviceteam regelmäßig geschult und kennt sich sehr gut mit unserem Sortiment aus. Dank ihrer Erfahrung finden unsere Mitarbeiter in Frankfurt ganz sicher eine passende Lösung für jedes mögliche Problem und alle Ihre Wünsche. Überzeugen Sie sich gleich selbst von unserem freundlichen Service rund ums Gartenhaus und rufen Sie uns an oder schreiben Sie uns eine Nachricht!',
            'google-map': {
                'lat': '50.11600293326253',
                'lng': '8.673028155844015'
            }
        },
        'muenchen': {
            'numberAnchor': 'tel:+4989215282400',
            'numberToDisplay': '089 / 215 282 400',
            'whatsappAnchor': 'https://wa.me/491604004348',
            'whatsappToDisplay': '160 4004348',
            'email': 'info@baudiscount.de',
            'fax': '089 / 215 282 401',
            'address': 'Rundfunkplatz 2, 80335 München',
            'name': 'München',
            'link': 'muenchen',
            'opening-hours': '07:00-18:00',
            'opening-hours-saturday': '9:00-13:00',
            'city-info': 'Zu unserem Serviceanspruch gehört auch, vor Ort für Sie da zu sein. Deshalb sind wir, neben weiteren Standorten in Deutschland und Österreich, mit Baudiscount-Gartenhaus in München direkt in Ihrer Region für Sie vertreten. Das unterscheidet uns von vielen anderen Onlinehändlern, die eher anonym sind: Bei uns beantworten kompetente und freundliche Ansprechpartner persönlich Ihre Fragen. Sie erreichen uns während der Sprechzeiten telefonisch sowie auch per WhatsApp, E-Mail und Fax. Unser Serviceteam in München wird regelmäßig zu unseren Produkten geschult, vom Gartenhaus aus Holz über unsere Holzgaragen bis hin zu Holzcarports, und kennt sich darüber hinaus gut in der Umgebung aus. So können wir schon vor dem Kauf alle Ihre Fragen rund um Produktauswahl und Ausstattung beantworten. Außerdem vermitteln wir Ihnen gerne kompetente Montagepartner in der Nähe für Ihr Gartenhaus. Überzeugen Sie sich selbst und rufen Sie uns gleich an oder schreiben Sie uns eine Nachricht – wir finden sicher eine Lösung für all Ihre Wünsche.',
            'google-map': {
                'lat': '48.14598109460186',
                'lng': '11.55592541304458'
            },
        },
        'wien': {
            'numberAnchor': 'tel:+43139810100',
            'numberToDisplay': '01 / 39 810 100',
            'whatsappAnchor': 'https://wa.me/491604004348',
            'whatsappToDisplay': '160 4004348',
            'email': 'info@baudiscount.de',
            'fax': '01 / 39 810 101',
            'address': 'Tuchlauben 7a, AT-1010 Wien',
            'name': 'Wien',
            'link': 'wien',
            'opening-hours': '07:00-18:00',
            'opening-hours-saturday': '9:00-13:00',
            'city-info': 'Wir sind stets bestrebt, Ihnen einen hervorragenden Service rund um Ihr Gartenhaus zu bieten. Deshalb sind wir mit unserem Team von Baudiscount-Gartenhaus in Wien auch direkt in der Region persönlich für Sie vor Ort vertreten. Das unterscheidet uns von anderen, eher anonymen Onlinehändlern. Unser Serviceteam in Wien kennt sich bis ins Detail mit unseren Produkten aus und wird regelmäßig geschult. Darüber hinaus sind wir mit unserem Standort sehr gut in Wien und in der Umgebung vernetzt. So können wir Ihnen auf Wunsch auch kompetente Montagepartner vermitteln. Sie erreichen unser Team in Wien für jede Frage rund um Ihr Gartenhaus, Ihre Holzgarage oder Ihren Holzcarport aus unserem Sortiment telefonisch während unserer Sprechzeiten. Außerdem sind wir auch per WhatsApp, per E-Mail und per Fax für Sie da. Überzeugen Sie sich selbst von unserem Service und rufen Sie uns gleich an oder schreiben Sie uns eine Nachricht. Wir finden sicher eine passende Lösung für Ihre Wünsche sowie jedes Ihrer Anliegen.',
            'google-map': {
                'lat': '48.21051074067077',
                'lng': '16.36947281174772'
            }
        }
    }
};