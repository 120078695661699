<script setup>
import SocialLinks from './SocialLinks.vue';
import { ref } from 'vue';

const props = defineProps({
  dark: {
      type: Boolean,
      required: true
    },
  fixed: {
    type: Boolean
  },
  blueBg: {
    type: Boolean
  },
})

const currentYear = ref(new Date().getFullYear())

</script>

<template>
  <footer :class="{fixed: props.fixed, blueBg: props.blueBg}">
    <social-links :dark="dark"></social-links>
    <div class="rights">
      <p :class="[dark && 'dark']">
        All Rights Reserverd ® www.Baudiscount.de Copyright 2004 - {{ currentYear }} | 
        <router-link :class="[dark && 'dark']" to="/impressum">Impressum</router-link> |
        <router-link :class="[dark && 'dark']" to="/impressum#datenschutz">Datenschutz</router-link> |
        <span class="pointer" id="editCookieConsent">Dateneinstellungen</span>
      </p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  width: 100%;
  z-index: 10;
  padding: 2rem 8% 2rem 5%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: white;
  font-weight: 400;
  margin-top: auto;

  &.blueBg {
    background-color: rgba(2, 163, 249, 0.2);
  }

  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: unset;
    padding: 2rem 2rem 1rem;
    align-items: flex-start;
    gap: 1rem;
  }

  p, a {
    font-size: 0.8rem;
  }

  .socialmedia__links-wrapper {
    display: flex;
    gap: 2rem;
  }
}

.page__counter {
  display: flex;
  position: fixed;
  flex-direction: column;
  right: 1.5rem;
  bottom: 50%;
  transform: translateY(50%);
  color: white;
  align-items: center;
  gap: 1rem;
  z-index: 999;
  transition: 1s;

  > span {
    &:after {
    display: none;
    background: transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: '';
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    z-index: -1;
    transition: 0.2s;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  
  &.activePageCounter {
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;

  @media screen and (max-width: 768px) {
    color: white;
  }

  &:after {
    background: #0062c6 !important;
  }
}
  }

  @media screen and (max-width: 768px) {
    bottom: 25%;
  }

  > .number {
    cursor: pointer;
  }
  .line {
    display: block;
    height: 1px;
    background: white;
    transition: 1s;
  }
}

.rights {
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-start;
  }

  p {
    margin: 0;

    @media screen and (max-width: 768px) {
      width: 85%;
    }
  }

  .pointer {
    cursor: pointer;
  }

  a {
    color: white;
  }
}
</style>