<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
        <path style="fill:#DB2727;" d="M496,369.6c0,27.2-21.6,46.4-48,46.4H48c-26.4,0-48-19.2-48-46.4V126.4C0,99.2,21.6,80,48,80h400
    	c26.4,0,48,19.2,48,46.4V369.6z" />
        <path style="fill:#C41616;" d="M48,80h400c26.4,0,48,19.2,48,46.4v243.2c0,27.2-23.2,46.4-49.6,46.4" />
        <g>
            <path style="fill:#AD0505;" d="M48,80h400c26.4,0,48,19.2,48,46.4V216" />
            <path style="fill:#AD0505;" d="M496,368.8c0,28.8-21.6,47.2-48,47.2H48c-26.4,0-48-20.8-48-48" />
        </g>
        <polygon style="fill:#FFFFFF;" points="360,208 288,208 288,136 208,136 208,208 136,208 136,288 208,288 208,360 288,360 288,288 
    	360,288 " />
        <polygon style="fill:#CEDDDD;" points="288,136 208,136 208,208 199.2,208 296.8,288 360,288 360,208 288,208 " />
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
</template>