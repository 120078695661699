<template>
     <div class="newsletter-view">
          <div class="newsletter-content">
               <div class="newsletter-form-wrapper">
                    <NewsletterFormHeader />
                    <NewsletterForm />
               </div>
               <div class="card-wrapper">
                    <NewsletterDiscountCard />
               </div>
          </div>
     </div>
</template>

<script>
import NewsletterForm from "@/components/Newsletter/NewsletterForm.vue";
import NewsletterDiscountCard from "@/components/Newsletter/NewsletterDiscountCard.vue";
import NewsletterFormHeader from "@/components/Newsletter/NewsletterFormHeader.vue";

export default {
     components: {
          NewsletterFormHeader,
          NewsletterDiscountCard,
          NewsletterForm,
     },
};
</script>

<style scoped lang="scss">
.newsletter-view {
     display: flex;
     padding: 3rem 0;
     justify-content: center;
     align-items: center;
     flex: 1;
     background-color: rgba(2, 163, 249, 0.2);
     z-index: 11;
}

.newsletter-view .newsletter-content {
     width: 100%;
     padding: 0 5%;
     display: flex;
     justify-content: center;
     gap: 18%;
     max-width: 1900px;
     
     @media screen and (min-width: 2160px) {
           gap: 10%;
      }
      
      @media screen and (max-width: 900px) {
            gap: 10%;
      }
      
      @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: center;
            padding: 0 1rem;
            gap: 0;
     }
}

.newsletter-view .newsletter-content .newsletter-form-wrapper {
     container-type: inline-size;
     width: 35%;
     background-color: rgba(255, 255, 255, 0.6);
     border-radius: 1.8rem;
     box-shadow: 7px 7px 50px 0px rgba(2, 163, 249, 0.2);
     padding: 2rem 2rem 2.75rem 2rem;
     display: flex;
     flex-direction: column;
     min-width: 340px;
     max-width: 720px;

     @media screen and (min-width: 2160px) {
          width: 40%;
     }

     @media screen and (max-width: 768px) {
          width: 100%;
          min-width: 250px;
          max-width: 550px;
          min-height: 500px;
     }
}

.card-wrapper {
     width: 35%;
     position: relative;

     @media screen and (max-width: 768px) {
          display: none;
     }
}
</style>