<script setup>
import { reactive, ref } from 'vue';
import useValidator from '@/utils/useValidatorNew.js';
import FormInput from "@/components/FormsComponents/FormInput.vue";
import FormTelInput from "@/components/FormsComponents/FormTelInput.vue";
import FormTextarea from "@/components/FormsComponents/FormTextarea.vue";
import FormRadioCheckbox from "@/components/FormsComponents/FormRadioCheckbox.vue";
import axios from 'axios';

const formData = reactive({
    name: {
        value: "",
        defaultValue: "",
        validation: {
            required: true,
        },
    },
    vorname: {
        value: "",
        defaultValue: "",
        validation: {
            required: true,
        },
    },
    email: {
        value: "",
        defaultValue: "",
        validation: {
            required: true,
            regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        },
    },
    countryCode: {
        value: "+49",
        validation: false
    },
    tel: {
        value: "",
        defaultValue: "",
        validation: {
            required: false,
            regex: /^(?=.*\d)[\d ]+$/,
            minLength: 5,
            maxLength: 20,
        },
    },
    question: {
        value: "",
        defaultValue: "",
        validation: {
            maxLength: 250,
            required: true
        },
    },
    policy: {
        value: false,
        defaultValue: false,
        validation: {
            required: true,
        },
        dontSend: true
    },
    newsletter: {
        value: false,
        defaultValue: false,
        validation: false,
        dontSend: true
    },
})

const state = reactive({
    alertShown: false,
    isError: false,
})

const alertMessage = ref();

const showAlert = (success) => {
    alertMessage.value.textContent = success ? "Viele danke. Wir antworten Ihnen schnellst möglich darauf." : "Fehler aufgetreten, versuchen Sie es erneut."
    if (!success) state.isError = true;
    state.alertShown = true;

    setTimeout(() => {
        state.alertShown = false;

        setTimeout(() => {
            if (!state.isError) {
                resetValues(formData)
            }
            state.isError = false
        }, 400)
    }, 4000)
}

const setCountryCodeValue = (val) => {
    formData.countryCode.value = val
}

const { validateInput, validateAll, resetValues } = useValidator()

const onSubmit = async () => {
    const isFormValid = validateAll(formData)

    if (isFormValid) {
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            if (!formData[key].dontSend && formData[key].value !== '') data.append(key,formData[key].value)
        })

        if (formData.newsletter.value) {
            const newsletterData = new FormData();

            newsletterData.append("name", formData.name.value);
            newsletterData.append("phone", formData.tel.value);
            newsletterData.append("email", formData.email.value);

            try {
                await axios.post('/api/add_to_newsletter', newsletterData);
            } catch (e) {
                console.log(e);
            }
        }

        try {
            await axios.post('/api/question', data)
            showAlert(true)
            resetValues(formData)
        } catch (error) {
            console.log(error);
            showAlert(false)
        }
    }
}

</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="status-alert" :class="{ shown: state.alertShown, error: state.isError }">
            <span class="status-alert-message" ref="alertMessage"></span>
        </div>
        <div class="input-row">
            <FormInput v-model="formData.name.value" label="Name *" id="name"
                :isError="formData.name.validation.isValid === false" @blur="validateInput(formData.name)" />
            <FormInput v-model="formData.vorname.value" label="Vorame *" id="vorname"
                :isError="formData.vorname.validation.isValid === false" @blur="validateInput(formData.vorname)" />
        </div>
        <div class="input-row">
            <FormInput v-model="formData.email.value" label="E-Mail-Adresse *" type="email" id="email"
                :isError="formData.email.validation.isValid === false" @blur="validateInput(formData.email)" />

            <FormTelInput v-model="formData.tel.value" label="Telefonnummer *" id="tel"
                @onCountryCodeChange="setCountryCodeValue" :isError="formData.tel.validation.isValid === false"
                @blur="validateInput(formData.tel)" />
        </div>

        <FormTextarea v-model="formData.question.value" label="Ihre Nachricht" id="question"
            :isError="formData.question.validation.isValid === false" @blur="validateInput(formData.question)" rows="5" />

        <div class="policy-wrapper">
            <FormRadioCheckbox name="Policy" id="policy" :checked="formData.policy.value" type="checkbox"
                @check="formData.policy.value = !formData.policy.value"
                :isError="formData.policy.validation.isValid === false" />
            <label for="policy">
                Ich habe die <a target="_blank" href="/impressum">Datenschutzerklärung</a>
                zur Kenntnis genommen. Ich stimme zu, dass meine Angaben zur Kontaktaufnahme,
                Angebotserstellung, Auftragsabwicklung und für Rückfragen dauerhaft gespeichert
                werden.*
            </label>
        </div>
        <div class="newsletter-wrapper mt-2">
            <FormRadioCheckbox name="Newsletter" id="newsletter" :checked="formData.newsletter.value" type="checkbox"
                @check="formData.newsletter.value = !formData.newsletter.value"
                :isError="formData.newsletter.validation.isValid === false" />
            <label for="newsletter" ref="newsletterContent">
                Neuste Produktinformationen, Rabattcodes und aktuelle Rabattaktionen so wie
                Newsletter
                per E-Mail erhalten.
            </label>
        </div>
        <div>
            <p class="form-label-info">
                Die mit einem * gekennzeichneten Felder sind Pflichtfelder
            </p>
            <p class="form-label-info">
                ** Unsere Fachberater benötigen Ihre Telefonnummer für Rückfragen zu Ihrem Projekt.
                Es ist oftmals einfacher und vor allem schneller, eine Rückfrage telefonisch zu
                stellen,
                als per E-Mail zu formulieren.
            </p>
        </div>

        <button class="submit-btn" type="submit">Nachricht absenden</button>
    </form>
</template>

<style lang="scss" scoped>
@import "@/css/config/config.scss";

form {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    position: relative;
    overflow: hidden;
    padding: 1px;

    .status-alert {
        position: absolute;
        width: fit-content;
        max-width: 80%;
        padding: 1rem 2rem;
        background-color: $bbBlue;
        top: 0rem;
        left: 50%;
        transform: translate(-50%, -200%);
        z-index: 1;
        color: #FFF;
        transition: transform .4s ease-in-out;
        text-align: center;

        @include break("md down") {
            max-width: 95%;
        }

        @include break("xs down") {
            width: 95%;
        }

        span {
            max-width: 100%;
            word-break: break-word;
        }

        &.error {
            background-color: #d64e4e;
        }

        &.shown {
            transform: translate(-50%, 0);
        }
    }

    .input-row {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.25rem;

        @media screen and (max-width: 425px) {
            row-gap: 0.675rem;
            grid-template-columns: 100%;
        }
    }

    .policy-wrapper,
    .newsletter-wrapper {
        display: flex;
        align-items: flex-start;
        column-gap: 0.375rem;

        label {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.5;
            color: #37474F;

            a {
                text-decoration: underline;
                color: #37474F;
                font-weight: 400;
            }
        }
    }

    button {
        margin-left: auto;
        width: fit-content;

        &.submit-btn {
            background-color: $bbMagenta;
            width: fit-content;
            border: none;
            color: #FFF;
            border-radius: 8px;
            padding: 0.75rem 2rem;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 700;
            cursor: pointer;
            transition: transform 0.25s ease-in-out;

            &:hover {
                transform: scale(1.02);
            }

            @media screen and (max-width: 768px) {
                margin-left: 2.75rem
            }

            @media screen and (max-width: 425px) {
                margin-left: 1.5rem;
                width: calc(100% - 3rem);
            }
        }
    }
}
</style>