<script setup>

import { ref } from 'vue';
import { RouterLink} from 'vue-router';

const props = defineProps(["sidebarVisibility", "dark"])

const currentYear = ref(new Date().getFullYear())

</script>

<template>
  <transition name="sidebar">
    <aside v-if="sidebarVisibility" class="sidebar">
      <div class="aside_content_wrapper">
        <div class="sidebar__links-wrapper">

          <div class="link__overflow">
            <transition name="swipe" appear>
              <RouterLink v-if="sidebarVisibility" class="sidebar__link" :to="{ name: 'Startseite' }">
                Startseite
              </RouterLink>
            </transition>
          </div>

          <div class="link__overflow">
            <transition name="swipe" appear>
              <RouterLink v-if="sidebarVisibility" class="sidebar__link" :to="{ name: 'Uber Uns' }">
                Mehr über den DIY Baustoff Marktplatz der Zukunft
              </RouterLink>
            </transition>
          </div>

          <div class="link__overflow">
            <transition name="swipe" appear>
              <RouterLink v-if="sidebarVisibility" class="sidebar__link" :to="{ name: 'Produkte' }">
                Baudiscount Produktkonfiguratoren
              </RouterLink>
            </transition>
          </div>

          <div class="link__overflow">
            <transition name="swipe" appear>
              <RouterLink v-if="sidebarVisibility" class="sidebar__link" :to="{ name: 'Newsletter' }">
                Abonnieren Sie unseren Bauratgeber
              </RouterLink>
            </transition>
          </div>

          <div class="link__overflow">
            <transition name="swipe" appear>
              <RouterLink v-if="sidebarVisibility" class="sidebar__link" :to="{ name: 'B2B' }">
                Geschäftskunden
              </RouterLink>
            </transition>
          </div>

          <div class="link__overflow">
            <transition name="swipe" appear>
              <RouterLink v-if="sidebarVisibility" class="sidebar__link" :to="{ name: 'Kontakt' }">
                Kontakt
              </RouterLink>
            </transition>
          </div>

        </div>
        <div class="sidebar__footer-content">
          <div class="socialmedia__links-wrapper">
            <a target="_blank" rel="ugc" href="https://www.facebook.com/baudiscountde"><img src="@/assets/socials/fblogo.svg"
                alt="Facebook logo" /></a>
            <a target="_blank" rel="ugc" href="https://www.instagram.com/baudiscountde/ "><img src="@/assets/socials/inslogo.svg"
                alt="Twitter logo" /></a>
            <a target="_blank" rel="ugc" href="https://www.linkedin.com/company/baudiscountde"><img
                src="@/assets/socials/lilogo.svg" alt="LinkedIn logo" /></a>
            <a target="_blank" rel="ugc" href="https://www.youtube.com/c/Baudiscountde"><img src="@/assets/socials/ytlogo.svg"
                alt="Youtube logo" /></a>
            <a target="_blank" rel="ugc" href="https://www.pinterest.de/baudiscountde"><img src="@/assets/socials/pinlogo.svg"
                alt="Instagram logo" /></a>
            <a target="_blank" rel="ugc" href="https://twitter.com/baudiscountde"><img src="@/assets/socials/twitterlogo.svg"
                alt="Instagram logo" /></a>
            <a target="_blank" rel="ugc" href="https://wa.me/491604004348"><img src="@/assets/socials/whatsapplogo.svg"
                alt=""></a>
          </div>
          <p>
            All Rights Reserverd ® www.Baudiscount.de Copyright 2004 - {{ currentYear }}
          </p>
        </div>
      </div>
    </aside>
  </transition>
</template>

<style lang="scss" scoped>

@import "@/css/variables.scss";

@keyframes scaling {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(100%);
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $bbMagenta;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 111;
  bottom: 0;
  left: 0;
  $this: &;
  z-index: 200;

  >.aside_content_wrapper {
    width: 100%;
    height: fit-content;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 80px;

    .sidebar__links-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4rem;
      text-align: left;

      @media screen and (max-width: 768px) {
        align-items: flex-start;
        width: 90%;
        margin: 0 auto;
        gap: 2rem;
      }

      @media screen and (max-width: 350px) {
        gap: 1.5rem;
      }

      .sidebar__link {

        font-size: 2rem;
        font-weight: 500;
        display: block;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        text-decoration: none;

        @media screen and (max-width: 375px) {
          font-size: 1.75rem;
        }

        @media screen and (max-width: 350px) {
          font-size: 1.5rem;
        }

        &.router-link-active {
          position: relative;
          color: #FFF;

          .sidebar__line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            background: #FFF;
            transform-origin: left;
            width: 70%;

          }
        }
      }

      .link__overflow {
        display: block;
        overflow: hidden;
      }
    }
  }
}

.sidebar__footer-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding-left: 2rem;
  bottom: 0;
  height: 60px;
  color: white;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 768px) {
    align-items: flex-start;
    left: 0;
    transform: unset;
  }

  >p {
    font-size: 0.8rem;

    @media screen and (max-width: 768px) {
      font-size: 0.6rem;
      align-items: flex-start;
    }
  }
}

.socialmedia__links-wrapper {
  display: flex;
  gap: 2rem;
}

//sidebar transition

.sidebar-enter-from {
  transform: translateY(100%);
}

.sidebar-enter-active {
  transition-delay: 0.3s;
  transition: 0.4s cubic-bezier(0.62, 0.05, 0.01, 0.99);
}

.sidebar-enter-to {
  transform: translateY(0);
}

.sidebar-leave-from {
  opacity: 1;
}

.sidebar-leave-active {
  transition: 0.4s cubic-bezier(0.62, 0.05, 0.01, 0.99);
}

.sidebar-leave-to {
  opacity: 0;
}

//links swipe transition

.swipe-enter-from,
.swipe-leave-to {
  transform: translateY(100%);
}

.swipe-enter-active {
  transition: 0.4s;
  transition-delay: 0.3s;
}
.swipe-leave-active {
  transition: 0.6s;
}

.swipe-enter-to,
.swipe-leave-from {
  transform: translateY(0);
}
</style>
