import { createApp } from 'vue'
import App from './App.vue'
import Trengo from './components/Trengo.vue'
import router from './router'
import Cookies from './components/Cookies.vue'

createApp(App).use(router).mount('#app')
//TRENGO
createApp(Trengo).use(router).mount('#icons')
//COOKIES 
createApp(Cookies).use(router).mount('#cookies')
