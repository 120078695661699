export default [
     [
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/working-teams-of-7-persons.webp"),
               index: 0,
               classes: ["big"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/presentation-profile.webp"),
               index: 1,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/teams-check-profile.webp"),
               index: 2,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/working-teams-of-4-persons.webp"),
               index: 3,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/consultant-talks-to-family.webp"),
               index: 4,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/employee-feasts.webp"),
               index: 5,
               classes: ["big"],
          },
     ],
     [
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/talking-fachberater.webp"),
               index: 6,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/profile-description.webp"),
               index: 7,
               classes: ["big"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/discussion.webp"),
               index: 8,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/presentation-quality.webp"),
               index: 9,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/product-casting.webp"),
               index: 10,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/teams-conferences.webp"),
               index: 11,
               classes: ["big"],
          },
     ],
     [
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_17.webp"),
               index: 12,
               classes: ["big"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_15.webp"),
               index: 13,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_16.webp"),
               index: 14,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_21.webp"),
               index: 15,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/profile-design.webp"),
               index: 16,
               classes: ["big"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_18.webp"),
               index: 17,
          },
     ],
     [
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_20.webp"),
               index: 18,
               classes: ["img-80"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_19.webp"),
               index: 19,
               classes: ["big"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_22.webp"),
               index: 20,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_23.webp"),
               index: 21,
               classes: ["big"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_24.webp"),
               index: 22,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_25.webp"),
               index: 23,
          },
     ],
     [
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/learn-new-product.webp"),
               index: 24,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/drink-coffee.webp"),
               index: 25,
               classes: ["big"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/testing-profile.webp"),
               index: 26,
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/friendly-service.webp"),
               index: 27,
               classes: ["big"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_33.webp"),
               index: 28,
               classes: ["img-80"],
          },
          {
               imageSrc: require("@/assets/gridGallery/aboutImages/uberuns_28.webp"),
               index: 29,
               classes: ["img-20"],
          },
     ],
];
