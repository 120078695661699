<template>
     <transition name="slide">
          <span v-if="loading" class="loading-bar">
               <span>{{ text }}</span>
          </span>
     </transition>
</template>

<script>
export default {
     props: {
          loading: Boolean,
          text: String,
     },
};
</script>

<style scoped lang="scss">
.loading-bar {
    position: absolute;
    width: 100%;
    bottom: -6rem;
    left: 0;
    font-weight: 600;
    padding: 1rem 5rem;
    color: white;
    border-radius: 60px;
    text-align: center;
    background-color: #a11e62;
    z-index: 30;
}

.slide-enter-active {
     transition: all 0.3s ease-out;
}

.slide-leave-active {
     transition: all 0.2s cubic-bezier(0.5, 0.2, 0.2, 1);
}

.slide-enter-from,
.slide-leave-to {
     transform: translateY(20px);
     opacity: 0;
}
</style>