<script setup>
import Navigation from "./components/Navigation/Navigation.vue";
import FooterComponent from "./components/FooterComponent.vue";

import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import Alert from "./components/Alert/Alert.vue";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const route = useRoute()

const dark = ref(route.name !== "Startseite");
const sticky = ref(route.name !== "Startseite")
const blueBg = ref(route.name === "Newsletter")

watch(route, (newRoute) => {

     const canonicalLink = document.getElementById("canonical")
     canonicalLink.setAttribute("href", `https://www.baudiscount.de${newRoute.path}`)

     if (newRoute.name === "Startseite") {
          dark.value = false;
          sticky.value = false;
     } else {
          dark.value = true;
          sticky.value = true;
     }

     // if(newRoute.name === 'B2B') hideBars.value = true
     // else hideBars.value = false

     if(newRoute.name === 'Newsletter') blueBg.value = true 
     else blueBg.value = false
})

</script>

<template>
     <Navigation :dark="dark" :sticky="sticky"/>
     <router-view />
     <FooterComponent :dark="dark" :fixed="!sticky" :blueBg = "blueBg"/>
     <Alert/>
</template>

<style lang="scss">
@import "./css/config/config";

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-LightItalic.ttf");
     font-style: italic;
     font-weight: 300;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-BoldItalic.ttf");
     font-weight: 700;
     font-style: italic;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-BlackItalic.ttf");
     font-weight: 900;
     font-style: italic;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-Italic.ttf");
     font-style: italic;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-MediumItalic.ttf");
     font-style: italic;
     font-weight: 500;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-Black.ttf");
     font-weight: 900;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-Bold.ttf");
     font-weight: 700;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-Light.ttf");
     font-weight: 300;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-Medium.ttf");
     font-weight: 500;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-Regular.ttf");
     font-weight: 400;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-Thin.ttf");
     font-weight: 100;
}

@font-face {
     font-family: Poppins;
     src: url("@/css/fonts/Poppins/Poppins-ThinItalic.ttf");
     font-weight: 100;
     font-style: italic;
}

body {
     padding: 0;
     margin: 0;
     width: 100%;
     min-height: 100vh;
     overflow-y: auto;
     overflow-x: hidden;
}

* {
     font-family: "Poppins", sans-serif;
     box-sizing: border-box;
}

::-webkit-scrollbar {
     width: 7.5px;
}

::-webkit-scrollbar-thumb {
     background-color: #cfd8dc;
}

::-webkit-scrollbar-track {
     background-color: transparent;
     border-radius: 20px;
     background: rgba(255, 255, 255, 0.1);
     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
     backdrop-filter: blur(3.4px);
     -webkit-backdrop-filter: blur(3.4px);
}

#app {
     display: flex;
     flex-direction: column;
     min-height: 100vh;
}

#content {
     @media screen and (max-width: 768px) {
          overflow: auto;
     }
}

.mobile {
     display: none !important;

     @media screen and (max-width: 768px) {
          display: inline-flex !important;
     }
}

.desktop {
     display: inline-flex !important;

     @media screen and (max-width: 768px) {
          display: none !important;
     }
}

.dark {
     color: black !important;
     fill: black !important;

     >* {
          color: black !important;
     }
}

.arrow-up {
     display: block;
     width: 60px;
     height: 60px;
     border-radius: 50%;
     background: rgba(45, 55, 144, 0.5);
     position: fixed;
     z-index: 9999;
     left: 1rem;
     top: calc(50% - 30px);
     display: table-cell;
     display: flex;
     align-items: center;
     justify-content: center;

     >img {
          height: 50%;
     }
}

.arrow-down {
     display: block;
     width: 60px;
     height: 60px;
     border-radius: 50%;
     background: rgba(45, 55, 144, 0.5);
     position: fixed;
     z-index: 9999;
     right: 1rem;
     top: calc(50% - 30px);
     display: table-cell;
     display: flex;
     align-items: center;
     justify-content: center;

     >img {
          height: 50%;
     }
}

html {
     font-size: 100%;
}

@media screen and (max-width: 1440px) {
     html {
          font-size: 83.333333%;
     }
}

@media screen and (max-width: 1200px) {
     html {
          font-size: 61.111111%;
     }
}

@media screen and (max-width: 1024px) {
     html {
          font-size: 53.33333333%;
     }
}

@media screen and (max-width: 768px) {
     html {
          font-size: 70%;
     }
}

[data-tooltip] {
    position: relative;
    width: fit-content;
    height: fit-content;

    &:hover .tooltip_data {
        visibility: visible;
        opacity: 1;
    }

    .tooltip_data {
        position: absolute;
        width: fit-content;
        height: fit-content;
        opacity: 0;
        visibility: hidden;
        text-wrap: nowrap;
        white-space: nowrap;
        pointer-events: none;
        text-align: center;
        font-weight: 400;
        z-index: 2;
        box-shadow: 0 10px 20px #0000001a;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

        &.wrapping {
            text-wrap: wrap;
            white-space: pre-wrap;
            width: max-content;
            max-width: 12.5rem;
        }

        &.wrapping-lg {
            text-wrap: wrap;
            white-space: pre-wrap;
            width: max-content;
            max-width: 15rem;
        }

        &.wrapping-xl {
            text-wrap: wrap;
            white-space: pre-wrap;
            width: max-content;
            max-width: 20rem;
        }

        $tooltipOffset: 0.375rem;

        &.dark {
            background-color: $primary;
            border: 1px solid $primary;
            color: #FFF !important;
        }

        &.light {
            background-color: #fff;
            border: 1px solid $gray_100;
            color: $gray_900;
        }

        &.text-norm {
            font-size: inherit;
            padding: 0.5rem 1rem;
        }

        &.text-medium {
            font-size: 0.875rem;
            padding: 0.5rem 0.75rem;
        }

        &.text-small {
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
        }

        &.top {
            bottom: calc(100% + $tooltipOffset);
            left: 50%;
            transform: translateX(-50%);
        }

        &.top-right {
            bottom: calc(100% + $tooltipOffset);
            left: calc(100% + $tooltipOffset);
        }

        &.right {
            left: calc(100% + $tooltipOffset);
            top: 50%;
            transform: translateY(-50%);
        }

        &.bottom-right {
            top: calc(100% + $tooltipOffset);
            left: calc(100% + $tooltipOffset);
        }

        &.bottom {
            top: calc(100% + $tooltipOffset);
            left: 50%;
            transform: translateX(-50%);
        }

        &.bottom-left {
            top: calc(100% + $tooltipOffset);
            right: calc(100% + $tooltipOffset);
        }

        &.left {
            top: 50%;
            transform: translateY(-50%);
            right: calc(100% + $tooltipOffset);
        }

        &.top-left {
            bottom: calc(100% + $tooltipOffset);
            right: calc(100% + $tooltipOffset);
        }
    }
}

#trengo-web-widget {

     .TrengoWidgetLauncher__iframe {
     display: none !important;
     }

     .trengo-shadow {
     display: none !important;
     }

     .TrengoWidgetPanel__iframe {
     right: 7rem !important;
     bottom: 1.5rem !important;
     }
}

//SWIPING UP OR DOWN
.swipe_wrapper {
     display: block;
     overflow: hidden;
}

.swipe-enter-from {
     transform: translateY(-150px);
}

.swipeup-enter-from {
     transform: translateY(150px);
}

.swipe-enter-active,
.swipeup-enter-active {
     transition: 0.6s;
}

.swipe-enter-to,
.swipeup-enter-to {
     transform: translateY(0);
}

.swipe-leave-from,
.swipeup-leave-from {
     transform: translateY(0);
}

.swipe-leave-active,
.swipeup-leave-active {
     transition: 0.4s;
}

.swipe-leave-to {
     transform: translateY(150px);
}

.swipeup-leave-to {
     transform: translateY(-150px);
}

//FADE
.fadeout_wrapper {
     display: block;
     overflow-y: hidden;
}

.fadeout-enter-from {
     opacity: 0;
}

.fadeout-enter-active {
     transition: 0.3s;
}

.fadeout-enter-to {
     opacity: 1;
}

.fadeout-leave-from {
     opacity: 1;
}

.fadeout-leave-active {
     transition: 0.15s;
}

.fadeout-leave-to {
     opacity: 0;
}

//SCALING
.scaling-enter-from {
     transform: scaleX(0);
}

.scaling-enter-active {
     transition: 0.3s cubic-bezier(0.62, 0.05, 0.01, 0.99);
}

.scaling-enter-to {
     transform: scaleX(1);
}

.scaling-leave-from {
     transform: scaleX(1);
}

.scaling-leave-active {
     transition: 0.3s;
}

.scaling-leave-to {
     transform: scaleX(0);
}

//SCALING
.scale-enter-from {
     transform: scale(0);
}

.scale-enter-active {
     transition: 0.3s cubic-bezier(0.62, 0.05, 0.01, 0.99);
}

.scale-enter-to {
     transform: scale(1);
}

.scale-leave-from {
     transform: scale(1);
}

.scale-leave-active {
     transition: 0.3s;
}

.scale-leave-to {
     transform: scale(0);
}</style>