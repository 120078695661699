<script setup>
import CloseModalIcon from '@/components/svg/CloseModalIcon.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { watch, nextTick, ref } from 'vue';

const props = defineProps({
    tuvOpened: {
        type: Boolean,
        required: true
    }
})

const emit = defineEmits(['closeModal'])

watch(() => props.tuvOpened, (newValue)=> {
    if(newValue) {
        nextTick(() => {
        document.querySelector('.tuv-modal iframe').setAttribute("sandbox",'allow-forms allow-pointer-lock allow-popups allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-top-navigation-to-custom-protocols ');
    })
    }
})

const iframeLoaded = ref(false)

</script>

<template>
        <transition name="view">
            <div v-if="props.tuvOpened" class="tuv-modal-wrapper" @click="emit('closeModal')">
                <div class="tuv-modal" @click.stop>
                    <CloseModalIcon class="close-icon" color="white" @click="emit('closeModal')"/>
                    <div class="tuv-loader-container" v-if="!iframeLoaded">
                        <LoadingSpinner/>
                    </div>
                    <iframe src="https://www.tuev-saar.de/zertifikat/sc44531/" frameborder="0" @load="iframeLoaded = true"></iframe>
                </div>
            </div>
        </transition>
</template>

<style lang="scss" scoped>

    .view-enter-active,
    .view-leave-active {
        transition: all 0.3s ease-in-out;
        opacity: 1;
    }

    .view-enter-from,
    .view-leave-to {
        opacity: 0;
    }
    
    .tuv-modal-wrapper {
        position: fixed;
        background-color: rgba($color: #000000, $alpha: 0.5);
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 999999999999999 !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .tuv-modal {
            max-height: 90vh;
            height: 70%;
            width: 75%;
            max-width: 1600px;
            max-height: 1000px;
            position: relative;

            @media screen and (max-width: 1024px) {
                width: 85%;
                height: 80%;
            }
            @media screen and (max-width: 576px) {
                width: 90%;
            }

            @media screen and (max-width: 425px) {
                width: 95%;
            }

            .close-icon {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(140%, -140%);
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    transform: translate(40%, -140%);
                    width: 2rem;
                    height: 2rem;
                }

                @media screen and (max-width: 425px) {
                    transform: translate(0, -140%);
                }
            }

            .tuv-loader-container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%)
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>