<script setup>
import { ref, reactive } from 'vue';
import TelefonischeBeratung from '../svg/callModal/TelefonischeBeratung.vue';
import ClockIcon from '../svg/callModal/ClockIcon.vue';
import TelefonSelect from '../TelefonSelect.vue';
import axios from 'axios';
import useValidator from '@/utils/useValidator';

const freshmailApiUrl = "/api/add_to_newsletter"
const newsletterContent = ref(null)

const props = defineProps({
    state: {
        type: Object,
        required: true
    },
    selectedDayText: {
        type: String,
        required: true
    }
})

const emit = defineEmits(['showAlert'])

const formData = reactive({
    name: {
        value: "",
        defaultValue: "",
        required: true,
        isValid: true,
    },
    vorname: {
        value: "",
        defaultValue: "",
        required: true,
        isValid: true,
    },
    email: {
        value: "",
        defaultValue: "",
        required: true,
        regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        isValid: true,
    },
    countryCode: {
        value: "+49",
        required: true,
        isValid: true,
    },
    tel: {
        value: "",
        defaultValue: "",
        required: true,
        regex: /^(?=.*\d)[\d ]+$/,
        minLength: 5,
        maxLength: 20,
        isValid: true,
    },
    message: {
        value: "",
        defaultValue: "",
        required: false,
        isValid: true,
    },
    policy: {
        value: false,
        defaultValue: false,
        required: true,
        isValid: true,
        dontSend: true
    },
    newsletter: {
        value: false,
        defaultValue: false,
        required: false,
        isValid: true,
        dontSend: true
    },
})

const setCountryCode = (code) => {
    formData.countryCode.value = code
}

const { validateInput, validateAll } = useValidator()

const submitForm = async () => {

    const errorElement = document.querySelector('.invalid')
    if (errorElement) errorElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })

    if (validateAll(formData) && !props.state.processingForm) {
        props.state.processingForm = true
        const formValues = new FormData()
        const url = "/api/fachberatung"

        formValues.append("selectedDate", props.state.selectedDate)
        formValues.append("selectedHour", props.state.selectedHour)
        formValues.append("callType", "telefon")

        const keys = Object.keys(formData)

        keys.forEach((el) => {
            if (!formData[el].dontSend && formData[el].value !== '') formValues.append(el, formData[el].value)
        })

        if (newsletterContent.value && formData.newsletter.value) {
            formValues.append('newsletterContent', newsletterContent.value.textContent)
            const data = new FormData();
            data.append("email", formData.email.value);
            data.append("name", formData.name.value);
            data.append("phone", formData.countryCode.value + formData.tel.value);
            try {
                await axios.post(freshmailApiUrl, data);
            } catch(e) {
                
            }
        }

        window.dataLayer.push({ 'form_sent_success': true });
        try {
            await axios.post(url, formValues, { headers: { "Content-Type": "text/plain" } })
            emit('showAlert', false)
        } catch (e) {
            emit('showAlert', true)
        }
    }
}

</script>

<template>
    <div class="card-content" >
        <div class="form-section">
            <div class="title-wrapper">
                <p class="form-title">Telefonberatung</p>
            </div>
            <div class="summary-wrapper">
                <div class="info-section">
                    <div class="info-item">
                        <ClockIcon />
                        <span>30 min</span>
                    </div>
                    <div class="info-item">
                        <TelefonischeBeratung />
                        <span>Wir&nbsp;rufen&nbsp;Sie&nbsp;an</span>
                    </div>
                    <div class="info-item">
                        <ClockIcon />
                        <span>{{ props.state.selectedHour }}, {{ props.selectedDayText }}</span>
                    </div>
                    <div class="info-item">
                        <TelefonischeBeratung />
                        <span>Mitteleuropäische Zeit (UTC+1:00)</span>
                    </div>
                </div>
            </div>
            <div class="form-wrapper">
                <form @submit.prevent="submitForm" id="callCardForm">
                    <div class="box-vorname-name">
                        <div class="input-wrapper">
                            <label for="name">Name *</label>
                            <input type="text" name="Name" id="name" :class="{ invalid: !formData.name.isValid }"
                                v-model="formData.name.value" @blur="validateInput(formData.name)">
                        </div>
                        <div class="input-wrapper">
                            <label for="vorname">Vorname *</label>
                            <input type="text" name="Vorname" id="vorname" :class="{ invalid: !formData.vorname.isValid }"
                                v-model="formData.vorname.value" @blur="validateInput(formData.vorname)">
                        </div>
                    </div>
                    <div class="box-vorname-name">
                        <div class="input-wrapper">
                            <label for="email">E-Mail-Adresse *</label>
                            <input type="email" name="email" id="email" :class="{ invalid: !formData.email.isValid }"
                                v-model="formData.email.value" @blur="validateInput(formData.email)">
                        </div>
                        <div class="input-wrapper">
                            <label for="tel">Telefonnummer **</label>
                            <div class="tel-input-wrapper" :class="{ invalid: !formData.tel.isValid }">
                                <TelefonSelect @onCountryChange="setCountryCode" color="green" />
                                <input type="text" id="tel" name="number" v-model="formData.tel.value"
                                    @blur="validateInput(formData.tel)">
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <label for="message">Ihre Nachricht</label>
                        <textarea id="message" v-model="formData.message.value"></textarea>
                    </div>
                </form>
            </div>

            <div class="submit-section">
                <div class="data-protection-wrapper">
                    <p class="section-title">Datenschutz</p>
                    <div class="checkbox-wrapper">
                        <div class="custom-checkbox-container">
                            <input type="checkbox" id="policy" v-model="formData.policy.value"
                                :class="{ invalid: !formData.policy.isValid }" @change="validateInput(formData.policy)">
                            <div class="custom-checkbox"></div>
                        </div>
                        <label for="policy">
                            Ich habe die <a target="_blank" href="/impressum">Datenschutzerklärung</a>
                            zur Kenntnis genommen. Ich stimme zu, dass meine Angaben zur Kontaktaufnahme,
                            Angebotserstellung, Auftragsabwicklung und für Rückfragen dauerhaft gespeichert
                            werden.*
                        </label>
                    </div>
                    <div class="checkbox-wrapper mt-2">
                        <div class="custom-checkbox-container">
                            <input type="checkbox" id="newsletter" v-model="formData.newsletter.value"
                                :class="{ invalid: !formData.newsletter.isValid }">
                            <div class="custom-checkbox"></div>
                        </div>
                        <label for="newsletter" ref="newsletterContent">
                            Neuste Produktinformationen, Rabattcodes und aktuelle Rabattaktionen so wie
                            Newsletter
                            per E-Mail erhalten.
                        </label>
                    </div>
                    <div>
                        <p class="form-label-info">
                            Die mit einem * gekennzeichneten Felder sind Pflichtfelder
                        </p>
                        <p class="form-label-info">
                            ** Unsere Fachberater benötigen Ihre Telefonnummer für Rückfragen zu Ihrem Projekt.
                            Es ist oftmals einfacher und vor allem schneller, eine Rückfrage telefonisch zu
                            stellen,
                            als per E-Mail zu formulieren.
                        </p>
                    </div>
                </div>
                <button class="form-btn" :disabled="false" type="submit" form="callCardForm">Termin
                    buchen</button>
            </div>

        </div>
    </div>
</template>

<style lang="scss">
@import "@/css/variables";

.form-section {
    width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (max-width: 425px) {
        grid-template-columns: 100%;
        padding-bottom: 1.25rem;
    }

    .title-wrapper {
        padding: 0 2rem;

        @media screen and (max-width: 576px) {
            padding-inline: 1.25rem;
        }

        @media screen and (max-width: 425px) {
            grid-column: 1/2;
            padding-inline: 1rem;
        }

        .form-title {
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.5;
            color: $gray_900;
            margin-bottom: 0.5rem;
        }
    }

    .form-wrapper {
        padding-left: 2rem;

        @media screen and (max-width: 576px) {
            padding-left: 1.25rem;
        }

        @media screen and (max-width: 425px) {
            padding-left: 0;
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 0.626rem;
            padding: 0 2rem 1.25rem 0;

            @media screen and (max-width: 576px) {
                padding-right: 1.25rem;
            }

            @media screen and (max-width: 425px) {
                border: none;
                padding-inline: 1rem;
            }

            .input-wrapper {
                display: flex;
                flex-direction: column;
                column-gap: 0.25rem;

                label {
                    font-weight: 300;
                    font-size: 0.875rem;
                    line-height: 1.5;

                    color: $gray_900;
                }

                input,
                textarea {
                    border: 1px solid $gray_200;
                    padding: 0.35rem;
                    outline: 1px solid transparent;
                    font-size: 0.875rem;

                    &:focus {
                        border-color: $bbGreen;
                        outline-color: $bbGreen;
                    }

                    &.invalid {
                        border-color: red;

                        &:focus {
                            outline-color: red !important;
                        }
                    }
                }

                textarea {
                    resize: none;
                    min-height: 25px;
                    flex-grow: 1;

                    @media screen and (max-width: 576px) {
                        min-height: 75px;
                    }
                }

                .tel-input-wrapper {
                    display: flex;
                    width: 100%;
                    border: 1px solid $gray_200;

                    &.invalid {
                        border-color: red;

                        &:focus-within {
                            border-color: red;
                            outline-color: red !important;
                        }
                    }

                    &:focus-within {
                        border-color: $bbGreen;
                        outline-style: solid !important;
                        outline-width: thin !important;
                        outline-color: $bbGreen !important;
                    }

                    input {
                        border: none;
                        width: 100%;

                        &:focus {
                            outline: none !important;
                        }
                    }

                }
            }
        }

        @media screen and (min-width: 576px) {
            .box-vorname-name {
                display: flex;
                gap: 1rem;

                .form-label {
                    width: 50%;
                }

                .input-wrapper {
                    width: 50%;
                }
            }
        }
    }

    .summary-wrapper {
        padding: 0 2rem 1.75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 576px) {
            padding-inline: 1.25rem;
        }

        @media screen and (max-width: 425px) {
            grid-row: 2/3;
            padding-inline: 1rem;
        }

        .info-section {
            width: 100% !important;
            max-width: unset !important;

            .info-item {
                width: inherit !important;
                margin-bottom: 0.375rem !important;
            }
        }
    }

    .submit-section {
        padding: 0 2rem;

        @media screen and (max-width: 576px) {
            padding-inline: 1.25rem;
        }

        @media screen and (max-width: 425px) {
            padding-inline: 1rem;
        }

        .data-protection-wrapper {
            margin-bottom: 1rem;

            .section-title {
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.5;
                margin-bottom: 0.5rem;
            }

            .checkbox-wrapper {
                display: flex;
                align-items: flex-start;
                column-gap: 0.5rem;

                .custom-checkbox-container {
                    position: relative;
                    height: 1rem;

                    @media screen and (max-width: 425px) {
                        height: 1.25rem;
                    }

                    input[type="checkbox"] {
                        border: 1px solid $gray_100;
                        border-radius: 2px;
                        cursor: pointer;
                        opacity: 0;
                        height: 100%;
                        aspect-ratio: 1;
                    }

                    .custom-checkbox {
                        height: 100%;
                        aspect-ratio: 1;
                        border: 1px solid $gray_100;
                        border-radius: 2px;

                        position: absolute;
                        top: 0;
                        left: 0;

                        pointer-events: none;

                        &::after {
                            content: "";
                            position: absolute;
                            height: 100%;
                            aspect-ratio: 1;
                            background-image: url("@/assets/check.svg");
                            background-size: contain;
                        }
                    }

                    input[type="checkbox"]:checked+.custom-checkbox {
                        background-color: $bbGreen;
                    }

                    input[type="checkbox"].invalid+.custom-checkbox {
                        border-color: red;
                    }
                }

                label {
                    font-weight: 300;
                    font-size: 0.625rem;
                    line-height: 1.6;
                    color: #455a64;

                    @media screen and (max-width: 425px) {
                        font-size: 0.75rem;
                    }

                    a {
                        text-decoration: underline;
                        color: #455a64;
                        font-weight: 400;
                    }
                }
            }
        }

        .form-label-info {
            font-size: 0.625rem;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }

        .form-btn {
            width: fit-content;
        }
    }
}
</style>