<script setup>
import LottieAnimatedIconCard from './LottieAnimatedIconCard.vue';

import { ref } from 'vue';

const lottieCardsData = ref([
    {
        iconJson: require("@/assets/uberuns/uber-uns-icon-json/lieferung-direkt-ab-werk.json"),
        cardText: "Werkfrische direkte Lieferung"
    },
    {
        iconJson: require("@/assets/uberuns/uber-uns-icon-json/hochste-produktqualitat.json"),
        cardText: "Erstklassige Qualität"
    },
    {
        iconJson: require("@/assets/uberuns/uber-uns-icon-json/professionelle-fachberatung.json"),
        cardText: "Profiberatung inklusive"
    },
    {
        iconJson: require("@/assets/uberuns/uber-uns-icon-json/hochzufriedene-kunden.json"),
        cardText: "Begeisterte Kunden"
    },
    {
        iconJson: require("@/assets/uberuns/uber-uns-icon-json/transparente-preise.json"),
        cardText: "Faire Preise"
    },
    {
        iconJson: require("@/assets/uberuns/uber-uns-icon-json/kostenlose-lieferung-inkl.json"),
        cardText: "Preiswerte Lieferung, zuverlässiges Handling"
    },
    {
        iconJson: require("@/assets/uberuns/uber-uns-icon-json/engagiertes-team.json"),
        cardText: "Motivierte Experten"
    },
    {
        iconJson: require("@/assets/uberuns/uber-uns-icon-json/innovative-it-lösungen.json"),
        cardText: "Revolutionäre IT-Tools  "
    },
])

</script>

<template>
    <div class="uberuns__content" style="margin-top: 1rem;">
    <div class="baudiscount-unique" id="uberuns">
        <h2 data-aos="fade-up" data-aos-delay="0" data-aos-duration="550">Diese Qualitäten machen Baudiscount zur Nr. 1</h2>
        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="550">
            Erleben Sie die einzigartigen Vorteile des ersten und einzigen digitalen Marktplatzes für Baustoffe innerhalb Europas. 
            Nutzen Sie die modernsten Konfigurationstools für die Zusammenstellung eines persönlichen Angebots. 
            Sie stehen im direkten Kontakt mit den Herstellern, damit Sie die Baumaterialien Ihren Wünschen entsprechend anpassen können. Professionelle, 
            sachkundige und erfahrene Handwerkerprofis stehen Ihnen auf Wunsch bei jedem Schritt zur Seite und unterstützen Sie bei Ihrer Bestellung. 
        </p>
        <ul>

            <LottieAnimatedIconCard 
                v-for="card in lottieCardsData" 
                :iconJson = "card.iconJson" 
                :cardText = "card.cardText"
            />
   
        </ul>
    </div>
</div>
</template>

<style lang="scss" scoped>
.baudiscount-unique {
    text-align: center;

    h2 {
        font-weight: 400;
        font-size: 2.875rem;
        line-height: 4.5rem;

        @media screen and (max-width: 576px) {
            font-size: 2.125rem; //28px
            line-height: 3rem;
        }

        span {
            font-weight: 700;
        }
    }

    p {
        font-size: 1.125rem;
        max-width: 980px;
        margin: 2rem auto 3.875rem;
        line-height: 2.375rem;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        position: relative;
        max-width: 1140px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        grid-auto-rows: 1fr 1fr;
        gap: 1.5rem;

        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(auto-fit, minmax(183px, 1fr));
        }

        @media screen and (max-width: 435px) {
            grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
            gap: 1rem;
            justify-content: center;
        }
    }
}
</style>