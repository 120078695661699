<script setup>
import ArrowBack from '../svg/callModal/ArrowBack.vue';
import TelefonischeBeratung from '../svg/callModal/TelefonischeBeratung.vue';

import { ref, reactive, watch, computed, onMounted } from 'vue'
import CloseModalIcon from '../svg/CloseModalIcon.vue';
import FormStep from './FormStep.vue';
import CalendarStep from './CalendarStep.vue';

const emit = defineEmits(['closeModal'])

const closeModal = () => emit("closeModal")

const tabsWrapper = ref(null)
const alertMessage = ref(null)


// reactive states
const state = reactive({
    processingForm: false,
    calendarInstance: null,
    selectedDate: null,
    selectedHour: null,
    activeStep: 1,
    alertShown: false,
    isError: false,
})


//computed values

const selectedDayText = computed(() => {
    let selectedDay = "Bitte ankreuzen"
    if (state.selectedDate) {
        const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" }

        const selectedDateObject = new Date(state.selectedDate)

        selectedDay = selectedDateObject.toLocaleDateString("de-DE", options)
    }

    return selectedDay
})


watch(() => state.activeStep, (newStep) => {
    if (newStep === 2 && window.innerWidth < 426) tabsWrapper.value.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
})


const showAlert = (isError) => {
    alertMessage.value.textContent = isError ? "Fehler aufgetreten, versuchen Sie es erneut." : "Ihr Beratungstermin ist bestätigt"
    if (isError) state.isError = true;
    state.alertShown = true;
    alertMessage.value.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

    setTimeout(() => {
        state.alertShown = false;

        setTimeout(() => {
            if (!state.isError) {
                emit('closeModal')
            }
            state.isError = false
            state.processingForm = false
        }, 400)
    }, 4000)
}

</script>

<template>
    <div class="call-modal-wrapper" @click="closeModal">
        <div class="call-card-container">
            <CloseModalIcon color="white" class="close-icon" @click="closeModal" />
            <div class="call-card" @click.stop>
                <div class="status-alert" :class="{ shown: state.alertShown, error: state.isError }">
                    <span class="status-alert-message" ref="alertMessage"></span>
                </div>

                <div class="tabs-wrapper" ref="tabsWrapper">
                    <div class="back-icon" :class="{ disabled: state.activeStep === 1 }" @click="state.activeStep = 1">
                        <ArrowBack />
                    </div>
                    <div class="tab active">
                        <TelefonischeBeratung />
                        <span>Telefonberatung</span>
                    </div>
                </div>

                <CalendarStep v-show="state.activeStep == 1" :selectedDayText="selectedDayText" :state="state" />
                <FormStep v-show="state.activeStep == 2" @showAlert="showAlert" :selectedDayText="selectedDayText" :state="state" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" >
@import "@/css/variables.scss";

.call-modal-wrapper {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.5);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999999999999999 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .call-card-container {
        position: relative;
        width: fit-content;
        height: fit-content;
        max-width: 860px;
        max-height: 85vh;

        @media screen and (max-width: 768px) {
            max-width: 95%;
        }

        @media screen and (max-width: 425px) {
            width: 95%;
            max-height: 70vh;
        }

        .close-icon {
            position: absolute;
            width: 2rem;
            height: 2rem;
            top: 0;
            right: 0;
            transform: translate(140%, -140%);
            cursor: pointer;

            @media screen and (max-width: 768px) {
                transform: translate(0, -140%);
            }
        }
    }

    .call-card {
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 1.75rem 0;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        max-width: 860px;
        max-height: 85vh;


        p {
            margin-top: 0;
        }

        svg {
            vertical-align: middle;
        }

        &::-webkit-scrollbar {
            width: 7.5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray_100;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 20px;
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(3.4px);
            -webkit-backdrop-filter: blur(3.4px);
        }

        @media screen and (max-width: 768px) {
            margin-top: 0;
        }

        @media screen and (max-width: 425px) {
            padding-top: 2.75rem;
            border-radius: 0;
            max-height: 70vh;
        }

        .card-content .main-section {
            @media screen and (max-width: 425px) {
                padding-bottom: 1.25rem;
            }
        }

        .status-alert {
            position: absolute;
            width: fit-content;
            max-width: 80%;
            padding: 1rem 2rem;
            background-color: $bbGreen;
            top: 1rem;
            left: 50%;
            transform: translate(-50%, -170%);
            z-index: 1;
            color: #FFF;
            transition: transform .4s ease-in-out;
            text-align: center;

            @media screen and (max-width: 768px) {
                max-width: 95%;
            }

            @media screen and (max-width: 425px) {
                width: 95%;
            }

            span {
                max-width: 100%;
                word-break: break-word;
            }

            &.error {
                background-color: #d64e4e;
            }

            &.shown {
                transform: translate(-50%, 0);
            }
        }

        .tabs-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            padding-inline: 2rem;
            position: relative;

            @media screen and (max-width: 576px) {
                padding-inline: 1.25rem;
            }

            @media screen and (max-width: 425px) {
                justify-content: center;
                column-gap: 1rem;
                padding-inline: 1rem;
            }

            .back-icon {
                position: absolute;
                top: 0;
                left: 2rem;
                height: calc(100% + 6px);
                aspect-ratio: 1;
                cursor: pointer;

                &.disabled {
                    opacity: 0;
                }

                @media screen and (max-width: 1200px) {
                    svg {
                        height: 24px;
                        width: 24px;
                    }
                }

                @media screen and (max-width: 576px) {
                    left: 1.25rem;

                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }

                @media screen and (max-width: 340px) {
                    left: 0.5rem;
                }
            }

            .tab {
                cursor: pointer;
                position: relative;
                padding: 0 0.25rem;

                svg {
                    margin-right: 0.5rem;

                    @media screen and (max-width: 1200px) {
                        height: 16px;
                    }

                    @media screen and (max-width: 1024px) {
                        height: 12px;
                        margin-right: 0.25rem;
                    }

                    @media screen and (max-width: 425px) {
                        margin-right: 0;
                    }

                    path {
                        transition: fill 0.2s ease-in-out;
                    }
                }

                span {
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    color: $gray_900;

                    transition: color 0.2s ease-in-out;
                }

                &.active::after {
                    content: "";
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    background-color: $bbGreen;

                    @media screen and (max-width: 1024px) {
                        bottom: -3px;
                    }
                }

                &.active,
                &:hover {
                    svg path {
                        fill: $bbGreen;
                    }

                    span {
                        color: $bbGreen;
                    }
                }
            }
        }

        .card-content {
            width: 100%;
            padding-top: 2.75rem;

            @media screen and (max-width: 1024px) {
                height: 100%;
            }

            @media screen and (max-width: 425px) {
                padding-top: 1.75rem;
            }


            .form-btn {
                padding: 0.5rem 2.5rem;
                cursor: pointer;

                font-weight: 700;
                font-size: 0.9375rem;
                line-height: 1.375rem;
                color: #fff;
                background-color: $bbGreen;
                border: none;
                border-radius: 0.5rem;
                transition: background-color 0.2s ease-in-out;

                &:hover {
                    background-color: #097c3d
                }

                &:disabled {
                    background-color: $gray_100;
                    cursor: default;
                }
            }

        }
    }

}
</style>
