<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const isMobile = ref(null)
const textShown = ref(false)
const video = ref(null)

let interval;

onMounted(() => {

  interval = setInterval(()=> {
    const time = video.value.currentTime
    if(time>4 && time < 8) textShown.value = true
    if(time>8) textShown.value = false;
  },1000)

  isMobile.value = window.innerWidth <= 576

})

onUnmounted(() => {
  clearInterval(interval);
})

</script>

<template>
  <main class="home__view-wrapper">
    <video ref="video" id="home__video" muted autoplay loop playsinline
      :poster="isMobile ? require('@/assets/homepage/b-de2023-mobile-poster.jpg') : require('@/assets/homepage/b-de2023-desktop-poster.jpg')">
      <source v-if="!isMobile" src="@/assets/homepage/b-de2023-desktop.mp4" />
      <source v-if="isMobile" src="@/assets/homepage/b-de2023-mobile.mp4" />
    </video>
    <article class="center__content-wrapper">
      <h1 class="homepage__title" :class="{shown: textShown}" ref="title">
        <span>Europas erster Marktplatz für Bauprodukte &
          Bauelemente nach&nbsp;Maß</span>
        <span>Starten Sie Ihr nächstes Projekt mit&nbsp;Baudiscount</span>

      </h1>
    </article>
  </main>
</template>


<style scoped lang="scss">
.desktopVisible {
  display: block !important;

  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.mobileVisible {
  display: none !important;

  @media screen and (max-width: 768px) {
    display: block !important;
  }
}

.home__view-wrapper {
  position: relative;
  height: 100vh;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.45);
  }

  #home__video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
  }

  .center__content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-width: 95%;
    display: flex;
    z-index: 10;
    flex-direction: column;
    align-items: center;

    @keyframes fadeup {
      0% {
        transform: translate3d(0, 100px, 0);
        opacity: 0;
      }
      100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    .homepage__title {
      z-index: 999;
      color: white;
      font-size: 2.5rem;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin: 0;
      width: 100%;
      opacity: 0;
      transform: translate3d(0, 100px, 0);
      transition: opacity 0.5s ease-in-out ;
      
      &.shown {
        animation-name: fadeup;
        animation-duration: 380ms;
        animation-timing-function: cubic-bezier(.47, 0, .745, .715);
        animation-delay: 70ms;
        animation-fill-mode: forwards
      };

      @media screen and (min-width: 2160px) {
        font-size: 3rem;
      }

      @media screen and (max-width: 768px) {
        font-size: 2.05rem;
      }

      @media screen and (max-width: 425px) {
        max-width: 95%;
        font-size: 1.75rem;
      }

      span {
        width: 100%;
      }

    }

  }
}
</style>
