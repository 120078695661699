<script setup>
import useValidator from '@/utils/useValidator';
import ContactInput from './ContactInput.vue';
import CustomCheckbox from './CustomCheckbox.vue';
import { reactive } from 'vue';
import axios from 'axios';
import store from '@/store';

const props = defineProps({
    form_name:{
        type: String,
    }
})


const formData = reactive({
    sex: {
        value: "Herr",
        defaultValue: "Herr",
        required: true,
        isValid: true
    },
    name: {
        value: "",
        defaultValue: "",
        required: true,
        isValid: true,
    },
    vorname: {
        value: "",
        defaultValue: "",
        required: true,
        isValid: true,
    },
    firma: {
        value: "",
        defaultValue: "",
        required: false,
        isValid: true,
    },
    email: {
        value: "",
        defaultValue: "",
        required: true,
        regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        isValid: true,
    },
    countryCode: {
        value: "+49",
        required: true,
        isValid: true,
    },
    tel: {
        value: "",
        defaultValue: "",
        required: true,
        regex: /^(?=.*\d)[\d ]+$/,
        minLength: 5,
        maxLength: 20,
        isValid: true,
    },
    message: {
        value: "",
        defaultValue: "",
        required: false,
        maxLength: 248,
        isValid: true,
    },
    policy: {
        value: false,
        defaultValue: false,
        required: true,
        isValid: true,
        dontSend: true
    },
})

const setSexValue = (val) => {
    formData.sex.value = val
    if (formData.sex.isValid === false) formData.sex.isValid = true
}

const setCountryCodeValue = (val) => {
    formData.countryCode.value = val
}

const { validateInput, validateAll, resetValues } = useValidator()

let isLoading = false

const onSubmit = async () => {
    if (!isLoading) {
        const isFormValid = validateAll(formData)

        if (isFormValid) {
            isLoading = true;
            const freshmailFormData = new FormData()
            const keys = Object.keys(formData)
            keys.forEach((key) => {
                if (!formData[key].dontSend) freshmailFormData.append(key, formData[key].value)
            })

            freshmailFormData.append('form_name', props.form_name)

            try {
                const response = await axios.post("/api/add_to_b2b_newsletter", freshmailFormData)
                const responseData = JSON.parse(response.data)

                if (responseData.errors && responseData.errors[0].code === 1304) {
                    //Ten mail jest już zapisany
                    store.alert.showAlert('Ihre Daten sind schon in unserer Datenbank', 5)
                }

                if (!responseData.errors) {
                    //Pomyślnie zapisano
                    store.alert.showAlert('Vielen Dank für Ihre Nachricht, wir bearbeiten diese schnellstmöglich, und melden uns bei Ihnen persönlich.')
                    resetValues(formData)
                }

            } catch (error) {
                store.alert.showAlert('Etwas scheint schief gelaufen zu sein, versuchen Sie es bitte später noch einmal', 5)

            } finally {
                isLoading = false
            }
        }

    }
}

</script>

<template>
    <form id="b2b-form" @submit.prevent="onSubmit">
        <div class="sex-wrapper">
            <span>Anrede *</span>
            <CustomCheckbox :label="'Herr'" name="'Sex'" :id="'herr'" :checked="formData.sex.value === 'Herr'"
                @check="setSexValue" :isError="!formData.sex.isValid" />
            <CustomCheckbox :label="'Frau'" name="'Sex'" :id="'frau'" :checked="formData.sex.value === 'Frau'"
                @check="setSexValue" :isError="!formData.sex.isValid" />
            <CustomCheckbox :label="'Firma'" name="'Sex'" :id="'firma'" :checked="formData.sex.value === 'Firma'"
                @check="setSexValue" :isError="!formData.sex.isValid" />
        </div>

        <div class="input-row">
            <ContactInput v-model="formData.vorname.value" :label="'Vorname *'" name="'Vorname'" :id="'vorname'"
                :isError="!formData.vorname.isValid" @blur="validateInput(formData.vorname)" />
            <ContactInput v-model="formData.name.value" :label="'Name *'" name="'Name'" :id="'name'"
                :isError="!formData.name.isValid" @blur="validateInput(formData.name)" />
        </div>
        <ContactInput v-model="formData.firma.value" :label="'Firma'" name="'Firma'" :id="'firmenname'"
            :isError="!formData.firma.isValid" @blur="validateInput(formData.firma)" />
        <ContactInput v-model="formData.tel.value" @onCountryCodeChange="setCountryCodeValue" :type="'telefon'"
            :label="'Ihre Telefonnummer **'" name="'Telefonnummer'" :id="'telefonnummer'" :isError="!formData.tel.isValid"
            @blur="validateInput(formData.tel)" />
        <ContactInput v-model="formData.email.value" :type="'email'" :label="'Ihre Email *'" name="'Email'" :id="'email'"
            :isError="!formData.email.isValid" @blur="validateInput(formData.email)" />
        <ContactInput v-model="formData.message.value" :type="'textarea'" :label="'Ihre Nachricht'" name="'Nachricht'"
            :id="'nachricht'" :isError="!formData.message.isValid" @blur="validateInput(formData.message)" />
        <div class="policy-wrapper">
            <CustomCheckbox name="'Policy'" :id="'policy'" :checked="formData.policy.value" type="checkbox"
                @check="formData.policy.value = !formData.policy.value" :isError="!formData.policy.isValid" />
            <label for="policy">
                Ich habe die <a target="_blank" href="/impressum">Datenschutzerklärung</a>
                zur Kenntnis genommen. Ich stimme zu, dass meine Angaben zur Kontaktaufnahme,
                Angebotserstellung, Auftragsabwicklung und für Rückfragen dauerhaft gespeichert
                werden.*
            </label>
        </div>
        <div class="info-container">
            <p class="mb">
                Die mit einem * gekennzeichneten Felder sind Pflichtfelder
            </p>
            <p>
                ** Unsere Fachberater benötigen Ihre Telefonnummer für Rückfragen zu Ihrem Projekt.
                Es ist oftmals einfacher und vor allem schneller, eine Rückfrage telefonisch zu
                stellen,
                als per E-Mail zu formulieren.
            </p>
        </div>
    </form>
</template>

<style lang="scss" scoped>
form {

    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .sex-wrapper {
        display: flex;
        align-items: center;
        column-gap: 2rem;

        span {
            color: #263238;
            font-size: 0.75rem;
            line-height: 1.5;
            font-weight: 300;
        }
    }

    .input-row {
        width: 100%;
        display: flex;
        column-gap: 1.25rem;

        @media screen and (max-width: 425px) {
            row-gap: 0.675rem;
            flex-direction: column;
        }
    }

    .policy-wrapper {
        display: flex;
        align-items: flex-start;
        column-gap: 0.375rem;

        label {
            font-weight: 400;
            font-size: 0.625rem;
            line-height: 1.6;
            color: #37474F;

            @media screen and (max-width: 425px) {
                font-size: 0.75rem;
            }

            a {
                text-decoration: underline;
                color: #37474F;
                font-weight: 400;
            }
        }
    }

    .info-container p {
        font-weight: 300;
        font-size: 0.625rem;
        margin-top: 0;
        margin-bottom: 0;
        color: #95a1a8;

        &.mb {
            margin-bottom: 0.25rem;
        }
    }
}
</style>