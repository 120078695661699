<template>
    <meta name="robots" content="noindex follow">
    <div class="impressum__content">
        <h3 class="stitle">Impressum</h3>

        <!--Impressum -->
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>Baustoff-Euro-Trade GmbH<br>
            Bahnhofstrasse 22<br>
            15806 Zossen</p>

        <p>Handelsregister: HRB26656<br>
            Registergericht: Amtsgericht Potsdam</p>

        <p><strong>Vertreten durch:</strong><br>
            Paul Filipowicz</p>

        <h2>Kontakt</h2>
        <p>Telefon: 030 577 134 2110<br>
            Telefax: 030 577 134 2111<br>
            E-Mail: info@baustoffhandel-baudiscount.de</p>

        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br>
            DE287802780</p>

        <h2>EU-Streitschlichtung</h2>
        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                href="https://ec.europa.eu/consumers/odr" target="_blank"
                rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.<br> Unsere E-Mail-Adresse finden Sie
            oben im Impressum.</p>

        <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.</p>

        <h3>Haftung für Inhalte</h3>
        <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
            Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
            übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
            eine rechtswidrige Tätigkeit hinweisen.</p>
        <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
            konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.</p>
        <h3>Haftung für Links</h3>
        <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
            Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
            wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
            Zeitpunkt der Verlinkung nicht erkennbar.</p>
        <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.</p>
        <h3>Urheberrecht</h3>
        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
        <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
        <!--datenschutz-->
        <h1 id="datenschutz">Datenschutz­erklärung</h1>
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>
        <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
            identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
            diesem Text aufgeführten Datenschutzerklärung.</p>
        <h3>Datenerfassung auf dieser Website</h3>
        <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
        <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
            dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</p>
        <h4>Wie erfassen wir Ihre Daten?</h4>
        <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um
            Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
        <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme
            erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des
            Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
        <h4>Wofür nutzen wir Ihre Daten?</h4>
        <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
        <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
        <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
            personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser
            Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese
            Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
        <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
        <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>
        <p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem
            mit sogenannten Analyseprogrammen.</p>
        <p>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.</p>
        <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
        <h3>Externes Hosting</h3>
        <p>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf
            dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
            IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
            Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.</p>
        <p>Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
            bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
            Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p>Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
            erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p>
        <p>Wir setzen folgenden Hoster ein:</p>
        <p>domainfactory GmbH<br>
            Oskar-Messter-Str. 33<br>
            85737 Ismaning<br>
            Deutschland</p>
        <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
        <h3>Datenschutz</h3>
        <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
            personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
            Datenschutzerklärung.</p>
        <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
            sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
            das geschieht.</p>
        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.</p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>
        <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
        <p>Baustoff-Euro-Trade GmbH<br>
            Bahnhofstrasse 22 <br>
            15806 Zossen</p>

        <p>Telefon: 030 577 134 2110<br>
            E-Mail: info@baustoffhandel-baudiscount.de</p>
        <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
            die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. Ä.)
            entscheidet.</p>

        <h3>Speicherdauer</h3>
        <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre
            personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
            Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
            gelöscht, sofern wir keinen anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen
            Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
            Löschung nach Fortfall dieser Gründe.</p>
        <h3>Hinweis zur Datenweitergabe in die USA</h3>
        <p>Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese
            Tools aktiv sind, können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen
            weitergegeben werden. Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des
            EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an
            Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern
            befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf
            diese Verarbeitungstätigkeiten keinen Einfluss.</p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
        <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
            bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.</p>
        <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
        </h3>
        <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT
            DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
            PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
            DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN
            NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p>
        <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
            JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
            WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
            STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
            DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
        <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>
        <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde,
            insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
            mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.</p>
        <h3>Recht auf Daten­übertrag­barkeit</h3>
        <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
        <h3>SSL- bzw. TLS-Verschlüsselung</h3>
        <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
            Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
            TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
            von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
        <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
            Dritten mitgelesen werden.</p>
        <h3>Verschlüsselter Zahlungsverkehr auf dieser Website</h3>
        <p>Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten
            (z.&nbsp;B. Kontonummer bei Einzugsermächtigung) zu übermitteln, werden diese Daten zur Zahlungsabwicklung
            benötigt.</p>
        <p>Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren) erfolgt
            ausschließlich über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol
            in Ihrer Browserzeile.</p>
        <p>Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns übermitteln, nicht von Dritten
            mitgelesen werden.</p>
        <h3>Auskunft, Löschung und Berichtigung</h3>
        <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
            über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
            Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>
        <h3>Recht auf Einschränkung der Verarbeitung</h3>
        <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu
            können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen:</p>
        <ul>
            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
                in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der
                Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung
                oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren
                und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
                haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
        </ul>
        <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer
            Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet
            werden.</p>
        <h3>Widerspruch gegen Werbe-E-Mails</h3>
        <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
            ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
            Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
            Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Cookies</h3>
        <p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem
            Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies)
            oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
            Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
            selbst löschen&nbsp;oder eine automatische Löschung durch Ihren Webbrowser erfolgt.</p>
        <p>Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere
            Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter
            Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</p>
        <p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
            Websitefunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von
            Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten&nbsp;oder Werbung anzuzeigen.</p>
        <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur
            Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die
            Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur Messung des Webpublikums) erforderlich
            sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage
            angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur
            technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
            Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies ausschließlich auf
            Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies
            nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das
            automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.</p>
        <p>Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im
            Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.</p>
        <h3>Server-Log-Dateien</h3>
        <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien,
            die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
        <ul>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
        </ul>
        <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
        <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu
            müssen die Server-Log-Files erfasst werden.</p>
        <h3>Kontaktformular</h3>
        <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
            inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
            Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
        <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich
            ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6
            Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</p>
        <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern,
            Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B.
            nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.</p>
        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
        <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
            gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
        <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich
            ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6
            Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</p>
        <p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
            (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
            insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>
        <h3>Kommunikation via WhatsApp</h3>
        <p>Für die Kommunikation mit unseren Kunden und sonstigen Dritten nutzen wir unter anderem den
            Instant-Messaging-Dienst WhatsApp. Anbieter ist die WhatsApp Ireland Limited, 4 Grand Canal Square, Grand
            Canal Harbour, Dublin 2, Irland.</p>
        <p>Die Kommunikation erfolgt über eine Ende-zu-Ende-Verschlüsselung (Peer-to-Peer), die verhindert, dass
            WhatsApp oder sonstige Dritte Zugriff auf die Kommunikationsinhalte erlangen können. WhatsApp erhält jedoch
            Zugriff auf Metadaten, die im Zuge des Kommunikationsvorgangs entstehen (z.B. Absender, Empfänger und
            Zeitpunkt). Wir weisen ferner darauf hin, dass WhatsApp nach eigener Aussage, personenbezogene Daten seiner
            Nutzer mit seiner in den USA ansässigen Konzernmutter Facebook teilt. Weitere Details zur Datenverarbeitung
            finden Sie in der Datenschutzrichtlinie von WhatsApp unter: <a
                href="https://www.whatsapp.com/legal/#privacy-policy" target="_blank"
                rel="noopener noreferrer">https://www.whatsapp.com/legal/#privacy-policy</a>.</p>
        <p>Der Einsatz von WhatsApp erfolgt auf Grundlage unseres berechtigten Interesses an einer möglichst schnellen
            und effektiven Kommunikation mit Kunden, Interessenten und sonstigen Geschäfts- und Vertragspartnern (Art. 6
            Abs. 1 S. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
            Datenverarbeitung ausschließlich auf Grundlage der Einwilligung; diese ist jederzeit mit Wirkung für die
            Zukunft widerrufbar.</p>
        <p>Die zwischen und auf WhatsApp ausgetauschten Kommunikationsinhalte verbleiben bei uns, bis Sie uns zur
            Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
            insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>
        <p>Wir nutzen WhatsApp in der Variante „WhatsApp Business“.</p>
        <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
            finden Sie hier: <a href="https://www.whatsapp.com/legal/business-data-transfer-addendum" target="_blank"
                rel="noopener noreferrer">https://www.whatsapp.com/legal/business-data-transfer-addendum</a>.</p>
        <p>Wir haben unsere WhatsApp-Accounts so eingestellt, dass es keinen automatischen Datenabgleich mit dem
            Adressbuch auf den im Einsatz befindlichen Smartphones macht.</p>
        <p>Wir haben einen Vertrag über Auftragsverarbeitung mit WhatsApp geschlossen.</p>
        <h3>Registrierung mit Facebook Connect</h3>
        <p>Statt einer direkten Registrierung auf dieser Website können Sie sich mit Facebook Connect registrieren.
            Anbieter dieses Dienstes ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die
            erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere Drittländer
            übertragen.</p>
        <p>Wenn Sie sich für die Registrierung mit Facebook Connect entscheiden und auf den „Login with
            Facebook”-/„Connect with Facebook”-Button klicken, werden Sie automatisch auf die Plattform von Facebook
            weitergeleitet. Dort können Sie sich mit Ihren Nutzungsdaten anmelden. Dadurch wird Ihr Facebook-Profil mit
            dieser Website bzw. unseren Diensten verknüpft. Durch diese Verknüpfung erhalten wir Zugriff auf Ihre bei
            Facebook hinterlegten Daten. Dies sind vor allem:</p>
        <ul>
            <li>Facebook-Name</li>
            <li>Facebook-Profil- und Titelbild</li>
            <li>Facebook-Titelbild</li>
            <li>bei Facebook hinterlegte E-Mail-Adresse</li>
            <li>Facebook-ID</li>
            <li>Facebook-Freundeslisten</li>
            <li>Facebook Likes („Gefällt-mir“-Angaben)</li>
            <li>Geburtstag</li>
            <li>Geschlecht</li>
            <li>Land</li>
            <li>Sprache</li>
        </ul>
        <p>Diese Daten werden zur Einrichtung, Bereitstellung und Personalisierung Ihres Accounts genutzt.</p>
        <p>Die Registrierung mit Facebook-Connect und die damit verbundenen Datenverarbeitungsvorgänge erfolgen auf
            Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Diese Einwilligung können Sie jederzeit mit
            Wirkung für die Zukunft widerrufen.</p>
        <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
            finden Sie hier: <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank"
                rel="noopener noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a>, <a
                href="https://de-de.facebook.com/help/566994660333381" target="_blank"
                rel="noopener noreferrer">https://de-de.facebook.com/help/566994660333381</a> und <a
                href="https://www.facebook.com/policy.php" target="_blank"
                rel="noopener noreferrer">https://www.facebook.com/policy.php</a>.</p>
        <p>Weitere Informationen finden Sie in den Facebook-Nutzungsbedingungen und den
            Facebook-Datenschutzbestimmungen. Diese finden Sie unter: <a
                href="https://de-de.facebook.com/about/privacy/" target="_blank"
                rel="noopener noreferrer">https://de-de.facebook.com/about/privacy/</a> und <a
                href="https://de-de.facebook.com/legal/terms/" target="_blank"
                rel="noopener noreferrer">https://de-de.facebook.com/legal/terms/</a>.</p>
        <h3>Kommentar­funktion auf dieser Website</h3>
        <p>Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch Angaben zum Zeitpunkt der
            Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte
            Nutzername gespeichert.</p>
        <h4>Speicherdauer der Kommentare</h4>
        <p>Die Kommentare und die damit verbundenen Daten werden gespeichert und verbleiben auf dieser Website, bis der
            kommentierte Inhalt vollständig gelöscht wurde oder die Kommentare aus rechtlichen Gründen gelöscht werden
            müssen (z.&nbsp;B. beleidigende Kommentare).</p>
        <h4>Rechtsgrundlage</h4>
        <p>Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
            können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
            unberührt.</p>
        <h2>5. Analyse-Tools und Werbung</h2>
        <h3>Google Analytics</h3><a href="javascript:gaOptout();">Google Analytics deaktivieren</a>
        <!--<button onclick="gaOptout();">Google Analytics deaktivieren</button>--> - klick hier
        <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland
            Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
        <p>Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu analysieren.
            Hierbei erhält der Websitebetreiber verschiedene Nutzungsdaten, wie z.B. Seitenaufrufe, Verweildauer,
            verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem Profil
            zusammengefasst, das dem jeweiligen Nutzer bzw. dessen Endgerät zugeordnet ist.</p>
        <p>Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der Analyse des
            Nutzerverhaltens ermöglichen (z.B. Cookies oder Device-Fingerprinting). Die von Google erfassten
            Informationen über die Benutzung dieser Website werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert.</p>
        <p>Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
            hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
            Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
            Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
            DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
            finden Sie hier: <a href="https://privacy.google.com/businesses/controllerterms/mccs/" target="_blank"
                rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/</a>.</p>
        <h4>IP Anonymisierung</h4>
        <p>Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von
            Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
            über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird
            die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
            Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten,
            um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
            Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt.</p>
        <h4>Browser Plugin</h4>
        <p>Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern, indem Sie das unter dem
            folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
        <p>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von
            Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank"
                rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
        <h4>Auftragsverarbeitung</h4>
        <p>Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben
            der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.</p>
        <h4>Demografische Merkmale bei Google Analytics</h4>
        <p>Diese Website nutzt die Funktion „demografische Merkmale“ von Google Analytics, um den Websitebesuchern
            passende Werbeanzeigen innerhalb des Google-Werbenetzwerks anzeigen zu können. Dadurch können Berichte
            erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten
            stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten
            können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die
            Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google
            Analytics wie im Punkt „Widerspruch gegen Datenerfassung“ dargestellt generell untersagen.</p>
        <h4>Google Analytics E-Commerce-Tracking</h4>
        <p>Diese Website nutzt die Funktion „E-Commerce-Tracking“ von Google Analytics. Mit Hilfe von
            E-Commerce-Tracking kann der Websitebetreiber das Kaufverhalten der Websitebesucher zur Verbesserung seiner
            Online-Marketing-Kampagnen analysieren. Hierbei werden Informationen, wie zum Beispiel die getätigten
            Bestellungen, durchschnittliche Bestellwerte, Versandkosten und die Zeit von der Ansicht bis zum Kauf eines
            Produktes erfasst. Diese Daten können von Google unter einer Transaktions-ID zusammengefasst werden, die dem
            jeweiligen Nutzer bzw. dessen Gerät zugeordnet ist.</p>

        <h3>Google Ads</h3>
        <p>Der Websitebetreiber verwendet Google Ads. Google Ads ist ein Online-Werbeprogramm der Google Ireland Limited
            („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
        <p>Google Ads ermöglicht es uns Werbeanzeigen in der Google-Suchmaschine oder auf Drittwebseiten auszuspielen,
            wenn der Nutzer bestimmte Suchbegriffe bei Google eingibt (Keyword-Targeting). Ferner können zielgerichtete
            Werbeanzeigen anhand der bei Google vorhandenen Nutzerdaten (z.B. Standortdaten und Interessen) ausgespielt
            werden (Zielgruppen-Targeting). Wir als Websitebetreiber können diese Daten quantitativ auswerten, indem wir
            beispielsweise analysieren, welche Suchbegriffe zur Ausspielung unserer Werbeanzeigen geführt haben und wie
            viele Anzeigen zu entsprechenden Klicks geführt haben.</p>
        <p>Die Nutzung von Google Ads erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an einer möglichst effektiven Vermarktung seiner Dienstleistung Produkte.</p>
        <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
            finden Sie hier: <a href="https://policies.google.com/privacy/frameworks" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy/frameworks</a> und <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/" target="_blank"
                rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/</a>.</p>
        <h3>Google Remarketing</h3>
        <p>Diese Website nutzt die Funktionen von Google Analytics Remarketing. Anbieter ist die Google Ireland Limited
            („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
        <p>Google Remarketing analysiert Ihr Nutzerverhalten auf unserer Website (z.B. Klick auf bestimmte Produkte), um
            Sie in bestimmte Werbe-Zielgruppen einzuordnen und Ihnen anschließend beim Besuch von anderen
            Onlineangeboten passende Webebotschaften auszuspielen (Remarketing bzw. Retargeting).</p>
        <p>Des Weiteren können die mit Google Remarketing erstellten Werbe-Zielgruppen mit den geräteübergreifenden
            Funktionen von Google verknüpft werden. Auf diese Weise können interessenbezogene, personalisierte
            Werbebotschaften, die in Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem Endgerät (z. B.
            Handy) an Sie angepasst wurden auch auf einem anderen Ihrer Endgeräte (z. B. Tablet oder PC) angezeigt
            werden.</p>
        <p>Wenn Sie über einen Google-Account verfügen, können Sie der personalisierten Werbung unter folgendem Link
            widersprechen: <a href="https://www.google.com/settings/ads/onweb/" target="_blank"
                rel="noopener noreferrer">https://www.google.com/settings/ads/onweb/</a>.</p>
        <p>Die Nutzung von Google Remarketing erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
            hat ein berechtigtes Interesse an einer möglichst effektiven Vermarktung seiner Produkte. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
            Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der Datenschutzerklärung von Google
            unter: <a href="https://policies.google.com/technologies/ads?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/technologies/ads?hl=de</a>.</p>
        <h4>Zielgruppenbildung mit Kundenabgleich</h4>
        <p>Zur Zielgruppenbildung verwenden wir unter anderem den Kundenabgleich von Google Remarketing. Hierbei
            übergeben wir bestimmte Kundendaten (z.B. E-Mail-Adressen) aus unseren Kundenlisten an Google. Sind die
            betreffenden Kunden Google-Nutzer und in ihrem Google-Konto eingeloggt, werden ihnen passende
            Werbebotschaften innerhalb des Google-Netzwerks (z.B. bei YouTube, Gmail oder in der Suchmaschine)
            angezeigt.</p>
        <h3>Google Conversion-Tracking</h3>
        <p>Diese Website nutzt Google Conversion Tracking. Anbieter ist die Google Ireland Limited („Google“), Gordon
            House, Barrow Street, Dublin 4, Irland.</p>
        <p>Mit Hilfe von Google-Conversion-Tracking können Google und wir erkennen, ob der Nutzer bestimmte Aktionen
            durchgeführt hat. So können wir beispielsweise auswerten, welche Buttons auf unserer Website wie häufig
            geklickt und welche Produkte besonders häufig angesehen oder gekauft wurden. Diese Informationen dienen
            dazu, Conversion-Statistiken zu erstellen. Wir erfahren die Gesamtanzahl der Nutzer, die auf unsere Anzeigen
            geklickt haben und welche Aktionen sie durchgeführt haben. Wir erhalten keine Informationen, mit denen wir
            den Nutzer persönlich identifizieren können. Google selbst nutzt zur Identifikation Cookies oder
            vergleichbare Wiedererkennungstechnologien.</p>
        <p>Die Nutzung von Google Conversion-Tracking erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
            Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z.
            B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von
            Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Mehr Informationen zu Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von Google: <a
                href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
        <h2>6. Newsletter</h2>
        <h3>Newsletter­daten</h3>
        <p>Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine
            E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der
            angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden
            nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für den Versand der
            angeforderten Informationen und geben diese nicht an Dritte weiter.</p>
        <p>Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf Grundlage
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der
            E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa über
            den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
            bleibt vom Widerruf unberührt.</p>
        <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
            Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
            Abbestellung des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir behalten
            uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen unseres
            berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren.</p>
        <p>Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
            Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um künftige Mailings zu verhindern. Die Daten
            aus der Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies
            dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim
            Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in
            der Blacklist ist zeitlich nicht befristet. <strong>Sie können der Speicherung widersprechen, sofern Ihre
                Interessen unser berechtigtes Interesse überwiegen.</strong></p>
        <h2>7. Plugins und Tools</h2>
        <h3>YouTube</h3>
        <p>Diese Website bindet Videos der Website YouTube ein. Betreiber der Website ist die Google Ireland Limited
            („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
        <p>Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube eingebunden ist, wird eine Verbindung zu den
            Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
            haben.</p>
        <p>Des Weiteren kann YouTube verschiedene Cookies auf Ihrem Endgerät speichern oder vergleichbare Technologien
            zur Wiedererkennung verwenden (z.B. Device-Fingerprinting). Auf diese Weise kann YouTube Informationen über
            Besucher dieser Website erhalten. Diese Informationen werden u. a. verwendet, um Videostatistiken zu
            erfassen, die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.</p>
        <p>Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
            persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account
            ausloggen.</p>
        <p>Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies
            stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
            Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
            DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: <a
                href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
        <h3>Google Web Fonts (lokales Hosting)</h3>
        <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
            bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet
            dabei nicht statt.</p>
        <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq"
                target="_blank" rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> und in der
            Datenschutzerklärung von Google: <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
        <h3>Adobe Fonts</h3>
        <p>Diese Website nutzt zur einheitlichen Darstellung bestimmter Schriftarten Web Fonts von Adobe. Anbieter ist
            die Adobe Systems Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA (Adobe).</p>
        <p>Beim Aufruf dieser Website lädt Ihr Browser die benötigten Schriftarten direkt von Adobe, um sie Ihrem
            Endgerät korrekt anzeigen zu können. Dabei stellt Ihr Browser eine Verbindung zu den Servern von Adobe in
            den USA her. Hierdurch erlangt Adobe Kenntnis darüber, dass über Ihre IP-Adresse diese Website aufgerufen
            wurde. Bei der Bereitstellung der Schriftarten werden nach Aussage von Adobe keine Cookies gespeichert.</p>
        <p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse an der einheitlichen Darstellung des Schriftbildes auf
            seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
            Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
            DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
            finden Sie hier: <a href="https://www.adobe.com/de/privacy/eudatatransfers.html" target="_blank"
                rel="noopener noreferrer">https://www.adobe.com/de/privacy/eudatatransfers.html</a>.</p>
        <p>Nähere Informationen zu Adobe Fonts erhalten Sie unter: <a
                href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html" target="_blank"
                rel="noopener noreferrer">https://www.adobe.com/de/privacy/policies/adobe-fonts.html</a>.</p>
        <p>Die Datenschutzerklärung von Adobe finden Sie unter: <a href="https://www.adobe.com/de/privacy/policy.html"
                target="_blank" rel="noopener noreferrer">https://www.adobe.com/de/privacy/policy.html</a></p>
        <h3>Font Awesome (lokales Hosting)</h3>
        <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font Awesome. Font Awesome ist lokal
            installiert. Eine Verbindung zu Servern von Fonticons, Inc.&nbsp; findet dabei nicht statt.</p>
        <p>Weitere Informationen zu Font Awesome finden Sie&nbsp;und in der Datenschutzerklärung für Font Awesome unter:
            <a href="https://fontawesome.com/privacy" target="_blank"
                rel="noopener noreferrer">https://fontawesome.com/privacy</a>.</p>
        <h3>Google Maps</h3>
        <p>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited („Google“), Gordon
            House, Barrow Street, Dublin 4, Irland.</p>
        <p>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese
            Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der
            Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.</p>
        <p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und
            an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt
            wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar.</p>
        <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
            finden Sie hier: <a href="https://privacy.google.com/businesses/gdprcontrollerterms/" target="_blank"
                rel="noopener noreferrer">https://privacy.google.com/businesses/gdprcontrollerterms/</a> und <a
                href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/" target="_blank"
                rel="noopener noreferrer">https://privacy.google.com/businesses <br/> /gdprcontrollerterms/sccs/</a>.</p>
        <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a
                href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
        <h3>Google reCAPTCHA</h3>
        <p>Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website. Anbieter ist die Google Ireland
            Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
        <p>Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z.&nbsp;B. in einem
            Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert
            reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt
            automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene
            Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer
            getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.</p>
        <p>Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen,
            dass eine Analyse stattfindet.</p>
        <p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher
            automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine entsprechende Einwilligung abgefragt wurde,
            erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
            jederzeit widerrufbar.</p>
        <p>Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen und den Google
            Nutzungsbedingungen unter folgenden Links: <a href="https://policies.google.com/privacy?hl=de"
                target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a> und <a
                href="https://policies.google.com/terms?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/terms?hl=de</a>.</p>
        <h2>8. eCommerce und Zahlungs­anbieter</h2>
        <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
        <p>Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche
            Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
            Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme dieser Website
            (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die
            Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.</p>
        <p>Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht.
            Gesetzliche Aufbewahrungsfristen bleiben unberührt.</p>
        <h3>Daten­übermittlung bei Vertragsschluss für Online-Shops, Händler und Warenversand</h3>
        <p>Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung
            notwendig ist, etwa an die mit der Lieferung der Ware betrauten Unternehmen oder das mit der
            Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende Übermittlung der Daten erfolgt nicht bzw.
            nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte
            ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.</p>
        <p>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
            Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>
        <h3>Daten­übermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte</h3>
        <p>Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung
            notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut.</p>
        <p>Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich
            zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der
            Werbung, erfolgt nicht.</p>
        <p>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
            Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>
        <h3>Zahlungsdienste</h3>
        <p>Wir binden Zahlungsdienste von Drittunternehmen auf unserer Website ein. Wenn Sie einen Kauf bei uns tätigen,
            werden Ihre Zahlungsdaten (z.B. Name, Zahlungssumme, Kontoverbindung, Kreditkartennummer) vom
            Zahlungsdienstleister zum Zwecke der Zahlungsabwicklung verarbeitet. Für diese Transaktionen gelten die
            jeweiligen Vertrags- und Datenschutzbestimmungen der jeweiligen Anbieter. Der Einsatz der
            Zahlungsdienstleister erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Vertragsabwicklung) sowie im
            Interesse eines möglichst reibungslosen, komfortablen und sicheren Zahlungsvorgangs (Art. 6 Abs. 1 lit. f
            DSGVO). Soweit für bestimmte Handlungen Ihre Einwilligung abgefragt wird, ist Art. 6 Abs. 1 lit. a DSGVO
            Rechtsgrundlage der Datenverarbeitung; Einwilligungen sind jederzeit für die Zukunft widerrufbar.</p>
        <p>Folgende Zahlungsdienste / Zahlungsdienstleister setzen wir im Rahmen dieser Website ein:</p>
        <h4>PayPal</h4>
        <p>Anbieter dieses Zahlungsdienstes ist PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449
            Luxembourg (im Folgenden „PayPal“).</p>
        <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
            finden Sie hier: <a href="https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full" target="_blank"
                rel="noopener noreferrer">https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full</a>.</p>
        <p>Details entnehmen Sie der Datenschutzerklärung von PayPal: <a
                href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full" target="_blank"
                rel="noopener noreferrer">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>.</p>
        <h4>Apple Pay</h4>
        <p>Anbieter des Zahlungsdienstes ist Apple Inc., Infinite Loop, Cupertino, CA 95014, USA. Die
            Datenschutzerklärung von Apple finden Sie unter: <a href="https://www.apple.com/legal/privacy/de-ww/"
                target="_blank" rel="noopener noreferrer">https://www.apple.com/legal/privacy/de-ww/</a>.</p>
        <h4>Google Pay</h4>
        <p>Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Die Datenschutzerklärung
            von Google finden Sie hier: <a href="https://policies.google.com/privacy" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy</a>.</p>
        <h4>Klarna</h4>
        <p>Anbieter ist die Klarna AB, Sveavägen 46, 111 34 Stockholm, Schweden (im Folgenden „Klarna“). Klarna bietet
            verschiedene Zahlungsoptionen an (z.&nbsp;B. Ratenkauf). Wenn Sie sich für die Bezahlung mit Klarna
            entscheiden (Klarna-Checkout-Lösung), wird Klarna verschiedene personenbezogene Daten von Ihnen erheben.
            Klarna nutzt Cookies, um die Verwendung der Klarna-Checkout-Lösung zu optimieren. Details zum Einsatz von
            Klarna-Cookies entnehmen Sie folgendem Link: <a
                href="https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf" target="_blank"
                rel="noopener noreferrer">https://cdn.klarna.com/1.0/shared/ <br> content/policy/cookie/de_de/checkout.pdf</a>.
        </p>
        <p>Details hierzu können Sie in der Datenschutzerklärung von Klarna unter folgendem Link nachlesen: <a
                href="https://www.klarna.com/de/datenschutz/" target="_blank"
                rel="noopener noreferrer">https://www.klarna.com/de/datenschutz/</a>.</p>
        <h4>Paydirekt</h4>
        <p>Anbieter dieses Zahlungsdienstes ist die Paydirekt GmbH, Hamburger Allee 26-28, 60486 Frankfurt am Main,
            Deutschland (im Folgenden „Paydirekt“). Wenn Sie die Bezahlung mittels Paydirekt ausführen, erhebt Paydirekt
            verschiedene Transaktionsdaten und leitet diese an die Bank weiter, bei der Sie mit Paydirekt registriert
            sind. Neben den für die Zahlung erforderlichen Daten erhebt Paydirekt im Rahmen der Transaktionsabwicklung
            ggf. weitere Daten wie z.&nbsp;B. Lieferadresse oder einzelne Positionen im Warenkorb. Paydirekt
            authentifiziert die Transaktion anschließend mit Hilfe des bei der Bank hierfür hinterlegten
            Authentifizierungsverfahrens. Anschließend wird der Zahlbetrag von Ihrem Konto auf unser Konto überwiesen.
            Weder wir noch Dritte haben Zugriff auf Ihre Kontodaten. Details zur Zahlung mit Paydirekt entnehmen Sie den
            AGB und den Datenschutzbestimmungen von Paydirekt unter: <a href="https://www.paydirekt.de/agb/index.html"
                target="_blank" rel="noopener noreferrer">https://www.paydirekt.de/agb/index.html</a>.</p>
        <h4>Sofort­überweisung</h4>
        <p>Anbieter dieses Zahlungsdienstes ist die Sofort GmbH, Theresienhöhe 12, 80339 München (im Folgenden „Sofort
            GmbH“). Mit Hilfe des Verfahrens „Sofortüberweisung“ erhalten wir in Echtzeit eine Zahlungsbestätigung von
            der Sofort GmbH und können unverzüglich mit der Erfüllung unserer Verbindlichkeiten beginnen. Wenn Sie sich
            für die Zahlungsart „Sofortüberweisung“ entschieden haben, übermitteln Sie die PIN und eine gültige TAN an
            die Sofort GmbH, mit der diese sich in Ihr Online-Banking-Konto einloggen kann. Sofort GmbH überprüft nach
            dem Einloggen automatisch Ihren Kontostand und führt die Überweisung an uns mit Hilfe der von Ihnen
            übermittelten TAN durch. Anschließend übermittelt sie uns unverzüglich eine Transaktionsbestätigung. Nach
            dem Einloggen werden außerdem Ihre Umsätze, der Kreditrahmen des Dispokredits und das Vorhandensein anderer
            Konten sowie deren Bestände automatisiert geprüft. Neben der PIN und der TAN werden auch die von Ihnen
            eingegebenen Zahlungsdaten sowie Daten zu Ihrer Person an die Sofort GmbH übermittelt. Bei den Daten zu
            Ihrer Person handelt es sich um Vor- und Nachnamen, Adresse, Telefonnummer(n), E-Mail-Adresse, IP-Adresse
            und ggf. weitere zur Zahlungsabwicklung erforderliche Daten. Die Übermittlung dieser Daten ist notwendig, um
            Ihre Identität zweifelsfrei zu festzustellen und Betrugsversuchen vorzubeugen. Details zur Zahlung mit
            Sofortüberweisung entnehmen Sie folgenden Links: <a href="https://www.sofort.de/datenschutz.html"
                target="_blank" rel="noopener noreferrer">https://www.sofort.de/datenschutz.html</a> und <a
                href="https://www.klarna.com/sofort/" target="_blank"
                rel="noopener noreferrer">https://www.klarna.com/sofort/</a>.</p>
        <h2>9. Audio- und Videokonferenzen</h2>
        <h4>Datenverarbeitung</h4>
        <p>Für die Kommunikation mit unseren Kunden setzen wir unter anderen Online-Konferenz-Tools ein. Die im
            Einzelnen von uns genutzten Tools sind unten aufgelistet. Wenn Sie mit uns per Video- oder Audiokonferenz
            via Internet kommunizieren, werden Ihre personenbezogenen Daten von uns und dem Anbieter des jeweiligen
            Konferenz-Tools erfasst und verarbeitet.</p>
        <p>Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung der Tools bereitstellen/einsetzen
            (E-Mail-Adresse und/oder Ihre Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der
            Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz, Anzahl der Teilnehmer und sonstige
            „Kontextinformationen“ im Zusammenhang mit dem Kommunikationsvorgang (Metadaten).</p>
        <p>Des Weiteren verarbeitet der Anbieter des Tools alle technischen Daten, die zur Abwicklung der
            Online-Kommunikation erforderlich sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen, Geräte-IDs,
            Gerätetyp, Betriebssystemtyp und -version, Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die
            Art der Verbindung.</p>
        <p>Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in sonstiger Weise bereitgestellt werden,
            werden diese ebenfalls auf den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten zählen
            insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten, Voicemails hochgeladene Fotos und Videos,
            Dateien, Whiteboards und andere Informationen, die während der Nutzung des Dienstes geteilt werden.</p>
        <p>Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der verwendeten
            Tools haben. Unsere Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik des jeweiligen
            Anbieters. Weitere Hinweise zur Datenverarbeitung durch die Konferenztools entnehmen Sie den
            Datenschutzerklärungen der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt haben.&nbsp;</p>
        <h4>Zweck und Rechtsgrundlagen</h4>
        <p>Die Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden Vertragspartnern zu kommunizieren oder
            bestimmte Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 S. 1 lit. b DSGVO). Des Weiteren
            dient der Einsatz der Tools der allgemeinen Vereinfachung und Beschleunigung der Kommunikation mit uns bzw.
            unserem Unternehmen (berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO). Soweit eine
            Einwilligung abgefragt wurde, erfolgt der Einsatz der betreffenden Tools auf Grundlage dieser Einwilligung;
            die Einwilligung ist jederzeit mit Wirkung für die Zukunft widerrufbar.&nbsp;</p>
        <h4>Speicherdauer</h4>
        <p>Die unmittelbar von uns über die Video- und Konferenz-Tools erfassten Daten werden von unseren Systemen
            gelöscht, sobald Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
            Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie
            löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben unberührt.</p>
        <p>Auf die Speicherdauer Ihrer Daten, die von den Betreibern der Konferenz-Tools zu eigenen Zwecken gespeichert
            werden, haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den
            Betreibern der Konferenz-Tools.</p>
        <h4>Eingesetzte Konferenz-Tools</h4>
        <p>Wir setzen folgende Konferenz-Tools ein:</p>
        <h3>Google Hangouts</h3>
        <p>Wir nutzen Google Hangouts. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Irland. Details zur Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Google Hangouts: <a
                href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
        <h4>Abschluss eines Vertrags über Auftragsverarbeitung</h4>
        <p>Wir haben mit dem Anbieter von Google Hangouts einen Vertrag zur Auftragsverarbeitung abgeschlossen und
            setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Hangouts
            vollständig um.</p>
        <h3>Google Meet</h3>
        <p>Wir nutzen Google Meet. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Irland. Details zur Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Google: <a
                href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
        <h4>Abschluss eines Vertrags über Auftragsverarbeitung</h4>
        <p>Wir haben mit dem Anbieter von Google Meet einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen
            die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Meet vollständig um.</p>

        <p><i>Stand: 2024</i></p>


        <p>&nbsp;<br></p>
        <p align="right">
            <img loading="lazy" src="@/assets/impressum/erecht24-siegel-datenschutzerklaerung-blau.png">
            <img loading="lazy" src="@/assets/impressum/erecht24-siegel-disclaimer-blau.png">
            <img loading="lazy" src="@/assets/impressum/erecht24-siegel-impressum-blau.png">
        </p>


    </div>
</template>

<style lang="scss" scoped>
.impressum__content {
    width: 75%;
    margin: 0 auto;
    padding: 2rem 0;

    @media screen and (max-width: 768px) {
      width: 90%
    }

    @media screen and (min-width: 2160px) {
      width: 60%
    }

    a {
        word-break: break-all;
    }
}

h3 {
    font-size: 3rem;
    margin-top: 0;
}

h2 {
    font-size: 1rem;
}

h1 {
    font-size: 2rem;
}
</style>