<template>
<svg width="43" height="40" viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-13.8322 14.9912C-13.4876 15.0421 -13.1353 15.069 -12.7767 15.069C-9.47436 15.069 -6.67949 12.8988 -5.72081 9.90132C-5.55687 9.38893 -10.5263 0.600657 -10 0.5C-4.51584 -0.548958 -4.5 0.814816 -4.5 -5C-4.5 -12.9348 1.79276 -10.2187 3.50622 -10.5595C4.9542 -10.8476 6.45075 -11 7.98295 -11C20.6766 -10.9999 30.9668 -0.662398 30.9668 12.0897C30.9668 24.8417 20.6766 35.1794 7.98295 35.1794C-3.45717 35.1794 -12.9168 26.8137 -14.696 15.823C-14.7767 15.3243 -14.3297 14.9179 -13.8322 14.9912Z" fill="#F5B97D"/>
    <path d="M18.3627 7.62033C16.7249 7.62033 15.3972 6.28654 15.3972 4.64122C15.3972 2.9959 16.7249 1.66211 18.3627 1.66211C20.0004 1.66211 21.3281 2.9959 21.3281 4.64122C21.3281 6.28654 20.0004 7.62033 18.3627 7.62033Z" fill="#CD916E"/>
    <path d="M16.8812 21.7717C15.6528 21.7717 14.657 20.7713 14.657 19.5372C14.657 18.3031 15.6528 17.3027 16.8812 17.3027C18.1097 17.3027 19.1055 18.3031 19.1055 19.5372C19.1055 20.7713 18.1097 21.7717 16.8812 21.7717Z" fill="#CD916E"/>
    <path d="M2.79277 15.0682C3.06498 15.0682 3.32642 14.9176 3.4567 14.6564C3.63985 14.2884 3.49144 13.8411 3.12505 13.657L1.64223 12.9122C1.27583 12.7296 0.83133 12.8773 0.647369 13.2454C0.464217 13.6134 0.612624 14.0607 0.979019 14.2448L2.46183 14.9896C2.56831 15.0427 2.68126 15.0682 2.79277 15.0682Z" fill="#B67F5F"/>
    <path d="M22.8111 13.5786C23.0833 13.5786 23.3448 13.4281 23.4751 13.1669L24.2165 11.6772C24.3996 11.3092 24.2512 10.8619 23.8848 10.6778C23.5191 10.4952 23.0739 10.6429 22.89 11.011L22.1485 12.5006C21.9654 12.8687 22.1138 13.316 22.4802 13.5001C22.5867 13.5532 22.6996 13.5786 22.8111 13.5786Z" fill="#B67F5F"/>
    <path d="M13.1736 26.9855C13.4458 26.9855 13.7073 26.835 13.8376 26.5738C14.0207 26.2057 13.8723 25.7584 13.5059 25.5743L12.0231 24.8295C11.6567 24.6462 11.2122 24.7946 11.0282 25.1627C10.8451 25.5307 10.9935 25.978 11.3599 26.1621L12.8427 26.9069C12.9492 26.9601 13.0621 26.9855 13.1736 26.9855Z" fill="#B67F5F"/>
    <path d="M13.1734 15.0694C13.3631 15.0694 13.5528 14.9967 13.6976 14.8512L14.439 14.1064C14.7287 13.8154 14.7287 13.3441 14.439 13.0532C14.1494 12.7622 13.6802 12.7622 13.3907 13.0532L12.6492 13.798C12.3596 14.089 12.3596 14.5603 12.6492 14.8512C12.7941 14.9967 12.9837 15.0694 13.1734 15.0694Z" fill="#A06E50"/>
    <path d="M5.01836 30.711C5.42821 30.711 5.75977 30.3779 5.75977 29.9662V29.2214C5.75977 28.8096 5.42812 28.4766 5.01836 28.4766C4.6086 28.4766 4.27695 28.8097 4.27695 29.2214V29.9662C4.27695 30.3779 4.60851 30.711 5.01836 30.711Z" fill="#A06E50"/>
    <path d="M9.46558 2.40728C9.65528 2.40728 9.84499 2.33458 9.98981 2.1891C10.2794 1.89813 10.2794 1.42678 9.98981 1.1359L9.2484 0.391076C8.95876 0.10011 8.48957 0.10011 8.20002 0.391076C7.91039 0.682042 7.91039 1.1534 8.20002 1.44427L8.94143 2.1891C9.08616 2.33449 9.27587 2.40728 9.46558 2.40728Z" fill="#A06E50"/>
    <path d="M25.6524 34.0151L25.7761 32.7223C25.8777 31.6597 26.7148 30.8193 27.7737 30.717L29.7765 30.5234C31.0994 30.3957 32.2405 31.4526 32.2194 32.7862L32.1756 35.5645C32.1483 37.2962 30.2521 38.3352 28.7828 37.4237L26.7002 36.1317C25.9777 35.6837 25.5713 34.8627 25.6524 34.0151Z" fill="#F5B97D"/>
    <path d="M34.9956 28.1296L34.2455 27.4619C33.6572 26.9383 33.5767 26.0461 34.0619 25.4272L35.1921 23.9853C35.8763 23.1124 37.2394 23.2743 37.7052 24.2839L38.4668 25.9346C38.8238 26.7086 38.4625 27.6235 37.6747 27.9401L36.5331 28.399C36.0117 28.6084 35.4165 28.5042 34.9956 28.1296Z" fill="#F5B97D"/>
    <path d="M38.5209 32.1667C39.3126 32.3803 39.7831 33.1971 39.5718 33.9911C39.3605 34.7851 38.5475 35.2556 37.7558 35.042C36.9641 34.8284 36.4936 34.0116 36.7048 33.2176C36.9161 32.4236 37.7292 31.9531 38.5209 32.1667Z" fill="#F5B97D"/>
    <path d="M5.20262 32.3862C17.8963 32.3862 28.1865 22.0486 28.1865 9.29645C28.1865 3.63154 26.1554 -1.55688 22.7854 -5.57422C27.7884 -1.33861 30.9668 5.0024 30.9668 12.0896C30.9668 24.8416 20.6766 35.1793 7.98295 35.1793C0.923851 35.1793 -5.38091 31.9941 -9.59278 26.9723C-5.59827 30.3521 -0.438389 32.3862 5.20262 32.3862Z" fill="#E3AA75"/>
    <path d="M16.7869 6.03827C18.4248 6.03827 19.7526 4.70439 19.7526 3.05898C19.7526 2.64688 19.6692 2.25445 19.5186 1.89746C20.582 2.35024 21.3281 3.4083 21.3281 4.64171C21.3281 6.28712 20.0004 7.621 18.3625 7.621C17.1348 7.621 16.0815 6.87149 15.6309 5.80314C15.9862 5.95439 16.3768 6.03827 16.7869 6.03827Z" fill="#B67F5F"/>
    <path d="M15.7209 20.6089C16.9492 20.6089 17.9451 19.6085 17.9451 18.3744C17.9451 18.0593 17.8786 17.7602 17.7614 17.4883C18.5505 17.8316 19.1035 18.6193 19.1035 19.5382C19.1035 20.7723 18.1077 21.7727 16.8793 21.7727C15.9646 21.7727 15.1805 21.2171 14.8388 20.4244C15.1094 20.5421 15.4072 20.6089 15.7209 20.6089Z" fill="#B67F5F"/>
    <path d="M2.79431 24.7522C1.15653 24.7522 -0.171146 23.4184 -0.171146 21.7731C-0.171146 20.1277 1.15653 18.7939 2.79431 18.7939C4.43209 18.7939 5.75977 20.1277 5.75977 21.7731C5.75977 23.4184 4.43209 24.7522 2.79431 24.7522Z" fill="#CD916E"/>
    <path d="M1.21857 23.1691C2.85644 23.1691 4.1842 21.8353 4.1842 20.1898C4.1842 19.7777 4.1008 19.3853 3.95024 19.0283C5.01369 19.4811 5.75977 20.5392 5.75977 21.7726C5.75977 23.418 4.432 24.7519 2.79413 24.7519C1.56647 24.7519 0.513166 24.0023 0.0625572 22.934C0.417729 23.0853 0.808455 23.1691 1.21857 23.1691Z" fill="#B67F5F"/>
    <path d="M8.75546 9.85526C7.93652 9.85526 7.27264 9.18833 7.27264 8.36562C7.27264 7.54291 7.93652 6.87598 8.75546 6.87598C9.5744 6.87598 10.2383 7.54291 10.2383 8.36562C10.2383 9.18833 9.5744 9.85526 8.75546 9.85526Z" fill="#CD916E"/>
    <path d="M7.96773 9.06357C8.78671 9.06357 9.45055 8.39659 9.45055 7.57393C9.45055 7.36783 9.4088 7.17166 9.33356 6.99316C9.86524 7.21955 10.2383 7.74863 10.2383 8.36529C10.2383 9.18795 9.57444 9.85494 8.75547 9.85494C8.14163 9.85494 7.61498 9.48018 7.38963 8.94605C7.56731 9.02163 7.76267 9.06357 7.96773 9.06357Z" fill="#B67F5F"/>
</svg>
</template>