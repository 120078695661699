<script setup>
import PaginationArrowLeft from '@/components/svg/PaginationArrowLeft.vue';
import PaginationArrowRight from '@/components/svg/PaginationArrowRight.vue';
import CloseModalIcon from '@/components/svg/CloseModalIcon.vue';

import { Swiper, SwiperSlide} from 'swiper/vue';
import { Pagination, Navigation, Controller } from "swiper";
import 'swiper/scss';
import "swiper/scss/pagination";
import {ref, watch} from 'vue';

const props = defineProps({
    modalShown: {
        type: Boolean,
        required: true
    },
    imagesData: {
        type: Array,
        required: true
    },
    activeSlide: {
        type: Number,
        required: false
    }
})

const emit = defineEmits(['closeModal'])

const closeModal = () => {
    emit('closeModal')
}

const swiper = ref(null)

const setControlledSwipper = (swiperInstance) => {
    swiper.value = swiperInstance
}

watch(()=>props.activeSlide, (index)=>{
    swiper.value.slideTo(index, 0, false)
})

</script>

<template>
    <div class="gallery-modal" @click="closeModal" :class="{ shown: props.modalShown }">
        <div class="modal-wrapper" @click.stop>
            <div class="closeIcon" @click="closeModal">
                <CloseModalIcon color="white"/>
            </div>
            <div class="prevIcon">
                <PaginationArrowLeft color="white"/>
            </div>
            <Swiper class="images-container "
            :modules="[Navigation, Pagination, Controller]"
            :controller="{
                control: swiper
            }"
            @swiper="setControlledSwipper"
            :grab-cursor="true"
            :space-between="16"
            :navigation="{
                prevEl: '.prevIcon',
                nextEl: '.nextIcon',
                disabledClass: 'arrow-disabled'
            }" 
            :pagination="{
                el: '.swiper-pagination.inModal',
                type: 'bullets',
                dynamicBullets: true,
                dynamicMainBullets: 10,
                clickable: true
            }"
            >
                <template v-for="gridImages in props.imagesData">
                    <SwiperSlide v-for="image in gridImages" :key="image.index" class="image-wrapper">
                        <img loading="lazy" :src="image.imageSrc"
                            :class="{ portrait: image.portraitMode }" alt="">
                    </SwiperSlide>
                </template>
                <div class="swiper-pagination inModal"></div>
            </Swiper>
            <div class="nextIcon">
                <PaginationArrowRight color="white"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .gallery-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000000000;
    background-color: rgba($color: #000000, $alpha: 0.75);
    display: none;
    justify-content: center;
    align-items: center;

    &.shown {
        display: flex;
    }

    .modal-wrapper {
        width: min(1200px, 80%);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;

        .yt-player {
            width: 100%;
            aspect-ratio: 16/9;
        }

        .arrow-disabled {
            display: none;
        }

        @media screen and (max-width: 1024px) {
            width: 85%;
        }

        @media screen and (max-width: 768px) {
            width: 95%;
            padding: 1rem;
        }

        @media screen and (max-width: 576px) {
            width: 100%;
        }

        .closeIcon,
        .prevIcon,
        .nextIcon {
            @media screen and (max-width: 768px) {
                background-color: rgba($color: #000000, $alpha: 0.5);
            }
        }

        .closeIcon {
            position: absolute;
            top: -1%;
            right: -1%;
            cursor: pointer;
            z-index: 2;

            @media screen and (max-width: 768px) {
                top: 1.75rem;
                right: 1.75rem;
                padding: 0.5rem;
            }

            svg {
                height: 28px;
                width: 28px;

                @media screen and (max-width: 1024px) {
                    height: 20px;
                    width: 20px;
                }

                @media screen and (max-width: 470px) {
                    height: 16px;
                    width: 16px;
                }
            }
        }

        .prevIcon,
        .nextIcon {
            position: absolute;
            top: 50%;
            cursor: pointer;
            z-index: 2;

            svg {
                width: 72px;
                height: 72px;

                @media screen and (max-width: 1024px) {
                    height: 56px;
                    width: 56px;
                }

                @media screen and (max-width: 768px) {
                    height: 48px;
                    width: 48px;
                    margin: 1rem 0;
                }

                @media screen and (max-width: 576px) {
                    height: 40px;
                    width: 40px;
                }

                @media screen and (max-width: 470px) {
                    height: 36px;
                    width: 36px;
                }
            }
        }

        .prevIcon {
            left: 0;
            transform: translate(-60%, -50%);

            @media screen and (max-width: 768px) {
                transform: translate(5%, -50%);
            }

            @media screen and (max-width: 576px) {
                transform: translate(30%, -50%);
            }
        }

        .nextIcon {
            right: 0;
            transform: translate(60%, -50%);

            @media screen and (max-width: 768px) {
                transform: translate(-5%, -50%);
            }

            @media screen and (max-width: 576px) {
                transform: translate(-30%, -50%);
            }
        }

        .images-container {
            overflow: hidden;
            border-radius: 0.5rem;

            .swiper-wrapper {
                align-items: center;
            }

            .image-wrapper {
                display: flex;
                align-items: center;
                max-height: 80vh;

                img {
                    max-width: 100%;
                    border-radius: 0.5rem;
                    user-select: none;

                    &.portrait {
                        max-height: 80vh;
                        margin-inline: auto;
                    }
                }
            }
        }
    }
}

</style>


<style lang="scss">

@import "@/css/variables.scss";

.gallery-modal {
    .swiper, .swiper-container {
        position: static;
    }
}

.swiper-pagination.inModal {
    bottom: 0 !important;

    @media screen and (max-width: 1024px) {
        bottom: -0.5rem !important;
    }

    @media screen and (max-width: 768px) {
        bottom: -1.5rem !important;
    }

    .swiper-pagination-bullet {
        width: 0.75rem;
        height: 0.75rem;
        min-width: 8px;
        min-height: 8px;
        background-color: #FFF;
        opacity: 1;

        &-active {
            background-color: $bbBlue;
            border: 1px solid #FFF;
        }
    }
}
</style>