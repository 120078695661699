<script setup>
import axios from "axios";
import NewsletterDiscountCard from "./NewsletterDiscountCard.vue";
import NewsletterPopup from "./NewsletterPopup.vue";

import useValidator from '@/utils/useValidator';
import { reactive } from "vue";

const apiUrl = "/api/add_to_newsletter"

const loading = reactive({
     loaderVisible: false,
     borderVisible: false,
     text: "",
})

const formData = reactive({
     email: {
        value: "",
        defaultValue: "",
        required: true,
        regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        isValid: true,
    },
    name: {
        value: "",
        defaultValue: "",
        required: false,
        isValid: true,
    },
    phone: {
        value: "",
        defaultValue: "",
        required: false,
        regex: /^(?=.*\d)[\d ]+$/,
        minLength: 5,
        maxLength: 20,
        isValid: true,
    },
})

const {validateInput, validateAll, resetValues} = useValidator()


const addSubscriber = async () => {

     if(!loading.loaderVisible) {
          const isFormValid = validateAll(formData)

          if(isFormValid) {
               loading.loaderVisible = true;

               const freshmailPostData = new FormData();
               freshmailPostData.append("email", formData.email.value);
               freshmailPostData.append("name", formData.name.value);
               freshmailPostData.append("phone", formData.phone.value);
               freshmailPostData.append("form_name", 'bauratgeber');
 
               try {
                    window.dataLayer.push({'form_sent_success': true});
                    const response = await axios.post(apiUrl, freshmailPostData)
                    const responseData = JSON.parse(response.data)

                    if (responseData.errors && responseData.errors[0].code === 1304) {
                         loading.text = "Sie haben den Bauratgeber bereits abonniert.";
                    }

                    if (!responseData.errors) {
                         loading.text = "Danke, dass Sie unseren Bauratgeber abonniert haben.";
                         resetValues(formData)
                    }

                    loading.borderVisible = true;

                    setTimeout(() => {
                         loading.borderVisible = false
                    }, 5000)

               }catch(err) {
                    loading.text = "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.";
                    loading.borderVisible = true;

                    setTimeout(() => {
                         loading.borderVisible = false
                    }, 5000)

               }finally{
                    loading.loaderVisible = false
               }
          }
     }
}

</script>

<template>
     <div>
          <form @submit.prevent="addSubscriber" class="newsletter-form" id="newsletter-form">
               <label for="name">
                    <img src="@/assets/newsletter-icon-1.svg" alt="" />
                    <span>Name:</span>
                    <input v-model="formData.name.value" name="Name" type="text" id="name" :class="{ error: !formData.name.isValid}" 
                         @blur = "validateInput(formData.name)"/>
               </label>
               <label for="mail">
                    <img src="@/assets/newsletter-icon-2.svg" alt="" />
                    <span>E-mail:</span>
                    <input v-model="formData.email.value" type="email" name="Email" id="mail" :class="{ error: !formData.email.isValid}" 
                         @blur = "validateInput(formData.email)"/>
               </label>
               <label for="telefonnummer">
                    <img src="@/assets/newsletter-icon-3.svg" alt="" />
                    <span>Telefonnummer:</span>
                    <input v-model="formData.phone.value" type="text" name="Telefonnummer" id="telefonnummer" :class="{ error: !formData.phone.isValid}" 
                         @blur = "validateInput(formData.phone)"/>
               </label>
               <div class="card-wrapper">
                    <NewsletterDiscountCard />
               </div>
               <button class="newsletter-form-button" type="submit">
                    Jetzt abonnieren!
                    <img v-if="loading.loaderVisible" class="loader" src="@/assets/loader.svg" alt="" />
               </button>
          </form>
          <NewsletterPopup :loading="loading.borderVisible" :text="loading.text"/>
     </div>
</template>


<style scoped lang="scss">
@import "@/css/variables.scss";

.newsletter-form {
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
     flex-grow: 1;
}

.newsletter-form label {
     width: 100%;
     background-color: white;
     border-radius: 0.5rem;
     border: 2px solid white;
     padding: 1rem;
     display: flex;
     align-items: center;
     gap: 1rem;
     transition: 0.2s;

     &:hover {
          cursor: pointer;
     }

     &:has(input:focus) {
          background-color: transparent;
          transform: scaleX(1.02);
     }
}

.newsletter-form label img {
     width: 1.75rem;
}
.newsletter-form label span {
     font-weight: 600;
     white-space: nowrap;
}

.newsletter-form label input {
     flex-grow: 1;
     border: 0;
     outline: none;
     background-color: transparent;
     caret-color: $bbBlue;
     width: 100%;
     font-size: 1rem;
     transition: 0.2s;
}

.newsletter-form label:has(.error) {
     background-color: rgba(255, 0, 0, 0.4);

     input {
          background-color: transparent;
     }
}

.newsletter-form label input[type="number"] {
     &::-webkit-inner-spin-button,
     &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }
}

.card-wrapper {
     display: flex;
     justify-content: center;
     margin: 2rem 0;

     @media screen and (min-width: 769px) {
          display: none;
     }
}

.newsletter-form .newsletter-form-button {
     margin-top: 2rem;
     padding: 1rem;
     background: $bbBlue;
     border: 2px solid $bbBlue;
     color: white;
     border-radius: 0.5rem;
     font-size: 1.25rem;
     font-weight: 600;
     transition: 0.2s;

     &:hover {
          background-color: transparent;
          color: $bbBlue;
          cursor: pointer;
     }

     &:active {
          transform: scale(0.98);
     }

     @media screen and (max-width: 786px) {
          margin-top: auto;
     }
}

.newsletter-form .newsletter-form-button .loader {
     width: 2rem;
     position: absolute;
     right: 3rem;
}
</style>