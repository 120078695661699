import { reactive } from "vue";

const multisiteConfig = {
    'baudiscount.de': {
        host: 'de',
        number: '030 / 577 134 2110',
        numberAnchor: '+49305771342110'
    },
    'baudiscount.at': {
        host: 'at',
        number: '01 / 39 810 100',
        numberAnchor: '+43139810100'
    }
}

const defaultHost = 'baudiscount.de'

const getHostConfig = () => {
    const fullHostname = window.location.hostname
    const hostname = fullHostname.replace("www.",'')

   if(!multisiteConfig[hostname]) return multisiteConfig[defaultHost]
   return multisiteConfig[hostname]
  }

const store = reactive({
    alert: {
        alertShown: false,
        alertMessage: "",
        showAlert(message, timeout = false) {
            this.alertMessage = message
            this.alertShown = true
    
            if(timeout) {
                setTimeout(() => {
                    if(this.alertShown) this.hideAlert()
                },(timeout*1000))
            }
        },
        hideAlert() {
            this.alertShown = false
        }
    },
    callModal: {
        opened: false,
        openModal() {
            this.opened = true
        },
        closeModal() {
            this.opened = false
        }
    },
    multisite: getHostConfig(),
    cookies: {
        accepted: document.cookie.indexOf("cookieConsentData") !== -1,
        setAccepted(accepted) {
            this.accepted = accepted
        }
    }
})

export default store