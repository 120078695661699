const useDateConverters = () => {
     const dateToString = (date) => {
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();

          return year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
     };
     const dateToTimeString = (date) => {
          const hour = date.getHours();
          const minutes = date.getMinutes();

          return (hour >= 10 ? hour : "0" + hour) + ":" + (minutes >= 10 ? minutes : "0" + minutes);
     };

     return {
          dateToString,
          dateToTimeString,
     };
};

export default useDateConverters