<template>
     <div className="wrapper" :style="offset">
          <div className="shape">
               <span class="shape-top-text">WERTGUTSCHEIN</span>
               <span class="shape-bottom-text">Gültig bis Ende 2024</span>
          </div>
          <div className="shape2">500€</div>
     </div>
</template>

<script>
export default {
     data() {
          return {
               offsetX: "",
               offsetY: "",
               isMobile: null,
               friction: 1 / 128,
          };
     },
     mounted() {

          this.isMobile = window.innerWidth <=768;
          
          ["mousemove", "touchmove"].forEach((event) => {
               document.addEventListener(event, this.mouseMove);
          })
          
          window.addEventListener("resize", ()=> {
               if(window.innerWidth<=768 && !this.isMobile) this.isMobile = true
               else if (window.innerWidth > 768 && this.isMobile) this.isMobile = false
          })
     },
     methods: {
           mouseMove(e) {
            if(!this.isMobile) {
               let followX = window.innerWidth / 2 - (e.clientX ?? e.touches[0].clientX);
               let followY = window.innerHeight / 2 - (e.clientY ?? e.touches[0].clientY);
               let x = 0;
               let y = 0;
               x += (-followX - x) * this.friction;
               y += (followY - y) * this.friction;
               (this.offsetX = x), (this.offsetY = y);
            }
          },
     },
     computed: {
          offset() {
               if(!this.isMobile) return {
                    transform: `translate(-50%, -50%) perspective(600px) rotateY(${this.offsetX}deg) rotateX(${this.offsetY}deg)`,
               };
          },
     },
};
</script>

<style scoped lang="scss">
.wrapper {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%) perspective(600px) rotateY(350deg) rotateX(5deg);
     background-image: url("@/assets/newsletter-card-gradient.png");
     background-size: cover;
     transform-style: preserve-3d;
     width: 35rem;
     height: 25rem;
     border-radius: 10px;

     @media screen and (max-width: 768px) {
          width: 15rem;
          height: 10rem;
          transform: translate(0);
          position: relative;
          left: unset;
          top: unset;
     }

     @media screen and (min-width: 2160px) {
          width: 25rem;
          height: 15rem;
     }

     & .shape {
          border-radius: 10px;
          width: 35rem;
          height: 25rem;
          transform: translateZ(100px) scale(0.85);
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem;

          @media screen and (max-width: 768px) {
               width: 15rem;
               height: 10rem;
               transform: scale(0.85);
          }

          @media screen and (min-width: 2160px) {
               width: 25rem;
               height: 15rem;
          }
     }

     & .shape .shape-top-text {
          font-weight: 600;
          font-size: 3.5rem;

          @media screen and (max-width: 768px) {
               font-size: 1rem;
          }

          @media screen and (min-width: 2160px) {
               font-size: 2rem;
          }
     }

     & .shape .shape-bottom-text {
          font-size: 2.5rem;

          @media screen and (max-width: 768px) {
               font-size: 0.8rem;
          }

          @media screen and (min-width: 2160px) {
               font-size: 1.8rem;
          }
     }

     & .shape2 {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          color: white;
          width: 40rem;
          height: 30rem;
          transform: translateZ(125px) translateX(-27px) translateY(-26px) scale(0.55);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15rem;
          font-weight: 600;

          @media screen and (max-width: 768px) {
               font-size: 5rem;
               width: 20rem;
               height: 15rem;
               transform:translateX(-27px) translateY(-26px) scale(0.55);
          }

          @media screen and (min-width: 2160px) {
               width: 27.5rem;
               height: 17.5rem;
               font-size: 10rem;
          }
     }
     & p {
          text-align: center;
     }
}
</style>