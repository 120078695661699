<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import CallModal from "./CallModal/CallModal.vue";
import Tooltip from "./Tooltip.vue";

import TrengoIcon from "./svg/CallIcons/TrengoIcon.vue";
import CrossIcon from "./svg/CallIcons/CrossIcon.vue";
import ConfiguratorIcon from "./svg/CallIcons/ConfiguratorIcon.vue";
import CallModalIcon from "./svg/CallIcons/CallModalIcon.vue";
import CallBoxMainIcon from "./svg/CallIcons/CallBoxMainIcon.vue";
import CloseCallBoxIcon from "./svg/CallIcons/CloseCallBoxIcon.vue";

const isOpened = ref(false);
const isTrengoOpened = ref(false);
const isTrengoRendered = ref(false);

window.Trengo = window.Trengo || {};
window.Trengo.render = false;

const renderTrengo = () => {
     window.Trengo.render = true;
     isTrengoRendered.value = true;
     sessionStorage.setItem("trengoRendered", "true");
     //ZAŁADOWANIE SKRYPTÓW TRENGO
     window.Trengo = window.Trengo || {};
     window.Trengo.key = "iYJGRhhXA42BErxByEBK";
     (function (d, script, t) {
          script = d.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.src = "https://static.widget.trengo.eu/embed.js";
          script.id = "trengoScript";
          d.getElementsByTagName("head")[0].appendChild(script);
     })(document);
};

const toggleCallIcons = () => {
     if (!isOpened.value) {
          if (!isTrengoRendered.value && store.cookies.accepted) {
               renderTrengo();
          }
          isOpened.value = true;
     } else {
          isOpened.value = false;
     }
};

const toggleTrengo = () => {
	if(store.cookies.accepted) {
		if (isTrengoOpened.value) window.Trengo.Api.Widget.close("chat");
		else window.Trengo.Api.Widget.open("chat");
	}
};

onMounted(() => {
     if (sessionStorage.getItem("trengoRendered") === "true" && store.cookies.accepted) {
          renderTrengo();
     }
     window.Trengo.on_open = () => {
          isTrengoOpened.value = true;
     };
     window.Trengo.on_close = () => {
          isTrengoOpened.value = false;
     };
});

const router = useRouter();
const route = useRoute();

const toServices = () => {
     router.push("/preis-produktkonfiguratoren");
};

const isProduktePage = ref(route.name === "Produkte");

watch(route, (newRoute) => {
     if (newRoute.name === "Produkte") isProduktePage.value = true;
     else isProduktePage.value = false;
});

const toggleCallModalOpened = () => {
     if (store.callModal.opened) store.callModal.closeModal();
     else store.callModal.openModal();
};
</script>

<template>
     <transition name="view">
          <CallModal
               :modalOpened="store.callModal.opened"
               @closeModal="store.callModal.closeModal()"
               v-if="store.callModal.opened" />
     </transition>

     <div class="icons-container" v-if="store.cookies.accepted">
          <Transition name="callBox">
               <div data-tooltip class="icon-box" v-if="isOpened" @click="toggleTrengo">
                    <TrengoIcon v-if="!isTrengoOpened" />
                    <CrossIcon v-else />
					<Tooltip textSize="text-small" position="left">Chat <ArrowDown /></Tooltip>
               </div>
          </Transition>
          <Transition name="callBox">
               <div data-tooltip class="icon-box" v-if="isOpened && !isProduktePage" @click="toServices">
                    <ConfiguratorIcon />
                    <Tooltip textSize="text-small" position="left">Preis Konfigurator <ArrowDown /></Tooltip>
               </div>
          </Transition>
          <Transition name="callBox">
               <div data-tooltip class="icon-box" v-if="isOpened" @click="toggleCallModalOpened">
                    <CallModalIcon />
                    <Tooltip textSize="text-small" position="left">Kostenloser Rückrufservice <ArrowDown /></Tooltip>
               </div>
          </Transition>
          <div class="icon-box main" @click="toggleCallIcons">
               <CloseCallBoxIcon v-if="isOpened" />
               <CallBoxMainIcon v-else />
          </div>
     </div>
</template>

<style lang="scss" scoped>
@import "../css/config/config";

.view-enter-active,
.view-leave-active {
     transition: all 0.3s ease-in-out;
     opacity: 1;
}

.view-enter-from,
.view-leave-to {
     opacity: 0;
}

.icons-container {
     position: fixed;
     right: 1.5rem;
     bottom: 1.5rem;
     width: 4rem;
     z-index: 12;
     display: flex;
     flex-direction: column;
     row-gap: 0.375rem;
     .tooltip_data {
          visibility: visible;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.25rem;

          svg {
               width: 1rem !important;
               transform: rotate(270deg);
          }
     }
     .icon-box {
          width: 4rem;
          height: 4rem;
          background-color: $primary;
          border-radius: 8px;
          transition: background-color 0.25s ease-in-out;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
               background-color: $secondary;
          }

          &.main {
               z-index: 1;
          }

          svg {
               width: 3.5rem;
               height: auto;
          }

          &:nth-of-type(1) {
               &.callBox-enter-active {
                    transition: all 0.085s ease-in;
                    transition-delay: 0.17s;
               }

               &.callBox-leave-active {
                    transition: all 0.085s ease-in;
               }
          }

          &:nth-of-type(2) {
               &.callBox-enter-active,
               &.callBox-leave-active {
                    transition: all 0.085s ease-in;
                    transition-delay: 0.085s;
               }
          }

          &:nth-of-type(3) {
               &.callBox-enter-active {
                    transition: all 0.085s ease-in;
               }

               &.callBox-leave-active {
                    transition: all 0.085s ease-in;
                    transition-delay: 0.17s;
               }
          }

          &.callBox-leave-to,
          &.callBox-enter-from {
               opacity: 0;
               transform: translateY(30px);
          }
     }
}
</style>