<template>
    <div class="loader-wrapper">
        <div class="bb-loader"></div>
        <span>B</span>
    </div>
</template>

<style lang="scss" scoped>

.loader-wrapper {
  position: relative;
}

.bb-loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgb(0,0,0,0.11); 
  border-radius: 50%;
  border-top-color: #FFDD00;
  border-right-color: #009142;
  border-left-color: #2D3790;
  border-bottom-color: #A11E62;
  animation: loader1776 2s linear infinite;
}

@keyframes loader1776 {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(315deg);
  }
}

.loader-wrapper span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-47%, -50%);
  font-size: 34px;
  color: #FFF;
  font-weight: 600;

  &.black {
      color: #263238;
  }
}
</style>