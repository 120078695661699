const useValidator = () => {

    const validateInput = (dataObject, returnStatus = false) => {
        dataObject.isValid = true
        
        if(!dataObject.required && dataObject.value?.length === 0 ) {
            dataObject.isValid = true

            if (returnStatus) return true
            else return
        }

        if (dataObject.required) {
            if (dataObject.value?.length < 1 || dataObject.value == false || dataObject.value === null) {
                dataObject.isValid = false

                if (returnStatus) return false
                else return
            }
        }

        if (dataObject.regex) {
            if (!dataObject.regex.test(dataObject.value)) {
                dataObject.isValid = false
                if (returnStatus) return false
                else return
            }
        }

        if (dataObject.minLength) {
            if (dataObject.value.length < dataObject.minLength) {
                dataObject.isValid = false
                if (returnStatus) return false
                else return
            }
        }

        if (dataObject.maxLength) {
            if (dataObject.value.length > dataObject.maxLength) {
                dataObject.isValid = false
                if (returnStatus) return false
                else return
            }
        }

        if (returnStatus) return true
    }

    const validateAll = (formData) => {
        const keys = Object.keys(formData)

        let isValid = true
        keys.forEach((el) => {
            if (!validateInput(formData[el], true)) isValid = false
        })

        return isValid
    }

    const resetValues = (formData) => {
        const keys = Object.keys(formData)
        keys.forEach((el) => {
            if(Object.keys(formData[el]).includes('defaultValue')) {
                formData[el].value = formData[el].defaultValue
                formData[el].isValid = true
            }
        })
    }

    return {
        validateInput,
        validateAll,
        resetValues
    }
}

export default useValidator