<template>
     <p class="newsletter-form-title">Abonnieren Sie unseren</p>
      <span class="subtitle-part-1">
            <img src="@/assets/bauratgeber-logo.svg" alt="Der Bauratgeber von Baudiscount"/>
      </span>
     <p class="newsletter-form-paragraph">und erhalten einen Gutschein für Ihren ersten Einkauf bei Baudiscount.de</p>
</template>

<style scoped lang="scss">
.newsletter-content .newsletter-form-wrapper .newsletter-form-title {
     font-weight: 600;
     font-size: 2.25rem;
     line-height: 2.5rem;
     margin: 0;

     @container (max-width: 400px) {
      font-size: 1.25rem;
     line-height: 1.5rem;
     }
}

.newsletter-content .newsletter-form-wrapper span {
     display: flex;
     line-height: 2.25rem;
     align-items: center;
}
.newsletter-content .newsletter-form-wrapper .subtitle-part-1 img {
     width: 50%;
     margin-top: 1rem;
}

.newsletter-content .newsletter-form-wrapper .newsletter-form-paragraph {
     font-size: 1.75rem;
     margin-top: 0.5rem;
     line-height: 2.25rem;
}
</style>