<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_327_26)">
            <path
                d="M22.2051 5.53457C18.6151 -0.000180699 11.2852 -1.64565 5.60088 1.79487C0.0661372 5.2354 -1.72891 12.7148 1.86119 18.2496L2.16037 18.6983L0.963664 23.1859L5.4513 21.9892L5.90007 22.2884C7.8447 23.3355 9.93894 23.9339 12.0331 23.9339C14.2769 23.9339 16.5208 23.3355 18.4655 22.1388C24.0002 18.5487 25.6456 11.2189 22.2051 5.53457ZM19.0638 17.0528C18.4655 17.9504 17.7175 18.5487 16.6703 18.6983C16.072 18.6983 15.3241 18.9975 12.3323 17.8008C9.78934 16.604 7.69512 14.6594 6.19924 12.4156C5.30171 11.3685 4.85295 10.0222 4.70336 8.67591C4.70336 7.47921 5.15212 6.4321 5.90007 5.68416C6.19924 5.38498 6.49841 5.2354 6.79759 5.2354H7.54553C7.8447 5.2354 8.14388 5.2354 8.29346 5.83374C8.59265 6.58169 9.34058 8.37674 9.34058 8.52632C9.49017 8.67591 9.49017 8.97509 9.34058 9.12468C9.49017 9.42385 9.34058 9.72303 9.19099 9.87261C9.04141 10.0222 8.89182 10.3214 8.74223 10.471C8.44305 10.6206 8.29346 10.9197 8.44305 11.2189C9.04141 12.1164 9.78934 13.0139 10.5373 13.7619C11.4348 14.5098 12.3323 15.1082 13.3794 15.5569C13.6786 15.7065 13.9778 15.7065 14.1274 15.4073C14.2769 15.1082 15.0249 14.3602 15.3241 14.061C15.6232 13.7619 15.7728 13.7619 16.072 13.9115L18.4655 15.1082C18.7647 15.2577 19.0638 15.4073 19.2134 15.5569C19.363 16.0057 19.363 16.604 19.0638 17.0528Z"
                fill="#F6F9FC" />
        </g>
        <defs>
            <clipPath id="clip0_327_26">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>