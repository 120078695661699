<template>
     <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
               d="M29.8488 30.3781C28.0337 29.4201 28.3361 28.1093 28.3361 28.1093H28.2858C30.3532 26.6975 31.664 24.7814 31.664 22.6638C31.664 18.4286 26.521 14.9998 20.168 14.9998C13.8151 14.9998 8.67206 18.4789 8.67206 22.7142C8.67206 26.9494 13.8151 30.3782 20.168 30.3782C21.8823 30.3782 23.5462 30.1261 25.0086 29.6724C26.2186 31.0842 31.664 31.3866 29.8489 30.3782L29.8488 30.3781ZM14.3192 24.378C13.563 24.378 12.9074 23.773 12.9074 22.9662C12.9074 22.21 13.5123 21.5544 14.3192 21.5544C15.0753 21.5544 15.731 22.1593 15.731 22.9662C15.731 23.773 15.126 24.378 14.3192 24.378ZM19.8657 24.378C19.1095 24.378 18.4539 23.773 18.4539 22.9662C18.4539 22.21 19.0588 21.5544 19.8657 21.5544C20.6218 21.5544 21.2774 22.1593 21.2774 22.9662C21.2774 23.773 20.6219 24.378 19.8657 24.378ZM25.4118 24.378C24.6556 24.378 24 23.773 24 22.9662C24 22.21 24.6049 21.5544 25.4118 21.5544C26.168 21.5544 26.8236 22.1593 26.8236 22.9662C26.8239 23.773 26.1683 24.378 25.4118 24.378Z"
               fill="white" />
          <path
               d="M37.3615 33.1515C38.0674 33.4539 38.8235 33.6052 39.6304 33.6558H39.3279C39.5294 33.6558 39.7313 33.7061 39.8825 33.7061C40.0841 33.7061 40.2859 33.7061 40.4875 33.6558C40.3362 33.6558 40.185 33.6055 39.9831 33.6055C43.3613 33.4543 46.0842 30.6811 46.0842 27.2526C46.0842 24.6306 44.5213 22.3617 42.2523 21.4037C41.4962 21.1012 40.6894 20.95 39.8826 20.95C36.3532 20.95 33.5296 23.7734 33.5296 27.3029C33.5799 29.9245 35.1429 32.1431 37.3615 33.1515V33.1515Z"
               fill="white" />
          <path
               d="M43.5129 34.2104C42.4538 34.7651 41.244 35.1178 39.9328 35.1178C38.6219 35.1178 37.4117 34.8154 36.3527 34.2104C31.815 35.7232 28.5881 39.9584 28.5881 45.0005H51.3279C51.2776 39.9584 48.0004 35.7231 43.5129 34.2104H43.5129Z"
               fill="white" />
     </svg>
</template>