<script>
export default {
    inheritAttrs: false
}
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    modelValue: {
        required: true
    },
    label: {
        type: String,
        required: false
    },
    id: {
        type: String,
        required: true
    },
    isError: {
        type: Boolean
    },
    errorMessage: {
        type: [String, null],
        required: false
    },
    helperMessage: {
        type: String,
        required: false
    }
})

const emit = defineEmits(['update:modelValue']);

</script>

<template>
    <div class="container" :class="{ 'error': isError }">
        <label v-if="props.label" :for="props.id">{{ props.label }}</label>

        <textarea :id="props.id" rows="3" :value="modelValue"
            @input="(event) => { emit('update:modelValue', event.target.value) }" v-bind="$attrs"></textarea>

        <p v-if="props.helperMessage && !props.errorMessage" class="message">{{ props.helperMessage }}</p>
        <p class="message error" v-else-if="props.errorMessage !== undefined"
            :class="{ hidden: props.errorMessage === null }">
            {{ props.errorMessage ? props.errorMessage : '-' }}
        </p>
    </div>
</template>

<style lang="scss" scoped>
@import "@/css/config/config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    flex-grow: 1;

    label {
        color: #263238;
        font-size: 0.875rem;
        line-height: 1.5;
        font-weight: 300;

        @include break("xxs down") {
            font-size: 1rem;
        }
    }

    &.error {
        textarea {
            border-color: red;

            &:focus {
                border-color: red;
                outline-color: red;
            }
        }

        animation: shake 0.2s 2;
    }

    textarea {
        border: 1px solid #B0BEC5;
        outline: 1px solid transparent;
        border-radius: 2px;
        width: 100%;
        padding: 0.5rem;
        resize: none;
        transition: border-color 0.15s ease-in-out, outline-color 0.15s ease-in-out;
        font-size: 0.875rem;

        @include break("xxs down") {
            font-size: 1rem;
        }

        &:focus {
            border-color: $bbBlue;
            outline-color: $bbBlue;
        }
    }

    .message {
        color: $gray_400;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;

        &.error {
            color: red;

            &.hidden {
                visibility: hidden
            }
        }
    }
}

@keyframes shake {
    25% {
        translate: 6px 0;
    }

    50% {
        translate: -6px 0;
    }

    75% {
        translate: 6px 0;
    }
}</style>