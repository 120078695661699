<template>
    <svg width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_169_16)">
            <path d="M18 1L24 7L18 13" stroke="#2d3790" stroke-width="2" />
            <path d="M24 7H0" stroke="#2d3790" stroke-width="2" />
        </g>
        <defs>
            <clipPath id="clip0_169_16">
                <rect width="26" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

    