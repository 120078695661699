<template>
  <div>
    <h1>Seite nicht gefunden.</h1>
    <router-link :to="{name:'Startseite'}">Startseite</router-link>
  </div>
</template>

<style lang="scss" scoped>
div {
  margin: 0 auto;
  width: 80%;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin: 0 auto;
    margin-top: 1rem;
    width: fit-content;
    display: block;
    padding: 1rem 4rem;
    border-radius: 0.5rem;
    background: #a11e62;
    color: white;
    border: 1px solid #a11e62;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      background: white;
      color: #a11e62;
    }
  }
}
</style>
