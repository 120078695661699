<script setup>
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";
import store from "@/store";

const props = defineProps({
    googleMap: {
        type: Object,
        required: true
    },
    adress: {
        type: [Number, String]
    }
})

const center = { lat: Number(props.googleMap.lat), lng: Number(props.googleMap.lng) };

const markerOptions = {
    position: center,
    //   label: "Baudiscount",
    title: props.adress,
    icon: {
        url: `/images/component-map-${store.multisite.host }.svg`,
    },
};

</script>

<template>
    <GoogleMap api-key="AIzaSyBlkbLttl_26dsAN6b9qFn0CGatU1DFru4" style="width: 100%; height: 500px" :center="center"
        :zoom="10">
        <!-- <InfoWindow :options="{ position: center, content: 'Hello World!' }" /> -->
        <Marker :options="markerOptions"></Marker>
    </GoogleMap>
</template>
