<template>
    <section class="our-history">
        <div class="uberuns__wrapper">
            <div class="uberuns__content">
                <h2 data-aos="fade-up" data-aos-delay="0" data-aos-duration="550">Baudiscount, die Top-Adresse für den Baustoffhandel</h2>
                <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="550">
                    Mit tatkräftiger digitaler Unterstützung erreichen wir unsere und vor allem Ihre ambitionierten Ziele. 
                    Die innovativen KI-, RPA- und Konfigurationstools verschlanken Ihre Baustoffbeschaffungsprozesse, indem Sie hiermit bedarfsgerechte 
                    Echtzeitangebote erstellen. Das erleichtert Ihnen die Suche nach den besten Produkten und Materialien. 
                    Darüber hinaus greifen die smarten digitalen Helfer dem Logistikteam bei der Berechnung der kürzesten Lieferwege 
                    vom Herstellungswerk zu Ihrem Bauprojekt unter die Arme. Jegliche Umwege zu Zwischenhändlern oder Umladungen in Lagerhallen 
                    klammern wir damit aus. Das Ergebnis: reduzierte Preise, Lieferzeiten, Arbeits- und Energieaufwände für Sie und ein 
                    geringerer CO₂-Fußabdruck für uns alle. Unsere Mission und Ihr Traum vom Haus bei minimalem Kostenaufwand ist somit schon bald Realität!
                </p>
                <ul>
                    <li>
                        <p data-aos="fade-up" data-aos-delay="0" data-aos-duration="550">
                            Über <b>3</b> Millionen Online-Besucher jährlich
                        </p>
                    </li>
                    <li>
                        <p data-aos="fade-up" data-aos-delay="50" data-aos-duration="550">
                            Über <b>100.000</b> Kundenangebote im Jahr
                        </p>
                    </li>
                    <li>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="550">
                            Über <b>200</b> Millionen&nbsp;EUR Angebotsvolumen jährlich
                        </p>
                    </li>
                    <li>
                        <p data-aos="fade-up" data-aos-delay="150" data-aos-duration="550">
                            <b>10</b> qualitativ hochwertige Eigenmarken
                        </p>
                    </li>
                    <li>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="550">
                            Über <b>50</b> sachkundige Fachberater
                        </p>
                    </li>
                    <li>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="550">
                            Mehr als <b>500.000</b> zufriedene Kunden
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.our-history {
    padding: 3.5rem 0 3rem;
    background-image: linear-gradient(180deg, rgba(50, 59, 148, 0.94) 35%, rgba(55,67,172,0.85) 75%),
        url("../../assets/uberuns/background-our-history.webp");
    color: #fff;
    margin-bottom: 2rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .uberuns__content {
        padding: 0;
        margin: 0 auto;

        h2 {
            font-weight: 700;
            font-size: 2.875rem;
            line-height: 4.5rem;
            margin-bottom: 1.25rem;
            margin-top: 0;

            @media screen and (max-width: 576px) {
                font-size: 2.125rem; //28px
                line-height: 3rem;
            }
        }

        p {
            font-weight: 300;
            font-size: 1rem;
            line-height: 1.94rem;
            margin-bottom: 1.75rem;
            margin-top: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width:768px) {
                flex-direction: column;
                align-items: flex-start;
            }

            li {
                position: relative;
                width: 100%;

                &:first-child p {
                    padding-left: 0;
                }

                &:last-child p {
                    padding-right: 0;
                }

                @media screen and (max-width:768px) {
                    margin-bottom: 1.5rem;
                }

                p {
                    font-weight: 300;
                    font-size: 1.25rem;
                    line-height: 1.626rem;
                    padding: 0 0.5rem 0 0.75rem;
                    word-break: break-word;
                    margin: 0;

                    b {
                        font-weight: 700;
                    }

                    @media screen and (max-width:1440px) {
                        font-size: 1.25rem;
                    }

                    @media screen and (max-width:1200px) {
                        font-size: 1.1rem;
                    }

                    @media screen and (max-width:1024px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width:768px) {
                        padding: 0.25rem 0 0 0;
                        font-size: 1.5rem;
                        line-height: 3rem;
                        position: relative;
                        padding-left: 2rem;

                        &:first-child {
                            padding-left: 2rem;
                        }

                        &::after {
                            content: "●";
                            position: absolute;
                            top: 0.25rem;
                            left: -1rem;
                            height: 3rem;
                            width: 3rem;
                            font-size: 1rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    @media screen and (max-width:576px) {
                        font-size: 1.75rem;
                    }
                }

                div {
                    margin-top: 1.5rem;
                }

                &:not(:first-child)::before {
                    content: "";
                    width: 1px;
                    height: 100%;
                    background-color: #fff;
                    display: inline-block;
                    position: absolute;

                    @media screen and (max-width:768px) {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>